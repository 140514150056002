import { PING_QUERY } from 'services/queries/ping'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGraphQLClient } from 'config/libs/globals'
import { setOnlineStatus } from 'redux/reducers/appOnlineReducer'
import { selectAppOnline } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'

export const useConnectionHandler = () => {
  const dispatch = useDispatch()
  const { setApplicationMode } = useAnalytics()
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const onlineStatus = useSelector(selectAppOnline)

  async function handleOnlineStatus() {
    const status = await checkInternetConnection()
    dispatch(setOnlineStatus(status))
  }

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOnlineStatus)

    handleOnlineStatus().then(() => {
      setIsInitialized(true)
    })

    return () => {
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOnlineStatus)
    }
  }, [])

  useEffect(() => {
    let interval: any = undefined
    const intervalCheckConnection = () => {
      interval = setInterval(() => {
        handleOnlineStatus()
      }, 10 * 1000)
    }

    if (window.electron?.isDesktop()) {
      intervalCheckConnection()
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  useEffect(() => {
    setApplicationMode(onlineStatus ? 'online' : 'offline')
  }, [onlineStatus])

  return isInitialized
}

export const checkInternetConnection = async () => {
  if (!window.electron?.isDesktop()) {
    return window.navigator.onLine
  }

  // for desktop
  try {
    if (!window.navigator.onLine) {
      return false
    }

    const { data, errors } = await getGraphQLClient().query({
      query: PING_QUERY,
      fetchPolicy: 'no-cache',
    })

    if (errors) {
      return false
    }

    if (data) {
      return true
    }
  } catch (error) {
    return false
  }
  return false
}
