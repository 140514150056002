import { usePageContent } from 'redux/hooks/pageContent'
import ManualContentUI from 'features/ManualContent/screens/ManualContent.ui'

type ManualContentProps = {
  pageId: number
  onDownloadStatus: (status: boolean) => void
}
const ManualContentPDF = ({ pageId, onDownloadStatus }: ManualContentProps) => {
  const { data, loading, error } = usePageContent(pageId)
  const hasDataLoaded = data && !loading && !error

  if (hasDataLoaded) {
    onDownloadStatus(true)
  }

  if (error) {
    onDownloadStatus(false)
  }

  if (hasDataLoaded) {
    return <ManualContentUI pageData={data} />
  }
  return null
}

export default ManualContentPDF
