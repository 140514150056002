import { universal } from 'database/db';
import { ASSET_KEYS, ASSET_PATCH_SIZE, DOCUMENT_KEYS, DocumentType, DownloadInfo } from 'redux/download/download.reducer';

export const storeCompletedDownload = async (name: string, data: DownloadInfo) => {
  try {
    await universal.dcaDownload.put({
      state: 'DONE',
      percent: '1',
      bytesWritten: data.bytesWritten.toString(),
      totalBytes: data.totalBytes.toString(),
      downloadFinishedAt: Date.now().toString(),
      id: name,
    })
    return { save: true }
  } catch (error) {
    console.error('storeCompletedDownload error:', error)
    throw error
  }
}

export const deleteAllCompletedDownloads = async () => {
  try {
    await universal.dcaDownload.clear()
    return { save: true }
  } catch (error) {
    console.error('deleteAllCompletedDownloads error:', error)
    throw error
  }
}

export const getCompleteDownloadDocument = async (name: DocumentType): Promise<DownloadInfo | null> => {
  const document = await universal.dcaDownload.get(name)
  if (document) {
    return {
      id: name,
      state: document.state,
      percent: Number(document.percent),
      bytesWritten: Number(document.bytesWritten),
      totalBytes: Number(document.totalBytes),
      downloadFinishedAt: Number(document.downloadFinishedAt),
    }
  }
  return null
}

export const getCompleteDownloadAsset = async (name: DocumentType): Promise<DownloadInfo | null> => {
  const documents = await universal.dcaDownload.where('id').startsWithAnyOfIgnoreCase(name).toArray()

  if (documents.length === ASSET_PATCH_SIZE && documents.every((doc) => doc.state === 'DONE')) {
    return {
      id: name,
      state: 'DONE',
      percent: 1,
      bytesWritten: Number(documents.reduce((acc, doc) => acc + Number(doc.bytesWritten), 0)),
      totalBytes: Number(documents.reduce((acc, doc) => acc + Number(doc.totalBytes), 0)),
      downloadFinishedAt: Number(documents[0].downloadFinishedAt),
    };
  }
  return null;
};

export const getCompletedDownloadFiles = async (): Promise<DownloadInfo[]> => {
  try {
    const result: DownloadInfo[] = [];
    for (const documentKey of DOCUMENT_KEYS) {
      const document = await getCompleteDownloadDocument(documentKey);
      if (document) {
        result.push(document);
      }
    }
    
    for (const assetKey of ASSET_KEYS) {
      const asset = await getCompleteDownloadAsset(assetKey);
      if (asset) {
        result.push(asset);
      }
    }
    return result;
  } catch (error) {
    console.error('getDownloadFilesRealm error:', error);
    return [];
  }
};

export const updateLatestDownloadFile = async (name: DocumentType, downloadFinishedAt: number) => {
  try {
    await universal.dcaDownload.update(name, { downloadFinishedAt: downloadFinishedAt.toString() })
    return { save: true }
  } catch (error) {
    console.error('updateLatestDownloadFile error:', error)
    throw error
  }
}