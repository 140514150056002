import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import {
  IsProductFinderAvailableResponseType,
  IsProductGroupHasProductFinderResponseType,
} from 'types'
import {
  GET_IS_PRODUCT_FINDER_AVAILABLE_FOR_PRODUCT_GROUP,
  IS_PRODUCT_FINDER_AVAILABLE,
} from './queries/productFinderQueries'

const isProductGroupHasProductFinderQuery = async (
  productGroupId: number,
): Promise<IsProductGroupHasProductFinderResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()
    const { data } = await getGraphQLClient().query({
      query: GET_IS_PRODUCT_FINDER_AVAILABLE_FOR_PRODUCT_GROUP(
        productGroupId,
        currentLanguage.languageCode,
        currentCountry.code,
      ),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data
  } catch (error) {
    console.error('Error fetching product finder:', error)
    throw new Error('Failed to fetch product finder')
  }
}

const isProductFinderAvailableQuery = async (): Promise<IsProductFinderAvailableResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()
    const { data } = await getGraphQLClient().query({
      query: IS_PRODUCT_FINDER_AVAILABLE(currentLanguage.languageCode, currentCountry.code),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data
  } catch (error) {
    console.error('Error fetching product finder availability:', error)
    throw new Error('Failed to fetch product finder availability')
  }
}

export { isProductGroupHasProductFinderQuery, isProductFinderAvailableQuery }
