import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StructureItemType } from 'types/structureType'

interface StructureState {
  isLoading: boolean
  data: Array<StructureItemType> | null
  error: boolean
}

const initialState: StructureState = {
  isLoading: false,
  data: null,
  error: false,
}

const structureSlice = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    getStructure: (state) => {
      state.isLoading = true
      state.error = false
    },
    getStructureSuccess: (state, action: PayloadAction<Array<StructureItemType>>) => {
      state.data = action.payload
      state.isLoading = false
      state.error = false
    },
    getStructureError: (state) => {
      state.isLoading = false
      state.error = true
    },
    resetStructure: () => initialState,
  },
})

export const { getStructure, getStructureSuccess, getStructureError, resetStructure } =
  structureSlice.actions

export default structureSlice.reducer
