import { SearchResponseType, SearchSuggestionResponseType } from 'types/searchType'
import { API_YEAR } from 'config/constants'
import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { SEARCH, SEARCH_SUGGESTIONS } from './queries/searchQueries'

const getSearchQuery = async (searchStr: string): Promise<SearchResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: SEARCH(searchStr, currentLanguage.languageCode, currentCountry.code, API_YEAR),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.search
  } catch (error) {
    console.error('Error getSearch:', error)
    throw new Error('Failed to getSearch')
  }
}

const getSearchSuggestionsQuery = async (
  searchStr: string,
): Promise<SearchSuggestionResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: SEARCH_SUGGESTIONS(
        searchStr,
        currentLanguage.languageCode,
        currentCountry.code,
        API_YEAR,
      ),
    })

    if (!data) {
      throw new Error('data is undefined')
    }

    return data?.searchSuggestions.data
  } catch (error) {
    console.error('Error getSearchSuggestions:', error)
    throw new Error('Failed to getSearchSuggestions')
  }
}

export { getSearchSuggestionsQuery, getSearchQuery }
