import { ProductDetailItemType } from 'types'
import { formatMoney, getCurrencySymbol } from 'config/libs/helpers'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getProductDataShort = (
  products: ProductDetailItemType[],
  translations: TranslationsPDFType,
) => {
  let html = '<table>'

  // Add the table header row
  html += `<tr style="background-color: #D3D3D3">
      <th style="text-align: left; padding: 0.5em; width: 20%;">${tr(
        translations,
        'article_export',
        'Item',
      ).toLocaleUpperCase()}</th>
      <th style="text-align: left; padding: 0.5em; width: 50%;"/>`

  // Calculate total prices
  const totalNetPrice = products.reduce((sum, data) => sum + data.priceNet, 0)
  const totalGrossPrice = products.reduce((sum, data) => sum + data.priceGross, 0)

  if (totalNetPrice !== 0) {
    html += `<th style="text-align: left; padding: 0.5em; width: 30%;">${tr(
      translations,
      'price_export',
      'Price',
    ).toLocaleUpperCase()}</th></tr>`
  }

  const priceNetTxt = tr(translations, 'net_priceNumber', 'Net*')
  const priceGrossTxt = tr(translations, 'gross_priceNumber', 'Gross*')
  const priceRemark = tr(translations, 'price_remark', 'Recommended retail price')

  products.forEach((data) => {
    // Add the product row
    html += '<tr class="product-row" >'

    const imagesArr = data.images.map((item) => {
      const fullImage = item.urls.find((imageUrl) => imageUrl.type === 'full')?.url || ''
      return fullImage
    })

    const orderNumber = `${tr(translations, 'order_number', 'Order number')}: ${
      data.partnumberFormatted
    }`
    const eanNumber = `EAN: ${data.ean}`

    // Add the product image
    html += `<td style="padding: 0.5em">
      <img src="${imagesArr[0]}" alt="product-ima" style="width: 100%;object-fit: contain; float: left;">
    </td>`

    // Add the name, order number and EAN number cells
    html += `<td style="text-align: left; padding: 0.5em" >
        <p class="bold medium">${data.name}</p>
        <p class="bold medium">${orderNumber}</p>
        <p class="bold medium">${eanNumber}</p>
      </td>`

    // Add the net and gross price cells
    if (totalNetPrice !== 0) {
      html += `<td style="text-align: left; padding: 0.5em" >
      <p class="bold medium">${priceNetTxt}: ${data?.priceNetFormatted ?? 0}</p>
      <p class="bold medium">${priceGrossTxt}: ${data?.priceGrossFormatted ?? 0}</p>
      </td>`
    }
    // Close the product row
    html += '</tr>'
  })

  // Add the total row
  if (totalNetPrice !== 0) {
    const currency = getCurrencySymbol(products[0].priceNetFormatted ?? 0)

    html += `<tr class="product-row" style="background-color: #D3D3D3" >
      <td />
      <td class="bold medium" style="text-align: left; padding: 0.5em">${tr(
        translations,
        'sum_price',
        'Total Price',
      ).toUpperCase()}</td>
      <td class="bold medium" style="text-align: left; padding: 0.5em">
        <p>
        ${priceNetTxt}: ${formatMoney(totalNetPrice, currency)}
        </p>
        <p>
        ${priceGrossTxt}: ${formatMoney(totalGrossPrice, currency)}
        </p>
      </td>
    </tr>`
  }

  // Close the table
  html += '</table>'
  if (totalNetPrice !== 0) {
    html += `<p class="product-row">${priceRemark}</p>`
  }
  return html
}

export default getProductDataShort
