import { gql } from 'apollo-boost'

export const GET_USER_NOTIFICATIONS = (languageCode: string) => gql`
  {
    userNotifications {
      popUpInfo(input: {locale: "${languageCode}"}) {
        id
        locale
        data {
          headline
          content
          buttonClose {
            name
            style
          }
        }
      }
    }
  }
`
