import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'styles/modal/magnifyModal.scss'

export type MagnifyModalProps = {
  showModal: boolean
  heading: string
  innerHtml: string
  handleClose: () => void
}

const MAX_MAGNIFY = 5
const MAX_MINIFY = -1

const MagnifyModal = (props: MagnifyModalProps): React.ReactElement => {
  const { showModal, heading, innerHtml, handleClose } = props

  const [fontScale, setFontScale] = useState<number>(0)

  const onMinify = () => {
    if (fontScale > MAX_MINIFY) setFontScale(fontScale - 0.5)
  }

  const onMagnify = () => {
    if (fontScale < MAX_MAGNIFY) setFontScale(fontScale + 0.5)
  }

  const renderMagnifyMinify = () => {
    const isMinifyDisabled = fontScale <= MAX_MINIFY
    const isMagnifyDisabled = fontScale >= MAX_MAGNIFY
    return (
      <div className='magnify-icon-wrap'>
        <FontAwesomeIcon
          icon={faSearchMinus}
          className={`magnify-icon ${isMinifyDisabled && 'in-active-icon'}`}
          onClick={onMinify}
        />
        <FontAwesomeIcon
          icon={faSearchPlus}
          className={`magnify-icon ${isMagnifyDisabled && 'in-active-icon'}`}
          onClick={onMagnify}
        />
      </div>
    )
  }

  return (
    <Modal show={showModal} onHide={handleClose} size='xl' className='magnify-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='magnify-modal-title'>
          {heading}
          {renderMagnifyMinify()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ fontSize: 11 + fontScale }}
          dangerouslySetInnerHTML={{
            __html: innerHtml,
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

export default MagnifyModal
