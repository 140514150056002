import { ProductDetailItemType } from 'types'
import { printDocument } from '../pdfHelper'
import { getCustomHTML } from '../pdfLayouts'
import { pageBreak } from '../styles'
import { TranslationsPDFType } from '../types'
import getProductDataShort from './getProductDataShort'

const toProductShortHTML = (
  products: ProductDetailItemType[],
  translations: TranslationsPDFType,
) => {
  let str = ''
  str += getProductDataShort(products, translations)

  return str
}

export const createProductShortPDF = (data: {
  products: ProductDetailItemType[]
  translations: TranslationsPDFType
}) => {
  const { products, translations } = data

  if (products.length === 0) return

  const productHTML = toProductShortHTML(products, translations)
  const htmlDOM = getCustomHTML({
    breadCrumbData: [],
    pageStyles: productShortStyle,
    title: translations.exportShort || 'Export short',
  })

  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += productHTML
  printDocument(htmlDOM)
}

const productShortStyle = `
.product-row {
  ${pageBreak}
}`
