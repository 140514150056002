import { createContext, useContext, useMemo } from 'react'
import { useSetState } from 'react-use'
import { Step } from 'react-joyride'
import { OnboardingResponseType } from 'types/onboardingType'

export interface AppState {
  run: boolean
  stepIndex: number
  steps: Step[]
  tourActive: boolean
  onboardingData: OnboardingResponseType
}

const appState: AppState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  onboardingData: {
    productGroupId: 0,
    productId: 0,
  },
}

type AppContextType = {
  setState: (patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>)) => void
  state: AppState
}

type AppProviderProps = {
  children: React.ReactNode
}

export const AppContext = createContext<AppContextType>({
  state: appState,
  setState: () => undefined,
})

AppContext.displayName = 'AppContext'

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, setState] = useSetState<AppState>(appState)

  const value = useMemo(() => ({ state, setState }), [state, setState])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider')
  }

  return context
}
