import { useState, forwardRef, useImperativeHandle } from 'react'
import moment from 'moment'
import { faClose, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { addMarkComment } from '../markingFunctions'
import { getMarkingDbById } from 'database/queries/marking'
import 'styles/components/markingComment.scss'

export interface MarkingCommentRef {
  closeComment: () => void
  openComment: () => void
  saveComment: () => void
}

interface MarkingCommentProps {
  activeMarkId?: string
}

const MarkingComment = forwardRef<MarkingCommentRef, MarkingCommentProps>((props, ref) => {
  const { activeMarkId } = props

  const translations = useSelector(selectTranslations)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')

  const addComment = translations?.add_comment ?? 'ADD COMMENT'
  const addTextComment = translations?.add_text_comment ?? 'ADD TEXT COMMENT'

  const closeComment = () => {
    setComment('')
    setIsOpen(false)
  }

  const openComment = async () => {
    if (!activeMarkId) return

    const id = Number(activeMarkId?.replace('mark-', ''))
    const markingData = await getMarkingDbById(id)
    if (markingData && markingData.comment) {
      setComment(markingData.comment)
    }

    setIsOpen(true)
  }

  const saveComment = () => {
    addMarkComment(activeMarkId, comment)
    closeComment()
  }

  useImperativeHandle(ref, () => ({
    closeComment,
    openComment,
    saveComment,
  }))

  return (
    <div id='commentContainer' className={`marking-comment ${isOpen ? 'open' : ''}`}>
      <Row>
        <Col md='10'>
          <FontAwesomeIcon size='1x' className='comment-date' icon={faComment} />
          <span className='comment-date'>{moment().format('D.M.yyyy')}</span>
        </Col>
        <Col md='2' onClick={closeComment} className='cursor-pointer'>
          <FontAwesomeIcon size='1x' className='comment-date' icon={faClose} />
        </Col>
      </Row>

      <textarea
        value={comment}
        id='commentContent'
        className='comment-texts'
        placeholder={`${addTextComment}...`}
        onChange={(e) => setComment(e.target.value)}
      />

      <Button onClick={saveComment} id='commentButton' className='comment-button'>
        {addComment}
      </Button>
    </div>
  )
})

MarkingComment.displayName = 'MarkingComment'

export default MarkingComment
