import { PageContentResponseType } from 'types'
import { LAYOUTS } from 'config/constants'
import { getContentRibbon } from 'config/utils/CommonFunction'
import { manualContentStyle, toManualContentHTML } from '../ManualContentPDF/ManualContentPDF'
import { getBreadcrumbData } from '../pdfHelper'
import { logoHTML, toBreadcrumbHTML, toRibbonHTML } from '../pdfLayouts'
import { Config } from './type'
import { asyncGetPageContent } from './utils'

export const getManualContentHTML = async (pageId: number, config: Config) => {
  const { isAppOnline, structure } = config
  const pageData = await asyncGetPageContent(pageId, isAppOnline)

  if (!pageData || !('content' in pageData)) return ''

  const ribbonData = getRibbonData(pageData)
  const parseRibbonData = getContentRibbon(ribbonData?.text ?? '')
  const breadCrumbData = getBreadcrumbData(pageId, structure)

  const breadCrumbHTML = toBreadcrumbHTML(breadCrumbData)
  const ribbonHTML = toRibbonHTML({
    headline: ribbonData?.headline ?? '',
    ...parseRibbonData,
  })
  const mainHTML = toManualContentHTML(pageData.content)

  return `
    <style>
    ${manualContentStyle}
    </style>
    ${logoHTML}
    ${breadCrumbHTML}
    ${ribbonHTML}
    ${mainHTML}
`
}

const getRibbonData = (pageData: PageContentResponseType) => {
  const ribbonData = pageData.content.find((item) => {
    const layoutConfig = item.config ? JSON.parse(item.config) : {}
    return layoutConfig.layout === LAYOUTS.CONTENT_RIBBON
  })

  if (!ribbonData) return null

  const layoutConfig = JSON.parse(ribbonData.config)
  const result: { headline: string; text: string } = layoutConfig.values?.[0]
  return result
}
