import React from 'react'
import { Row } from 'react-bootstrap'

function HorizontalRule(): React.ReactElement {
  return (
    <Row className='mt-4'>
      <hr className='ak-line' />
    </Row>
  )
}

export default HorizontalRule
