import { StyleSheet } from '@react-pdf/renderer'
import { Colors, SpacingX4 } from '../theme'

const styles = StyleSheet.create({
  divider: {
    marginTop: SpacingX4.p4,
    marginBottom: SpacingX4.p4,
    height: 1,
    backgroundColor: Colors.gray,
  },
})

const DividerPDF = () => {
  return <div style={styles.divider} />
}

export default DividerPDF
