import { useEffect, useRef, useState } from 'react'
import useJSPDF, { PDFInfoType } from './hooks/useJSPDF'
import './pdf.scss'
import PDFContent from './screens/PDFContent'
import { ProductDetailShortPDFWrapper } from './screens/ProductDetailShortPDF'

const WRAPPER_PREFIX_ID = 'pdf-wrapper-id'
const PDF = () => {
  const [pdfInfo, setPdfInfo] = useState<PDFInfoType[]>([])
  const downloadStatus = useRef<{ index: number; status?: boolean }[]>([])
  const { printDocuments } = useJSPDF()

  const isShortExport = pdfInfo.some((info) => info.type === 'productdetailsshort')

  useEffect(() => {
    const getSearchParams = () => {
      if (!window.electron?.isDesktop()) {
        return window.location.search
      }
      // for electron, the window.location.search doesn't work
      // have to extract the search params manually
      const fullUrl = window.location.href
      if (fullUrl.includes('?')) {
        return fullUrl.split('?')[1]
      }
      return ''
    }
    const searchParams = getSearchParams()
    const queryString = new URLSearchParams(searchParams)
    const pdfData = JSON.parse(queryString.get('data') || '[]')
    if (Array.isArray(pdfData) && pdfData.length > 0) {
      downloadStatus.current = pdfData.map((_, index) => ({ status: undefined, index }))
      setPdfInfo(pdfData)
    } else {
      window.close()
    }
  }, [])

  const onDownloadStatus = async (index: number, result: boolean) => {
    const itemIndex = downloadStatus.current.findIndex((status) => status.index === index)
    if (itemIndex === -1) return console.error('item not found')

    downloadStatus.current[itemIndex].status = result
    const nonDownloadStatus = downloadStatus.current.filter((data) => data.status === undefined)

    if (nonDownloadStatus.length === 0) {
      // all documents are downloaded, start printing documents
      const validDownloads = downloadStatus.current.filter((data) => data.status === true)

      // for export short pdf, only one document is available
      const elementIds = isShortExport
        ? [`${WRAPPER_PREFIX_ID}-0`]
        : validDownloads.map((data) => `${WRAPPER_PREFIX_ID}-${data.index}`)
      downloadStatus.current = []
      // await 100ms to make sure all components are rendered
      setTimeout(() => {
        printDocuments(elementIds)
      }, 100)
    }
  }

  return (
    <div id='pdf_container'>
      <h6>Loading...</h6>
      <div id='hidden_div'></div>
      <div id='pdf_wrapper'>
        {isShortExport ? (
          <div id={`${WRAPPER_PREFIX_ID}-0`}>
            <ProductDetailShortPDFWrapper products={pdfInfo} onDownloadStatus={onDownloadStatus} />
          </div>
        ) : (
          pdfInfo.map((pdf, index) => (
            <div id={`${WRAPPER_PREFIX_ID}-${index}`} key={`${pdf.pageId}-${index}`}>
              <PDFContent
                type={pdf.type}
                pageId={pdf.pageId}
                onDownloadStatus={(result: boolean) => onDownloadStatus(index, result)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default PDF
