import React, { useEffect } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import { handleSelectedMark } from 'features/Marking/markingFunctions'
import { usePDFLoading } from 'features/NewPDF/hooks/usePDFLoading'
import { ProductsNavType } from '../screens/ProductDetail/ProductDetail'
import 'styles/components/ribbon.scss'

type Props = {
  pageId?: number
  headline: string
  subHeadline?: JSX.Element | string
  description?: string
  targetId?: number
  productsNav?: ProductsNavType
  onAddFavorite: () => void
  onExportPDF?: (onDone: () => void) => void
}

const Ribbon: React.FC<Props> = ({
  pageId,
  targetId,
  headline,
  subHeadline,
  description,
  productsNav,
  onAddFavorite,
  onExportPDF,
}: Props) => {
  const translations = useSelector(selectTranslations)
  const { pdfLoading, handleExportPDF } = usePDFLoading(onExportPDF)

  const { onDynamicNavigate } = useNavigation()

  const exportText = translations.export || 'EXPORT'
  const moreInformationText = translations.more_information || 'MORE INFORMATION'

  /** Init Marking listener */
  useEffect(() => {
    document.addEventListener('mouseup', handleSelectedMark)

    return () => {
      document.removeEventListener('mouseup', handleSelectedMark)
    }
  }, [])

  const onGoToPage = (nextPageId?: number) => {
    if (!nextPageId) return

    onDynamicNavigate(nextPageId)
  }

  const onGoMoreInformation = () => {
    if (!targetId) return

    onDynamicNavigate(targetId)
  }

  const renderButtonLeft = () => {
    if (!productsNav || !productsNav.prev) return null

    return (
      <Button onClick={() => onGoToPage(productsNav.prev)} className='btn-left' variant='link'>
        <FontAwesomeIcon icon={faChevronLeft} className='icon' />
      </Button>
    )
  }

  const renderButtonRight = () => {
    if (!productsNav || !productsNav.next) return null

    return (
      <Button onClick={() => onGoToPage(productsNav.next)} className='btn-right' variant='link'>
        <FontAwesomeIcon icon={faChevronRight} className='icon' />
      </Button>
    )
  }

  const renderMiddle = () => {
    return (
      <Row>
        <Col md='9'>
          <h1
            id={`ribbon-headline-${pageId}`}
            dangerouslySetInnerHTML={{
              __html: headline,
            }}
          />
          {subHeadline && (
            <h6 id={`ribbon-sub-headline-${pageId}`} className='ak-bold'>
              {subHeadline}
            </h6>
          )}
          {description && (
            <p
              id={`ribbon-description-${pageId}`}
              dangerouslySetInnerHTML={{
                __html: description || '',
              }}
            />
          )}
        </Col>

        <Col md='3'>
          <Row className='d-flex justify-content-end'>
            <Col md='2'>
              <i
                onClick={onAddFavorite}
                role='button'
                tabIndex={0}
                className='karcher-icon fav-icon icon-a_068_feedback_line'
              >
                &#xe843;
              </i>
            </Col>
            {onExportPDF && (
              <Col md='2'>
                {pdfLoading ? (
                  <Spinner style={{ width: 12, height: 12 }} />
                ) : (
                  <i
                    onClick={handleExportPDF}
                    role='button'
                    tabIndex={0}
                    title={exportText}
                    className='karcher-icon export-icon icon-a_068_feedback_line ml-4'
                  >
                    &#xe850;
                  </i>
                )}
              </Col>
            )}
          </Row>

          {targetId && (
            <Row className='d-flex justify-content-end mt-5 '>
              <Button
                id='onboarding-btn-more-info'
                className='btn_more_info'
                onClick={onGoMoreInformation}
              >
                {moreInformationText}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <Container className='ribbon-wrapper' fluid>
      <Container>
        {renderButtonLeft()}

        {renderMiddle()}

        {renderButtonRight()}
      </Container>
    </Container>
  )
}

export default Ribbon
