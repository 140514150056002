import { showAlert } from 'config/utils/CommonFunction'
import { IDcaMarking } from 'database/dbTypes'
import {
  addMarkingDb,
  deleteMarkingDb,
  getListMarkingDb,
  getNextMarkingId,
  updateMarkingDb,
} from 'database/queries/marking'

export const MarkClassName = {
  SELECTED: 'select-mark',
  RED_MARK: 'red-mark',
  YELLOW_MARK: 'yellow-mark',
  GREEN_MARK: 'green-mark',
}

export const MarkingEventType = {
  MARK_MOUSEOVER: 'mark-mouseover',
}

export const MarkingTooltipData = {
  ID: 'marking-tooltip',
}

type RangeWithMark = Range & { markId?: string }

const containsNonTextNodes = (fragment: DocumentFragment): boolean => {
  let hasNonTextNodes = false
  let childNode = fragment.firstChild
  while (childNode) {
    if (childNode.nodeType !== Node.TEXT_NODE) {
      hasNonTextNodes = true
      break
    }
    childNode = childNode.nextSibling
  }

  return hasNonTextNodes
}

const getSelectedElementId = (selection: Selection) => {
  if (selection && selection.anchorNode) {
    const selectedNode = selection.anchorNode.parentElement

    if (selectedNode && selectedNode.getAttribute('id')) {
      return selectedNode.getAttribute('id')
    }
  }

  return null
}

const addMarkElementListener = (markElement: HTMLElement) => {
  markElement.addEventListener('mouseover', (event) => {
    const markEvent = new CustomEvent(MarkingEventType.MARK_MOUSEOVER, {
      detail: event.target,
    })
    document.dispatchEvent(markEvent)
  })
}

const createAndAppendMarkElement = (
  range: RangeWithMark,
  markId: string,
  colorName: string,
): void => {
  const markElement = document.createElement('mark')
  markElement.setAttribute('data-tooltip-id', MarkingTooltipData.ID)
  markElement.classList.add(MarkClassName.SELECTED, colorName)
  markElement.id = markId

  range.surroundContents(markElement)
  range.markId = markId

  // Listen to mouseover event on mark element and send the event to the Tooltip component
  addMarkElementListener(markElement)
}

const getStartOffset = (target: HTMLElement, selectedText: string): number => {
  const textContent = target.textContent ?? ''
  const startPosition = textContent.toLowerCase().indexOf(selectedText.toLowerCase())

  return startPosition !== -1 ? startPosition : 0
}

const applyMarkingToElement = async (mark: IDcaMarking): Promise<void> => {
  const { elementId, startOffset, endOffset, highlightColor, id } = mark
  const markId = `mark-${id}`
  const element = document.getElementById(elementId)

  if (!element) return

  const range = document.createRange()
  const fragment = await range.cloneContents()

  if (fragment.querySelector('mark')) {
    console.info('Can not mark already marked element')
    return
  }

  const { firstChild } = element
  const { length } = firstChild?.nodeValue ?? ''

  if (length === 0) return

  range.setStart(firstChild as Node, startOffset)
  range.setEnd(firstChild as Node, endOffset)
  createAndAppendMarkElement(range, markId, highlightColor)
}

export const initMarking = async (): Promise<void> => {
  try {
    const listMarking = await getListMarkingDb()

    if (!listMarking) {
      console.info('No marking data found')
      return
    }

    const markingGroup = listMarking.reduce((acc: { [key: string]: IDcaMarking[] }, obj) => {
      const key = obj.elementId
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})

    for (const key in markingGroup) {
      const markingOrder = markingGroup[key].slice().sort((a, b) => b.startOffset - a.startOffset)

      for (const mark of markingOrder) {
        await applyMarkingToElement(mark)
      }
    }
  } catch (error) {
    console.error('Error in initMarking', error)
  }
}

export const handleSelectedMark = async (event: MouseEvent): Promise<void> => {
  try {
    const target = event.target as HTMLElement
    const textSelection = document.getSelection()

    const selectedText = textSelection?.toString().trim()

    // if no text is selected, return early
    if (!selectedText || !textSelection || textSelection.rangeCount === 0) {
      return
    }

    const elementId = getSelectedElementId(textSelection)

    if (!elementId) {
      console.info('No marking element ID found')
      return
    }

    const range = textSelection.getRangeAt(0) as RangeWithMark
    const fragment = await range.cloneContents()

    const hasNonTextNodes = containsNonTextNodes(fragment)
    if (hasNonTextNodes) {
      console.info('Selected text contains non-text nodes')
      return
    }

    if (fragment.querySelector('mark')) {
      showAlert('Can not mark already marked element')
      return
    }

    const nextMarkingId = await getNextMarkingId()
    const uniqueId = `mark-${nextMarkingId}`

    createAndAppendMarkElement(range, uniqueId, MarkClassName.RED_MARK)

    // determine the start and end positions of the selected text
    const startPosition = getStartOffset(target, selectedText)
    const endPosition = startPosition + selectedText.length

    await addMarkingDb({
      id: nextMarkingId,
      elementId: elementId,
      markingText: selectedText,
      startOffset: startPosition,
      endOffset: endPosition,
      highlightColor: MarkClassName.RED_MARK,
      comment: '',
    })
  } catch (error) {
    console.error('Error in handleSelectedMark', error)
  }
}

export const changeColorMark = (
  markingId?: string,
  colorName?: string,
): HTMLElement | undefined => {
  if (!markingId || !colorName) return

  const markElement = document.getElementById(markingId)

  if (!markElement) {
    return
  }

  if (!markElement) {
    console.error(`Element with ID ${markingId} does not exist.`)
    return undefined
  }

  const newMarkElement = markElement.cloneNode(true) as HTMLElement
  newMarkElement.className = `${MarkClassName.SELECTED} ${colorName}`
  markElement.replaceWith(newMarkElement)
  addMarkElementListener(newMarkElement)

  const id = markingId.replace('mark-', '')
  updateMarkingDb({ id: Number(id), highlightColor: colorName })

  return newMarkElement
}

export const deleteMark = (markingId?: string): void => {
  if (!markingId) return

  const markElement = document.getElementById(markingId)

  if (!markElement) {
    showAlert(`Element with ID ${markingId} does not exist.`)
  }

  const textNode = document.createTextNode(markElement?.textContent ?? '')
  markElement?.replaceWith(textNode)
  const id = markingId.replace('mark-', '')
  deleteMarkingDb(Number(id))
}

export const addMarkComment = async (markingId?: string, comment?: string): Promise<void> => {
  if (!markingId || !comment) return

  const id = markingId.replace('mark-', '')
  await updateMarkingDb({ id: Number(id), comment })
}
