import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentAnchorItemType } from 'types'
import 'styles/components/anchor.scss'

type AnchorProps = {
  anchorsData: ContentAnchorItemType[]
  anchorRef?: React.RefObject<HTMLDivElement>
}

const ContentAnchor = (props: AnchorProps): React.ReactElement => {
  const { anchorsData, anchorRef } = props

  const [activeAnchor, setActiveAnchor] = useState<number | null>(null)

  /**
   * This effect will run when the component mounts and will create an IntersectionObserver
   * to observe the section elements inside the product section.
   * The observer will call the callback function when the section element is intersecting
   * with the root element (product section) and will set the active anchor.
   * The observer will disconnect when the component unmounts.
   */
  useEffect(() => {
    if (!anchorRef?.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        const activeEntry = entries.find((entry) => entry.isIntersecting)
        if (activeEntry && activeEntry.isIntersecting) {
          const activeAnchorIndex = anchorsData.findIndex(
            (anchor) => anchor.targetId === activeEntry.target.getAttribute('id'),
          )
          setActiveAnchor(activeAnchorIndex)
        }
      },
      { threshold: 0.8 },
    )

    anchorsData.forEach((anchor) => {
      const element = document.getElementById(anchor.targetId)
      if (element) observer.observe(element)
    })

    return () => {
      anchorsData.forEach((anchor) => {
        const element = document.getElementById(anchor.targetId)
        if (element) observer.unobserve(element)
      })
    }
  }, [anchorsData])

  const changeFocus = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    targetId: string,
  ) => {
    e.preventDefault()
    const element = document.getElementById(targetId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).selectedAnchor = targetId

    setActiveAnchor(index)
  }

  const renderItem = (anchor: { targetId: string; title: string }, index: number) => {
    const itemClassName = activeAnchor === index ? 'anchor-active-item' : 'anchor-item'
    return (
      <Col className={`${itemClassName} cursor-pointer`} key={anchor.targetId}>
        <a onClick={(e) => changeFocus(e, index, anchor.targetId)} className='p-3'>
          {typeof anchor.title === 'string' ? anchor.title.toUpperCase() : ''}
        </a>
      </Col>
    )
  }

  return (
    <Container>
      <Row className='anchor-wrapper'>
        {anchorsData.map((anchor, index) => renderItem(anchor, index))}
      </Row>
    </Container>
  )
}

export default ContentAnchor
