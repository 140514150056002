import { saveProductToCompareDb } from 'database/queries/comparison'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { saveProductToCompare } from 'redux/reducers/comparisonReducer'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import useAnalytics from 'hooks/useAnalytics'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import ProductFinderUI from './ProductFinder.ui'

type ProductData = {
  id: number
}

const ProductFinder = (): React.ReactElement => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { setPageInfoAnalytics } = useAnalytics()
  const productCompare = useSelector(selectProductComparison)
  const productCompareIds = productCompare?.map((item) => {
    return item.id
  })

  const pageId = location.state?.pageId || PAGE_IDS.PRODUCT_FINDER

  const [, , productGroupCode] = location.pathname.split('/')

  const zoovuAdvisorCode =
    productGroupCode ||
    (process.env.REACT_APP_DEPLOYMENT_ENV === 'prod' ? 'bsgpcBNt' : 'SCPTNpkQD8TJ')

  const getProductFinderLocale = (locale: string) => {
    if (locale === 'en-DCS' || locale === 'en-dcs') {
      locale = 'en-BM'
    } else if (locale === 'es-DCS' || locale === 'es-dcs') {
      locale = 'es-BM'
    }
    return locale
  }

  const startAssistant = (advisorCode: string, locale: string) => {
    const zoovuScript = document.createElement('script')
    const strCompareList = String(productCompareIds)
    zoovuScript.setAttribute('id', 'scriptTag')
    zoovuScript.type = 'text/javascript'
    zoovuScript.async = true
    zoovuScript.id = 'zoovu-script'
    locale = getProductFinderLocale(locale)
    zoovuScript.src = `https://orca-api.zoovu.com/advisor-fe-web/api/v1/advisors/${advisorCode}/js-loader?locale=${locale}&isWebApp=true&comparisonBasket=${strCompareList}`

    const script = document.getElementsByTagName('script')[0]
    script?.parentNode?.insertBefore(zoovuScript, script)
  }

  const injectCustomScript = async () => {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    if (!currentCountry || !currentLanguage) {
      return
    }

    const intLanguageCode = 'IN'
    const locale = `${currentLanguage.languageCode}-${
      currentCountry.code.toUpperCase() !== 'DCF'
        ? currentCountry.code.toUpperCase()
        : intLanguageCode
    }`
    startAssistant(zoovuAdvisorCode, locale)
  }

  const findAdvisorRevisionCode = (advisorCode: string) => {
    if (!window.ZoovuAdvisors) {
      return undefined
    }
    // extract the advisor from the list of advisors
    const advisor = Object.values(window.ZoovuAdvisors).find(
      (obj) => obj.advisorLoaderParameters.advisorCode === advisorCode,
    )
    // extract the advisorRevisionCode
    return advisor?.advisorLoaderParameters?.advisorRevisionCode
  }

  const deleteCustomScript = () => {
    const zooScript = document.querySelector('script#zoovu-script')
    const zooIdAssistant = document.getElementById('zoovu-assistant')
    if (zooScript) {
      zooScript.remove()
    }

    if (zooIdAssistant) {
      zooIdAssistant.remove()
    }

    if (window.ZoovuAdvisors) {
      const advisorRevisionCode = findAdvisorRevisionCode(zoovuAdvisorCode)
      if (advisorRevisionCode) {
        window.ZoovuAdvisors[advisorRevisionCode].advisorUI.destroy()
      }
    }
  }

  useEffect(() => {
    const addOrRemoveProductCompare = async (productData: ProductData) => {
      try {
        const newProductCompare = onAddOrRemoveProductCompare(productData)
        await saveProductToCompareDb(newProductCompare)
        dispatch(saveProductToCompare(newProductCompare))
      } catch (error) {
        console.error('Error saving product comparison:', error)
      }
    }

    const onAddOrRemoveProductCompare = (productData: ProductData) => {
      let newProductCompare = [...productCompare]
      const isProductExisted = productCompare.find((product) => product.id === productData.id)

      if (isProductExisted) {
        newProductCompare = newProductCompare.filter((product) => product.id !== productData.id)
      } else {
        newProductCompare.push(productData)
      }

      return newProductCompare
    }

    /** Analytics */
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.PRODUCT_FINDER,
      pageType: PAGE_TYPES.PRODUCT_FINDER,
    })

    window.addEventListener('comparison-feature', (e: Event) => {
      if (e instanceof CustomEvent && e.detail.length > 0) {
        console.info('eventZoovu', e.detail)
        e.detail.map((item: ProductData) => {
          addOrRemoveProductCompare(item)
        })
      }
    })

    injectCustomScript()

    return () => {
      deleteCustomScript()
    }
  }, [])

  return (
    <Container fluid className='product-finder-container p-0'>
      <Header pageId={pageId} />

      <Breadcrumb pageId={pageId} staticTitle={TRANSLATIONS.productFinder} />

      <ProductFinderUI pageId={pageId} />
    </Container>
  )
}

export default ProductFinder
