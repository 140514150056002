import { RootState } from 'redux/reducers'

export const selectProductDetailFilterState = (
  state: RootState,
  pageId: number | undefined,
): Partial<{
  accessorySelectedCategoryId: number
  accessorySelectedStandardOptionId: number
  detergentSelectedCategoryId: number
}> => {
  if (!pageId) return {}

  return state.navigation.productDetail.filterState?.[pageId] || {}
}
