import { isObjectEmpty } from 'config/utils/CommonFunction'
import React, { useEffect, useState } from 'react'
import InputRange, { Range } from 'react-input-range'
import 'react-input-range/lib/css/index.css'

interface RangeInputProps {
  rangeData: Range
  rangeSelected: Range
  filterKey: string
  unit: string
  onChangeRange: (filterKey: string, unit: string, value: Range) => void
}

const RangeInput: React.FC<RangeInputProps> = ({
  rangeData,
  rangeSelected,
  unit,
  filterKey,
  onChangeRange,
}) => {
  const { min, max } = rangeData
  const [minVal, setMinVal] = useState<number>(min)
  const [maxVal, setMaxVal] = useState<number>(max)

  useEffect(() => {
    if (isObjectEmpty(rangeSelected)) {
      setMinVal(min)
      setMaxVal(max)
    }
  }, [rangeSelected])

  const handleRangeChange = (value: Range | number) => {
    const data = value as Range
    setMinVal(data.min)
    setMaxVal(data.max)
    onChangeRange(filterKey, unit, data)
  }

  return (
    <InputRange
      maxValue={max}
      minValue={min}
      value={{ min: minVal, max: maxVal }}
      onChange={handleRangeChange}
    />
  )
}

export default RangeInput
