import { getProductGroupDbById, getSubProductGroupDbByIds } from 'database/queries/product'
import { getProductGroupQuery, getSubProductGroupQuery } from 'services'
import { ReactElement, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  ProductGroupDataType,
  ProductGroupItemType,
  ProductGroupResponseType,
  SubProductGroupItemType,
  SubProductGroupResponseType,
} from 'types'
import { LAYOUTS, PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { APP_CONTENT_ID, APP_HEADER_ID, useStyleContent } from 'config/utils/header'
import { RootState } from 'redux/reducers'
import { getPageContent } from 'redux/reducers/pageContentReducer'
import { getProductGroupProductFinder } from 'redux/reducers/productFinderReducer'
import { selectPageContent, selectProductGroupHasProductFinder } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import usePreviousValue from 'hooks/usePreviousValue'
import ErrorPage from 'features/Common/screens/ErrorPage'
import LoadingPage from 'features/Common/screens/LoadingPage'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import ProductGroupListUI from './ProductGroupList.ui'

const ProductGroupList = (): ReactElement => {
  useStyleContent()
  const dispatch = useDispatch()
  const { onDynamicNavigate } = useNavigation()
  const { setPageInfoAnalytics } = useAnalytics()
  const { pageId } = useParams<{ pageId: string }>()

  const pageIdNumber = Number(pageId)
  const previousPageId = usePreviousValue(pageIdNumber)
  const { fetchData } = useDataFetcher()

  const pageData = useSelector((state: RootState) => selectPageContent(state, pageIdNumber))
  const isGroupHasProductFinder: boolean = useSelector((state: RootState) =>
    selectProductGroupHasProductFinder(state, pageIdNumber),
  )
  const { data, error, loading } = pageData ?? {}

  const [productGroups, setProductGroups] = useState<ProductGroupItemType[]>()
  const [subProductGroups, setSubProductGroups] = useState<SubProductGroupItemType[]>()
  const [isDropdownAvailable, setIsDropdownAvailable] = useState<boolean>(false)
  const [dropdownData, setDropdownData] = useState<ProductGroupDataType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  /** Analytics and Marking Init */
  useEffect(() => {
    setPageInfoAnalytics({
      pageId: pageIdNumber,
      pageName: PAGE_NAMES.PRODUCT_GROUP_LIST,
      pageType: PAGE_TYPES.PRODUCT_GROUP_LIST,
    })
  }, [])

  useEffect(() => {
    if (!previousPageId || previousPageId !== pageIdNumber) {
      setIsLoading(true)
      dispatch(getPageContent(pageIdNumber))
      dispatch(getProductGroupProductFinder(pageIdNumber))
    }
  }, [dispatch, pageIdNumber])

  useEffect(() => {
    const handleSubProductGroup = async (productGroupsFilter: ProductGroupItemType[]) => {
      const productGroupIds = productGroupsFilter.map((item) => parseInt(item.id))
      const subProductGroup = await fetchData<number[], SubProductGroupResponseType>(
        getSubProductGroupQuery,
        getSubProductGroupDbByIds,
        productGroupIds,
        productGroupIds,
      )
      if (!subProductGroup) return

      setSubProductGroups(subProductGroup.data)
    }

    const handleProductGroup = async () => {
      try {
        if (!data || !('content' in data)) return

        const productGroupListObj = data.content.find(
          (item) => item.type === LAYOUTS.PRODUCT_GROUPS_LIST,
        )

        if (!productGroupListObj) return

        const productGroupConfig = JSON.parse(productGroupListObj.config || '{}')

        if (!productGroupConfig.productgroupId) return

        const productGroupsResponse = await fetchData<number, ProductGroupResponseType>(
          getProductGroupQuery,
          getProductGroupDbById,
          productGroupConfig.productgroupId,
          productGroupConfig.productgroupId,
        )

        if (!productGroupsResponse) return

        const productGroupsFilter = productGroupsResponse?.data?.filter((item) => {
          const [headerText] = item.texts

          return headerText
        })

        setProductGroups(productGroupsFilter)

        // Handle sub product group
        await handleSubProductGroup(productGroupsFilter)

        // Handle when dropdown is available
        const { isDropdownAvailable, dropdown } = productGroupsResponse
        if (isDropdownAvailable) {
          const dropdownData = JSON.parse(dropdown || '[]') as ProductGroupDataType[]

          setIsDropdownAvailable(isDropdownAvailable)
          setDropdownData(dropdownData)
        }
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (loading === false) {
      handleProductGroup()
    }
  }, [data, loading])

  // Error handling
  useEffect(() => {
    if (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }, [error])


  const onGoNexPage = (id: number) => {
    onDynamicNavigate(id)
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }

    if (isError) {
      return <ErrorPage />
    }

    if (data) {
      return (
        <ProductGroupListUI
          data={data}
          productGroups={productGroups}
          subProductGroups={subProductGroups}
          isGroupHasProductFinder={isGroupHasProductFinder}
          isDropdownAvailable={isDropdownAvailable}
          dropdownData={dropdownData}
          onGoNexPage={onGoNexPage}
        />
      )
    }

    return null
  }

  return (
    <Container className='group-list-container px-0' fluid>
      <div id={APP_HEADER_ID}>
        <Header pageId={pageIdNumber} />

        <Breadcrumb pageId={pageIdNumber} />
      </div>
      <div id={APP_CONTENT_ID}>{renderContent()}</div>
    </Container>
  )
}

export default ProductGroupList
