import { Button, Card, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import packageJson from '../../../../package.json'

const AppVersionBlock = () => {
  const { onDynamicNavigate } = useNavigation()
  const translations = useSelector(selectTranslations)

  const infoAndVersion = translations.info_and_version ?? 'Info & version'
  const versionTxt = translations.version ?? 'Version'
  const launchedInTxt = translations.launched_in ?? 'Launched in'
  const cookiePolicyTxt = translations.cookie_policy ?? 'Cookie Policy'

  const environment =
    process.env.REACT_APP_DEPLOYMENT_ENV !== 'prod' ? process.env.REACT_APP_DEPLOYMENT_ENV : ''
  const versionString = `${versionTxt} ${packageJson.version} (${environment})`
  const launchedInString = `${launchedInTxt} ${process.env.REACT_APP_LAUNCHED_IN}`
  const infoAppRestriction =
    translations.info_app_restriction ??
    '<b>Please note:</b> The use of this application is restricted to Kärcher partners, sales representatives and dealers. End customers are kindly asked to use the official website <a target="_blank" href="https://www.karcher.com">www.karcher.com</a>'

  const onGoCookiePolicy = () => {
    onDynamicNavigate(PAGE_IDS.COOKIE_POLICY)
  }

  return (
    <Card className='app-version-block setting-card'>
      <Row>
        <h6 className='settings-heading'>
          <i className='karcher-icon settings-icons'>&#xe819;</i>
          {infoAndVersion}
        </h6>

        <p className='settings-caption'>{versionString}</p>

        <p className='settings-caption'>{launchedInString}</p>

        <p
          className='settings-caption'
          dangerouslySetInnerHTML={{
            __html: infoAppRestriction || '',
          }}
        ></p>
      </Row>

      <div className='mb-2' style={{ marginTop: 24 }}>
        <Button className='app-button' onClick={onGoCookiePolicy}>
          {cookiePolicyTxt.toUpperCase()}
        </Button>
      </div>
    </Card>
  )
}

export default AppVersionBlock
