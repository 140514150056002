import images from 'assets/images/images'
import { capitalize } from 'lodash'
import { PopUpDataType } from 'types/userNotificationsType'
import { Button, Modal } from 'react-bootstrap'
import DCAImage from 'components/DCAImage'
import 'styles/modal/confirmModal.scss'

export type PopUpModalProps = {
  showModal: boolean
  popUpData: PopUpDataType
  handleClose: () => void
}

const PopUpModal = (props: PopUpModalProps): React.ReactElement => {
  const { handleClose, showModal, popUpData } = props

  const headline = popUpData?.headline ?? ''
  const textObject = JSON.parse(popUpData?.content ?? '')
  const text = textObject[0].children[0].text

  return (
    <Modal show={showModal} onHide={handleClose}>
      <DCAImage
        id='onboarding-logo'
        className='ak-logo'
        alt='logo'
        src={images.logo}
        style={styles.imgLogo}
      />
      <Modal.Header style={styles.header}>
        <Modal.Title>USER NOTIFICATION: {capitalize(headline)}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ alignSelf: 'center' }}>{text}</Modal.Body>
      <Modal.Footer style={styles.footer}>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PopUpModal

const styles = {
  imgLogo: {
    margin: 10,
    alignSelf: 'center',
  },
  header: { border: 'none', alignSelf: 'center' },
  footer: { border: 'none', alignSelf: 'center' },
}
