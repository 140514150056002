import useNavigation from '../../../hooks/useNavigation'
import { SearchContent } from '../../../types/searchType'

export const SearchContentItem = ({ item }: { item: SearchContent }) => {
  const { onDynamicNavigate } = useNavigation()

  const onGoNextPage = () => onDynamicNavigate(item?.pageId)

  return (
    <div className='cursor-pointer' onClick={onGoNextPage}>
      <h4
        className='search-card-title'
        dangerouslySetInnerHTML={{
          __html: item?.headline,
        }}
      />
      {item?.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: item.text,
          }}
        />
      )}
    </div>
  )
}
