import { combineReducers } from '@reduxjs/toolkit'
import countriesReducer from './countriesReducer'
import appOnlineReducer from './appOnlineReducer'
import translationsReducer from './translationsReducer'
import pageContentReducer from './pageContentReducer'
import structureReducer from './structureReducer'
import productFinderReducer from './productFinderReducer'
import analyticsReducer from './analyticsReducer'
import productGroupReducer from './productGroupReducer'
import comparisonReducer from './comparisonReducer'
import favoritesReducer from './favoritesReducer'
import downloadReducer from '../download/download.reducer'
import navigationReducer from './navigationReducer'

const rootReducer = combineReducers({
  countries: countriesReducer,
  appOnline: appOnlineReducer,
  translations: translationsReducer,
  pageContent: pageContentReducer,
  structure: structureReducer,
  productFinder: productFinderReducer,
  analytics: analyticsReducer,
  productGroup: productGroupReducer,
  comparison: comparisonReducer,
  favorites: favoritesReducer,
  download: downloadReducer,
  navigation: navigationReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
