import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ProductFinderState = {
  hasProductFinder: boolean
  availabilityFinderLoading: boolean
  availabilityFinderError: boolean
  productGroupHasProductFinder: Record<string, boolean>
  productGroupFinderLoading: boolean
  productGroupFinderError: boolean
}

const initialState: ProductFinderState = {
  hasProductFinder: false,
  availabilityFinderLoading: false,
  availabilityFinderError: false,
  productGroupHasProductFinder: {},
  productGroupFinderLoading: false,
  productGroupFinderError: false,
}

const productFinderSlice = createSlice({
  name: 'productFinder',
  initialState,
  reducers: {
    getProductFinderAvailability: (state) => {
      state.availabilityFinderLoading = true
      state.availabilityFinderError = false
    },
    getProductFinderAvailabilitySuccess: (state, action: PayloadAction<boolean>) => {
      state.availabilityFinderLoading = false
      state.hasProductFinder = action.payload
    },
    getProductFinderAvailabilityFailure: (state) => {
      state.availabilityFinderLoading = false
      state.availabilityFinderError = true
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProductGroupProductFinder: (state, action: PayloadAction<number>) => {
      state.productGroupFinderLoading = true
      state.productGroupFinderError = false
    },
    getProductGroupProductFinderSuccess: (
      state,
      action: PayloadAction<{ productGroupId: number; isAvailable: boolean }>,
    ) => {
      state.productGroupFinderLoading = false
      const { productGroupId, isAvailable } = action.payload
      state.productGroupHasProductFinder[productGroupId] = isAvailable
    },
    getProductGroupProductFinderFailure: (state) => {
      state.productGroupFinderLoading = false
      state.productGroupFinderError = true
    },
    resetProductFinder: () => initialState,
  },
})

export const {
  getProductFinderAvailability,
  getProductFinderAvailabilitySuccess,
  getProductFinderAvailabilityFailure,
  getProductGroupProductFinder,
  getProductGroupProductFinderSuccess,
  getProductGroupProductFinderFailure,
  resetProductFinder,
} = productFinderSlice.actions

export default productFinderSlice.reducer
