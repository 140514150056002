import {
  Bucket,
  ProductGroupCategory,
  RootlineUpdated,
  SearchContent,
  SearchItem,
  SearchQueryDataKey,
} from 'types/searchType'
import React, { useMemo, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { ProductCategoryDropdown } from './ProductCategoryDropdown'
import ProductItem from './ProductItem'
import { SearchContentItem } from './SearchContentItem'

const NUMBER_ITEMS_TO_SHOW = 8

interface SearchTabProps {
  tabKey: string
  tabData: Bucket
}

const SearchTab = ({ tabKey, tabData }: SearchTabProps) => {
  const translations = useSelector(selectTranslations)

  const [isShowAll, setIsShowAll] = useState<boolean>(false)
  const [numItemsToShow, setNumItemsToShow] = useState<number>(NUMBER_ITEMS_TO_SHOW)
  const [selectedCategories, setSelectedCategories] = useState<ProductGroupCategory[]>([])
  const [filteredDataLength, setFilteredDataLength] = useState(tabData.docCount)
  const title = translations?.[tabKey] ?? tabKey
  const resultFor = translations?.results_for ?? 'Results for'
  const showMore = translations?.show_more ?? 'Show more'
  const showAll = translations?.show_all ?? 'Show all'

  const filteredData = (data: SearchItem[]) => {
    if (selectedCategories.length === 0) {
      return data
    }

    return data.filter((item) => {
      const rootLineUpdatedObj: RootlineUpdated[] = item.rootline_updated
        ? JSON.parse(item.rootline_updated)
        : []
      return selectedCategories.some((category: ProductGroupCategory) => {
        return rootLineUpdatedObj.some(
          (rootLineItem: RootlineUpdated) => rootLineItem.id === category.id,
        )
      })
    })
  }

  const totalItems = filteredDataLength
  const numberItemsLeft = totalItems - numItemsToShow
  const maxNumItemsToShow =
    numberItemsLeft > NUMBER_ITEMS_TO_SHOW ? NUMBER_ITEMS_TO_SHOW : numberItemsLeft

  const showMoreTxt = `${showMore.toUpperCase()} (${maxNumItemsToShow}/${numberItemsLeft})`
  const showAllTxt = `${showAll.toUpperCase()} (${totalItems - numItemsToShow})`

  const onShowMore = () => {
    setNumItemsToShow((preNumber) => preNumber + NUMBER_ITEMS_TO_SHOW)
  }

  const onShowAll = () => {
    setNumItemsToShow(totalItems)
    setIsShowAll(true)
  }

  const renderProductList = (searchItemsData: SearchItem[]) =>
    useMemo(() => {
      const data = filteredData(searchItemsData)
      const itemsToShow = data.slice(0, numItemsToShow)
      setFilteredDataLength(data.length)
      return (
        <Row className='list-product'>
          {itemsToShow?.map((product) => {
            if (product) {
              return (
                <Col md='3' key={product?.id} className='product-item mb-4 px-4'>
                  <ProductItem productData={product} />
                </Col>
              )
            }
          })}
        </Row>
      )
    }, [tabData.items, selectedCategories, numItemsToShow])

  const renderSearchContentList = (searchContentData: SearchContent[]) =>
    useMemo(() => {
      setFilteredDataLength(tabData.docCount)
      const data = isShowAll ? searchContentData : searchContentData.slice(0, numItemsToShow)
      return (
        <Row>
          {data.map((item) => (
            <SearchContentItem key={item.pageId} item={item} />
          ))}
        </Row>
      )
    }, [tabData.items, numItemsToShow])

  const removeSelectedCategory = (category: ProductGroupCategory) => {
    setSelectedCategories((c) => c.filter((item) => item.id !== category.id))
  }

  const handleCategoryChange = (category: ProductGroupCategory, isChecked: boolean) => {
    !isChecked
      ? setSelectedCategories([...selectedCategories, { ...category }])
      : removeSelectedCategory(category)
  }

  return (
    <div key={tabKey} className='my-3'>
      <p className='search-tab-title'>{`${totalItems} ${resultFor} "${title}"`}</p>

      <ProductCategoryDropdown
        handleCategoryChange={handleCategoryChange}
        removeSelectedCategory={removeSelectedCategory}
        pgCategories={tabData.pgCategories}
        selectedCategories={selectedCategories}
      />
      {tabKey === SearchQueryDataKey.content
        ? renderSearchContentList(tabData.items as SearchContent[])
        : renderProductList(tabData.items as SearchItem[])}

      <div>
        {numItemsToShow < totalItems && !isShowAll && (
          <Button variant='primary' className='btn-load-more' onClick={onShowMore}>
            {showMoreTxt}
          </Button>
        )}
        {!isShowAll && numItemsToShow < totalItems && (
          <Button variant='primary' className='btn-load-more ms-2' onClick={onShowAll}>
            {showAllTxt}
          </Button>
        )}
      </div>
    </div>
  )
}

export default SearchTab
