import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectTranslations } from '../../../redux/selector'
import { ProductGroupCategory } from '../../../types/searchType'


export const ProductCategoryDropdown = ({
  handleCategoryChange,
  removeSelectedCategory,
  selectedCategories,
  pgCategories,
}: {
  handleCategoryChange: (pgCategories: ProductGroupCategory, isChecked: boolean) => void
  removeSelectedCategory: (pgCategories: ProductGroupCategory) => void
  selectedCategories: ProductGroupCategory[]
  pgCategories?: ProductGroupCategory[]
}) => {
  const translations = useSelector(selectTranslations)

  if (!pgCategories) {
    return null
  }

  return (
    <>
      <Dropdown className='filter-search-categories-dropdown'>
        <Dropdown.Toggle className='w-100'>{translations?.category ?? 'Category'}</Dropdown.Toggle>
        <Dropdown.Menu>
          {pgCategories?.map((category) => {
            const isChecked = !!selectedCategories?.find((c) => c.id === category.id)
            return (
              <div key={category.id}>
                <Row>
                  <Col md={1}>
                    <input
                      type='checkbox'
                      checked={isChecked}
                      className={isChecked ? 'checked' : ''}
                      onChange={() => handleCategoryChange(category, isChecked)}
                    />
                  </Col>
                  <Col md={10} disabled={true}>
                    <div className='search-card-desc'>{category.title}</div>
                  </Col>
                </Row>
              </div>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>

      <div style={{ display: 'flex' }}>
        {selectedCategories?.map((category) => {
          return (
            <div className={'search-category-tile'} key={category.id}>
              {category.title}
              <FontAwesomeIcon
                className={'cursor-pointer'}
                icon={faClose}
                style={{ padding: 6 }}
                onClick={() => removeSelectedCategory(category)}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}
