import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import { TRANSLATIONS } from 'config/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

type DescriptionCompare = {
  descriptionTable: AkTableType
}

const DescriptionCompare = ({ descriptionTable }: DescriptionCompare) => {
  const translations = useSelector(selectTranslations)

  const [isShow, setIsShow] = useState<boolean>(true)

  const equipmentsTxt = translations.description ?? TRANSLATIONS.description

  const onShowFull = () => setIsShow(!isShow)

  if (!descriptionTable || descriptionTable.length === 0) return null

  return (
    <div className='description-equipments'>
      <h6 className='mb-4'>
        {equipmentsTxt.toUpperCase()}

        {isShow ? (
          <span className='p-2' onClick={onShowFull}>
            <FontAwesomeIcon size='xs' icon={faAngleUp} />
          </span>
        ) : (
          <span className='p-2' onClick={onShowFull}>
            <FontAwesomeIcon size='xs' icon={faAngleDown} />
          </span>
        )}
      </h6>

      {isShow && <AkTable table={descriptionTable} />}
    </div>
  )
}

export default DescriptionCompare
