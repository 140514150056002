type AWSConfigType = {
  [key: string]: object
}

const AWS_CONFIG: AWSConfigType = {
  prod: {
    identityPoolId: '',
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_K7KTXUISs',
    userPoolWebClientId: '1i4qpmusd3ngv7pea66psrpjff',
  },
  stage: {
    identityPoolId: '',
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_HyCicqX6Y',
    userPoolWebClientId: 'r7vberuggva96mogb578dnma',
  },
  development: {
    identityPoolId: '',
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_Qnwsm2Ujq',
    userPoolWebClientId: 'p4r4ohrjinp2gk0f3pvp2ddor',
  },
}

const env = process.env.REACT_APP_DEPLOYMENT_ENV ?? ''

export default AWS_CONFIG[env]
