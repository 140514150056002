import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PageContentResponseType } from 'types/pageContentType'

export interface PageContentItem {
  data: PageContentResponseType
  loading: boolean
  error: boolean
}

export type PageContentState = {
  [pageId: number]: PageContentItem
}

const initialState: PageContentState = {}

export const pageContentSlice = createSlice({
  name: 'pageContent',
  initialState,
  reducers: {
    getPageContent(state, action: PayloadAction<number>) {
      const pageId = action.payload
      state[pageId] = {
        loading: true,
        error: false,
        data: {} as PageContentResponseType,
      }
    },
    getPageContentWithCallback(state, action: PayloadAction<{ pageId: number, onSuccess?: (response: PageContentResponseType) => void, onFailure?: () => void }>) {
      const pageId = action.payload.pageId
      state[pageId] = {
        loading: true,
        error: false,
        data: {} as PageContentResponseType,
      }
    },
    getPageContentSuccess(
      state,
      action: PayloadAction<{ pageId: number; obj: PageContentResponseType }>,
    ) {
      const { pageId, obj } = action.payload
      state[pageId] = {
        loading: false,
        error: false,
        data: obj,
      }
    },
    getPageContentError(state, action: PayloadAction<number>) {
      const pageId = action.payload
      state[pageId] = {
        loading: false,
        error: true,
        data: {} as PageContentResponseType,
      }
    },
    resetPageContent: () => initialState,
  },
})

export const { getPageContent, getPageContentSuccess, getPageContentError, resetPageContent, getPageContentWithCallback } =
  pageContentSlice.actions

export default pageContentSlice.reducer
