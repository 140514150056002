import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import LoadingPage from 'features/Common/screens/LoadingPage'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import FavoritesUI from './Favorites.ui'

const Favorites = () => {
  const { setPageInfoAnalytics } = useAnalytics()
  const translations = useSelector(selectTranslations)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const favoritesTitle = translations.favorites ?? TRANSLATIONS.favorites

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.COMPARISON,
      pageType: PAGE_TYPES.COMPARISON,
    })
    setIsLoading(false)
  }, [])

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }

    return <FavoritesUI />
  }

  return (
    <Container className='favorites-screen-wrapper px-0' fluid>
      <Header pageId={PAGE_IDS.FAVORITES_SCREEN} />

      <Breadcrumb pageId={PAGE_IDS.FAVORITES_SCREEN} staticTitle={favoritesTitle} />

      {renderContent()}
    </Container>
  )
}

export default Favorites
