import { UserNotificationsResponseType } from 'types/userNotificationsType'
import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentLanguage } from 'config/utils/CommonFunction'
import { GET_USER_NOTIFICATIONS } from './queries/popUpQueries'

export const getUserNotifications = async (): Promise<
  UserNotificationsResponseType | undefined
> => {
  try {
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: GET_USER_NOTIFICATIONS(currentLanguage.languageCode),
    })

    if (!data || !data.userNotifications) {
      throw new Error('data is undefined')
    }
    return data.userNotifications as UserNotificationsResponseType
  } catch (error) {
    console.error('Error getUserNotifications:', error)
    return undefined
  }
}
