import Dexie from 'dexie'

/**
 * Get all data from a table in a specific database.
 * @param db The database to query.
 * @param tableName The name of the table to retrieve data from.
 * @returns A Promise that resolves to an array of objects representing the table rows.
 */
async function getAllDataFromTable<T>(db: Dexie, tableName: string): Promise<T[keyof T][]> {
  try {
    return await db.table(tableName).toArray()
  } catch (error) {
    console.error(`Error retrieving data from table ${tableName}: ${error}`)
    return []
  }
}

/**
 * Get a specific item from a table in a Dexie database.
 * @param db The database to query.
 * @param tableName The name of the table to retrieve the item from.
 * @param itemId The ID of the item to retrieve.
 * @returns A Promise that resolves to the item object, or null if the item is not found.
 */
async function getItemFromTable<T>(db: Dexie, tableName: string, itemId: string | number): Promise<T | null> {
    try {
      const item = await db.table(tableName).get(itemId);
      return item || null;
    } catch (error) {
      console.error(`Error retrieving item ${itemId} from table ${tableName}: ${error}`);
      return null;
    }
  }

export { getAllDataFromTable, getItemFromTable }
