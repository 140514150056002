import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProductDetailItemType } from 'types'
import { TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import DCAImage from 'components/DCAImage'
import { useProductComparison } from '../hooks/useProductComparison'

type ImagesSectionProps = {
  productCompareDetails: ProductDetailItemType[]
}

const ImagesSection = ({ productCompareDetails }: ImagesSectionProps) => {
  const { onDynamicNavigate } = useNavigation()
  const { onAddOrRemoveProductCompare } = useProductComparison()
  const translations = useSelector(selectTranslations)

  const orderNumberTxt = translations.order_number ?? TRANSLATIONS.orderNumber
  const priceTxt = translations.price ?? TRANSLATIONS.price
  const removeTxt = translations.remove ?? TRANSLATIONS.remove
  const priceRemarkTitle =
    translations.price_remark ?? '*recommended retail price. Prices by the dealer may vary.'

  const renderListProductInfo = () => {
    return productCompareDetails.map((productDetail) => {
      const imageUrl = productDetail?.images[0]?.urls.find((img) => img.type === 'full')
      return (
        <td width='20%' key={productDetail.id}>
          <p
            className='export-text cursor-pointer'
            onClick={() => onAddOrRemoveProductCompare(productDetail)}
          >
            <i className='karcher-icon'>&#xe831;</i> {removeTxt}
          </p>

          <div className='export-text cursor-pointer'>
            <DCAImage
              className='product-compare-image'
              type='cardImage'
              src={imageUrl?.url ?? ''}
              onClick={() => {
                onDynamicNavigate(productDetail.id)
              }}
            />
          </div>

          <div className='ak-bold'>{productDetail?.name}</div>
          <div className='ak-bold'>
            {orderNumberTxt}: {productDetail?.partnumberFormatted}
          </div>
          {productDetail?.priceFormatted && (
            <>
              <div className='ak-bold'>
                {priceTxt}: {productDetail?.priceFormatted}
              </div>
              <div style={{ maxWidth: 200 }}>{priceRemarkTitle}</div>
            </>
          )}
        </td>
      )
    })
  }

  if (productCompareDetails.length === 0) return <></>

  return (
    <div className='table-container' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <Table className='ak-table table-borderless'>
        <tbody>
          <tr>
            <td width='20%'></td>
            {renderListProductInfo()}
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ImagesSection
