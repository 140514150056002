import * as Sentry from '@sentry/react'

const SENTRY_CLIENT_ID = '1i4qpmusd3ngv7pea66psrpjff'

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  autoSessionTracking: true,
  release: process.env.REACT_APP_VERSION,
  initialScope: {
    user: { ClientId: SENTRY_CLIENT_ID },
  },
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  enabled: true,
  environment: process.env.REACT_APP_DEPLOYMENT_ENV,
  enableTracing: true,
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_DEPLOYMENT_ENV === 'prod' ? 0.5 : 1,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export { Sentry }
