import { getProductDetailDbById } from 'database/queries/product'
import { toast } from 'react-toastify'
import { getProductDetailQuery } from 'services'
import { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FavoriteFolderType, ProductDetailItemType, ProductDetailResponseType } from 'types'
import { ALL_FOLDER, LAYOUTS, TRANSLATIONS } from 'config/constants'
import { useSelector } from 'redux/hooks/useSelector'
import { selectAppOnline, selectStructure, selectTranslations } from 'redux/selector'
import { selectFavoritesContent } from 'redux/selector/favoritesSelector'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import { createCombinedPDF } from 'features/NewPDF/CombinedPDF/CombinedPDF'
import { createProductShortPDF } from 'features/NewPDF/ProductShortPDF/ProductShortPDF'
import FavFolderTree from './FavFolderTree'
import { FavoritesList } from './FavoritesList'
import 'styles/pages/favorites.scss'

const FavoritesUI = () => {
  const { onGoBack } = useNavigation()
  const { fetchData } = useDataFetcher()

  const translations = useSelector(selectTranslations)
  const favoriteContent = useSelector(selectFavoritesContent)
  const [activeFolder, setActiveFolder] = useState<FavoriteFolderType>()
  const isAppOnline = useSelector(selectAppOnline)
  const structure = useSelector(selectStructure) || []

  const myFavoritesTitle = translations.my_favorites ?? TRANSLATIONS.myFavorites
  const backToPreviousPage = translations.back_to_previous_page ?? TRANSLATIONS.backToPreViousPage

  const onSortItem = () => {
    // TODO: Implement sort item
  }

  const onCreateNewFolder = () => {
    // TODO: Implement create new folder
  }

  const onExportFavAsFile = () => {
    // TODO: Implement export favorite as file
  }

  const onShowImportModal = () => {
    // TODO: Implement show import modal
  }

  const handleActiveFolderData = (activeFolderData: FavoriteFolderType) => {
    if (!activeFolderData) return

    setActiveFolder(activeFolderData)
  }

  const menuItems = [
    {
      eventKey: 'sort',
      label: translations?.sort_by_name ?? TRANSLATIONS.sortByName,
      onSelect: onSortItem,
    },
    {
      eventKey: 'createFolder',
      label: translations?.create_new_folder ?? TRANSLATIONS.createNewFolder,
      onSelect: onCreateNewFolder,
    },
    {
      eventKey: 'export',
      label: translations?.export_favorite_as_text ?? TRANSLATIONS.exportFavoriteAsText,
      onSelect: onExportFavAsFile,
    },
    {
      eventKey: 'import',
      label: translations?.import_favorites ?? TRANSLATIONS.importFavorites,
      onSelect: onShowImportModal,
    },
  ]

  const renderHeader = () => (
    <Row>
      <Col md='3'>
        <h1>{myFavoritesTitle}</h1>
      </Col>
      <Col md='3'>
        <Button variant='primary' className='app-button' onClick={onGoBack}>
          {backToPreviousPage.toUpperCase()}
        </Button>
      </Col>
    </Row>
  )

  // TODO: implement later when understand more about this feature
  // const renderSearch = () => (
  //   <Row className='d-flex'>
  //     <Col md='11'>
  //       <SearchBar />
  //     </Col>
  //     <Col md='1'>
  //       <Dropdown onSelect={(eventKey) => test(eventKey)}>
  //         <div className='ml-3 mt-1'>
  //           <Dropdown.Toggle bsPrefix='toggleDropDown' childBsPrefix='none'>
  //             <i className='karcher-icon'> &#xe81f; </i>
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu className='dropdown-menu'>
  //             {menuItems.map((item) => (
  //               <Dropdown.Item key={item.eventKey} eventKey={item.eventKey}>
  //                 {item.label}
  //               </Dropdown.Item>
  //             ))}
  //           </Dropdown.Menu>
  //         </div>
  //       </Dropdown>
  //     </Col>
  //   </Row>
  // )

  const handleExportPDFs = (folderID: number) => {
    const favoriteData =
      folderID === ALL_FOLDER.id
        ? favoriteContent
        : favoriteContent.filter((item) => item.folderID === folderID)
    const exportedPDFData = [...favoriteData]
      .sort((a, b) => a.position - b.position)
      .map((i) => ({ type: i.type, pageId: i.pageId, key: i.id }))

    createCombinedPDF(exportedPDFData, {
      translations,
      isAppOnline,
      structure,
    })
  }

  const handleExportShortPDFs = async (folderID: number) => {
    const toastId = toast('Loading PDF...', { autoClose: 2000 })
    const favoriteData =
      folderID === ALL_FOLDER.id
        ? favoriteContent
        : favoriteContent.filter((item) => item.folderID === folderID)

    const favoriteDataFiltered = favoriteData.filter(
      (item) => item.type === LAYOUTS.PRODUCT_DETAILS,
    )

    const exportedPDFData = favoriteDataFiltered
      .sort((a, b) => a.position - b.position)
      .map((i) => ({ type: LAYOUTS.PRODUCT_DETAILS_SHORT, pageId: i.pageId, key: i.id }))

    const products = await Promise.all(
      exportedPDFData.map(async (product) => {
        const productResponse = await fetchData<number, ProductDetailResponseType>(
          getProductDetailQuery,
          getProductDetailDbById,
          product.pageId,
          product.pageId,
        )
        const productData: ProductDetailItemType = JSON.parse(productResponse?.data || '{}')
        return productData
      }),
    )
    toast.dismiss(toastId)
    createProductShortPDF({ products, translations })
  }

  const renderFavorites = () => (
    <Row className='mt-4'>
      <Col md='4'>
        <FavFolderTree
          onActiveFolderData={handleActiveFolderData}
          onExportFolder={handleExportPDFs}
          onExportFolderShort={handleExportShortPDFs}
        />
      </Col>
      <Col md='8' className='favorite-content'>
        <FavoritesList />
      </Col>
    </Row>
  )
  return (
    <Container className='favorites-container' fluid>
      <Container className='py-4'>
        {renderHeader()}

        {/* {renderSearch()} */}

        {renderFavorites()}
      </Container>
    </Container>
  )
}

export default FavoritesUI
