import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PAGE_IDS } from 'config/constants'
import { selectStructure } from 'redux/selector'

const ROUTE_PATHS = {
  [PAGE_IDS.BUSINESS_UNIT_ENTRY]: '/business-entry',
  [PAGE_IDS.SETTINGS]: '/settings',
  [PAGE_IDS.COMPARISON_SCREEN]: '/comparison',
  [PAGE_IDS.COOKIE_POLICY]: '/cookie-policy',
  [PAGE_IDS.FAVORITES_SCREEN]: '/favorites',
}

const useNavigation = () => {
  const navigate = useNavigate()
  const structure = useSelector(selectStructure)

  const onDynamicNavigate = (pageId: number, params?: Record<string, string>) => {
    if (!structure) return

    const nextPageData = structure.find((page) => page.id === pageId)
    const nextPageChildrenData = structure.find((item) => item.parentId === pageId)

    const parentPageData = structure.find((page) => page.id === nextPageData?.parentId)
    const isGroupOverview = parentPageData?.parentId === PAGE_IDS.BUSINESS_UNIT_ENTRY

    switch (true) {
      case pageId in ROUTE_PATHS:
        navigate(ROUTE_PATHS[pageId])
        break
      case pageId === PAGE_IDS.PRODUCT_FINDER:
        navigate('/productFinder', { state: { pageId } })
        break
      case pageId === PAGE_IDS.SEARCH_SCREEN:
        const searchKey = params?.searchKey ?? ''
        navigate(`/search/${encodeURIComponent(searchKey)}`)
        break
      case nextPageData?.type === 'productgroup' && nextPageChildrenData?.type === 'productgroup':
        if (isGroupOverview) {
          navigate(`/group-overview/${pageId}`)
        } else {
          navigate(`/group-list/${pageId}`)
        }
        break
      case nextPageData?.type === 'productgroup' && nextPageChildrenData?.type === 'product':
        navigate(`/product-list/${pageId}`)
        break
      case nextPageData?.type === 'product':
        navigate(`/product/${pageId}`)
        break
      default:
        navigate(`/manual-content/${pageId}`, { state: params })
        break
    }
  }

  const onGoBack = () => navigate(-1)

  return { onDynamicNavigate, onGoBack }
}

export default useNavigation
