import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum DocumentType {
  AK_DCA_HOME_AND_GARDEN = 'AK_DCA_HOME_AND_GARDEN',
  AK_DCA_PROFESSIONAL = 'AK_DCA_PROFESSIONAL',
  HOME_AND_GARDEN_ASSET = 'HOME_AND_GARDEN_ASSET',
  PRO_ASSET = 'PRO_ASSET',
  AK_DCA_COMMON = 'AK_DCA_COMMON',
  COMMON_ASSET = 'COMMON_ASSET',
}

export const DOCUMENT_KEYS = [
  DocumentType.AK_DCA_COMMON,
  DocumentType.AK_DCA_HOME_AND_GARDEN,
  DocumentType.AK_DCA_PROFESSIONAL,
];

export const ASSET_KEYS = [
  DocumentType.COMMON_ASSET,
  DocumentType.HOME_AND_GARDEN_ASSET,
  DocumentType.PRO_ASSET,
];

export const ASSET_PATCH_SIZE = 3;
export const MAX_IMAGE_SIZE_FOR_PATCH_UPDATE = 1000;
export type DownloadStatus = 'NONE' | 'DOWNLOADING' | 'PAUSED' | 'DONE' | 'FAILED' | 'STOPPED';

export type DownloadInfo = {
  id: DocumentType;
  state: DownloadStatus;
  percent: number;
  bytesWritten: number;
  totalBytes: number;
  downloadFinishedAt?: number;
  createdAt?: number;
};

export type DownloadReducer = {
  [key in DocumentType]: DownloadInfo | null;
};

const initialState: DownloadReducer = {
  [DocumentType.AK_DCA_HOME_AND_GARDEN]: null,
  [DocumentType.AK_DCA_PROFESSIONAL]: null,
  [DocumentType.AK_DCA_COMMON]: null,
  [DocumentType.HOME_AND_GARDEN_ASSET]: null,
  [DocumentType.PRO_ASSET]: null,
  [DocumentType.COMMON_ASSET]: null,
};

export const INIT_DOWNLOAD_DATA: Omit<DownloadInfo, 'id'> = {
  state: 'NONE',
  percent: 0,
  bytesWritten: 0,
  totalBytes: 0,
  downloadFinishedAt: 0,
  createdAt: Date.now(),
};

type PartialDownloadInfo = Partial<DownloadInfo> & { id: DocumentType };

export const downloadsSlice = createSlice({
  name: 'new-downloads',
  initialState,
  reducers: {
    updateDownloadStatus: (state, action: PayloadAction<PartialDownloadInfo>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = {
          ...INIT_DOWNLOAD_DATA,
          ...action.payload,
        }
      } else {
        state[action.payload.id] = {
          ...(state[action.payload.id] as DownloadInfo),
          ...action.payload,
        };
      }
    },
    overrideDownloadStatus: (state, action: PayloadAction<Partial<DownloadReducer>>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    offlineDownloadReset: () => {
      return initialState
    }
  },
})

export const { updateDownloadStatus, overrideDownloadStatus, offlineDownloadReset } = downloadsSlice.actions

export default downloadsSlice.reducer