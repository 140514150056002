import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { IsAdobeAnalyticsAvailableResponseType } from 'types/analyticsType'
import { IS_ADOBE_ANALYTICS_AVAILABLE } from './queries/adobeAnalyticQueries'

const isAdobeAnalyticsAvailableQuery = async (): Promise<IsAdobeAnalyticsAvailableResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: IS_ADOBE_ANALYTICS_AVAILABLE(currentCountry.code, currentLanguage.languageCode),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data
  } catch (error) {
    console.error('Error fetching adobe analytics availability:', error)
    throw new Error('Failed to fetch adobe analytics availability')
  }
}

export default isAdobeAnalyticsAvailableQuery
