// NOTE: sync with eventName in the src/electron-shared folder
export enum RendererEvent {
  DOWNLOAD_FILE = 'download-file',
  STOP_ALL_DOWNLOAD = 'stop-all-download',
  PING_PONG = 'ping-pong',
  UNZIP_REQUEST = 'unzip-request',
  READ_FILE = 'read-file',
  REMOVE_FILE = 'remove-file',
  MOVE_TO_BACKUP = 'move-to-backup',
  REMOVE_FOLDER = 'remove-folder',
  IS_FILE_EXIST = 'is-file-exist',
  DOWNLOAD_SINGLE_IMAGE = 'download-single-image',
  GET_ALL_FILE_NAMES_IN_FOLDER = 'get-all-file-names-in-folder',
  SHOW_ALERT = 'show-alert',
  OPEN_PDF = 'open-pdf',
  SAVE_PDF_BLOB = 'save-pdf-blob',
  OPEN_PDF_RENDERER = 'open-pdf-renderer',
}

// same as tab-app
export enum MainEvent {
  DOWNLOAD_PROGRESS = 'downloadProgress',
  DOWNLOAD_COMPLETE = 'downloadComplete',
  DOWNLOAD_FAILED = 'downloadFailed',
  DOWNLOAD_BEGIN = 'downloadBegin',
}

