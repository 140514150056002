import { saveBusinessHeadlineDb } from 'database/queries/country'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { linkDataAnalytics } from 'config/adobeAnalytics/analytics'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { AppDataKeyEnum, SET_APP_DATA } from 'config/libs/globals'
import { RootState } from 'redux/reducers'
import { getPageContent } from 'redux/reducers/pageContentReducer'
import { selectCurrentLanguage, selectPageContent } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import useCookie from 'features/Settings/hooks/useCookie'
import Header from 'components/Header/Header'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import BusinessUnitEntryUI from './BusinessUnitEntry.ui'

const BusinessUnitEntry = (): React.ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { initCookeBanner } = useCookie()
  const { setPageInfoAnalytics } = useAnalytics()

  const currentLanguage = useSelector(selectCurrentLanguage)
  const pageData = useSelector((state: RootState) =>
    selectPageContent(state, PAGE_IDS.BUSINESS_UNIT_ENTRY),
  )

  const [isInit, setIsInit] = React.useState<boolean>(false)

  useEffect(() => {
    const requestPageContent = async () => {
      dispatch(getPageContent(PAGE_IDS.BUSINESS_UNIT_ENTRY))
    }

    const init = async () => {
      await requestPageContent()
      setPageInfoAnalytics({
        pageName: PAGE_NAMES.BUSINESS_UNIT_ENTRY,
        pageType: PAGE_TYPES.BUSINESS_UNIT_ENTRY,
      })
      setIsInit(true)
    }

    init()
  }, [])

  /** Init Cookie Banner */
  useEffect(() => {
    if (currentLanguage && isInit) {
      initCookeBanner(currentLanguage)
    }
  }, [currentLanguage, isInit])

  const onGoProductGroupOverview = (pageId: number, headline: string, division: string) => {
    linkDataAnalytics(division === 'first' ? 'home_garden_learn_more' : 'professional_learn_more')
    SET_APP_DATA(AppDataKeyEnum.businessHeadline, { headline, pageId })
    saveBusinessHeadlineDb(headline, pageId)
    navigate(`/group-overview/${pageId}`)
  }

  if (!pageData) return <></>

  const { loading, error, data } = pageData

  const renderContent = () => {
    if (loading) return <LoadingPage />

    if (error) return <ErrorPage />

    return <BusinessUnitEntryUI data={data} onNavigation={onGoProductGroupOverview} />
  }

  return (
    <Container className='business-unit-entry' fluid>
      <Header pageId={PAGE_IDS.BUSINESS_UNIT_ENTRY} />

      {renderContent()}
    </Container>
  )
}

export default BusinessUnitEntry
