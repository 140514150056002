import { getPageContentDb } from 'database/queries/pageContent'
import getPageContentQuery from 'services/pageContent'
import { PageContentResponseType } from 'types'

export const asyncFetchData = async <T, U>(
  isAppOnline: boolean,
  onlineApi: (params: T) => Promise<U | undefined>,
  offlineApi: (params: T) => Promise<U | undefined>,
  onlineParams: T,
  offlineParams: T,
): Promise<U | undefined> => {
  try {
    const data = await (isAppOnline ? onlineApi(onlineParams) : offlineApi(offlineParams))
    return data
  } catch (error) {
    console.error('fetchData error', error)
    throw error
  }
}


export const asyncGetPageContent = async (pageId: number, isAppOnline: boolean) => {
  const pageData = await asyncFetchData<number, PageContentResponseType>(
    isAppOnline,
    getPageContentQuery,
    getPageContentDb,
    pageId,
    pageId,
  )
  return pageData
}