import { OnboardingResponseType } from 'types/onboardingType'
import { API_YEAR } from 'config/constants'
import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { GET_ONBOARDING } from './queries/onboardingQueries'

const getOnboardingQuery = async (): Promise<OnboardingResponseType | undefined> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: GET_ONBOARDING(currentLanguage.languageCode, currentCountry.code, API_YEAR),
    })

    const onboarding = data?.getOnboarding

    if (!onboarding) {
      throw new Error('data is undefined')
    }
    return onboarding
  } catch (error) {
    console.warn('Error getOnboardingQuery:', error)
  }
}

export default getOnboardingQuery
