import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface TranslationsState {
  data: { [key: string]: string }
  loading: boolean
  error: boolean
}

const initialState: TranslationsState = {
  data: {},
  loading: false,
  error: false,
}

const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    getTranslations(state) {
      state.loading = true
      state.error = false
    },
    getTranslationsSuccess(state, action: PayloadAction<{ [key: string]: string }>) {
      state.data = action.payload
      state.loading = false
      state.error = false
    },
    getTranslationsError(state) {
      state.loading = false
      state.error = true
    },
    resetTranslations: () => initialState,
  },
})

export const { getTranslations, getTranslationsSuccess, getTranslationsError, resetTranslations } =
  translationsSlice.actions

export default translationsSlice.reducer
