import { RendererEvent } from 'electron-shared'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { convertBlobToBase64, showAlert } from 'config/utils/CommonFunction'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

const PDF_DIMENSION = {
  width: 595,
  height: 842,
  padding: 20,
}

export type PDFInfoType = {
  type: string
  pageId: number
}

const useJSPDF = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showModal, hideModal } = useGlobalModalContext()

  const printDocuments = async (elementIds: string[], callback?: (i: number) => void) => {
    setLoading(true)
    try {
      const pdf = new jsPDF('portrait', 'px', [PDF_DIMENSION.width, PDF_DIMENSION.height])
      const image = { type: 'jpeg' }
      const innerPageWidth = PDF_DIMENSION.width - PDF_DIMENSION.padding * 2
      const innerPageHeight = PDF_DIMENSION.height - PDF_DIMENSION.padding * 2
      for (let i = 0; i < elementIds.length; i++) {
        const elementId = elementIds[i]
        const input = document.getElementById(elementId)
        if (!input) continue

        const images = input.getElementsByTagName('img')
        const imagePromises = Array.from(images).map(async (img) => {
          try {
            if (!window.electron?.isDesktop()) {
              const base64 = await toBase64(img.src)
              img.src = base64
            }
          } catch (error) {
            console.error(error)
          }
        })
        await Promise.all(imagePromises)

        const canvas = await html2canvas(input, { useCORS: true, allowTaint: true })
        const pxFullHeight = canvas.height
        const pxPageHeight = Math.floor(canvas.width * (PDF_DIMENSION.height / PDF_DIMENSION.width))
        const nPages = Math.ceil(pxFullHeight / pxPageHeight)
        const pageHeight = innerPageHeight

        const pageCanvas = document.createElement('canvas')
        const pageCtx = pageCanvas.getContext('2d')
        pageCanvas.width = canvas.width
        pageCanvas.height = pxPageHeight

        for (let page = 0; page < nPages; page++) {
          const w = pageCanvas.width
          const h = pageCanvas.height
          if (pageCtx) {
            pageCtx.fillStyle = 'white'
            pageCtx.fillRect(0, 0, w, h)
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h)
          }
          if (page > 0) pdf.addPage()
          const imgData = pageCanvas.toDataURL('image/' + image.type)
          pdf.addImage(
            imgData,
            image.type,
            PDF_DIMENSION.padding,
            PDF_DIMENSION.padding,
            innerPageWidth,
            pageHeight,
          )
        }
        if (i < elementIds.length - 1) {
          pdf.addPage()
        }
        if (callback) {
          callback(i)
        }
      }

      const pdfData = pdf.output('blob')
      if (window.electron?.isDesktop()) {
        try {
          // Convert the Blob to a base64 string
          const base64String = await convertBlobToBase64(pdfData)
          const formattedBase64 = base64String.split(',')[1]
          const fileLocation = await window.electron?.asyncInvoke(
            RendererEvent.SAVE_PDF_BLOB,
            formattedBase64,
            { fileName: `kaecher-export-${moment().format('MMM-DD-YY_hh-mm-ss')}.pdf` },
          )
          if (fileLocation) {
            window.electron?.invoke(RendererEvent.OPEN_PDF, fileLocation)
          }
        } catch (error) {
          console.error(error)
        } finally {
          window.close()
        }
      } else {
        const blobURL = URL.createObjectURL(pdfData)
        window.document.write(
          `<html>
            <body>
              <embed src="${blobURL}" width="100%" height="100%" type="application/pdf">
              <br>
            </body>
          </html>`,
        )
      }
    } catch (error) {
      console.info(error)
    }
    setLoading(false)
  }

  const openNewPDFWindow = (pdfData: PDFInfoType[]) => {
    const pdfDataString = encodeURIComponent(JSON.stringify(pdfData))
    if (window.electron?.isDesktop()) {
      const pdfURL = `PDF?data=${pdfDataString}`
      // Open the PDF in a new window
      window.electron.invoke(RendererEvent.OPEN_PDF_RENDERER, pdfURL)
      toast('Loading PDF...', { autoClose: 5000 })
    } else {
      const pdfURL = `${window.location.origin}/PDF?data=${pdfDataString}`
      const newWin = window.open(pdfURL, 'newWin', 'width=1280,height=1000')
      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        showAlert('Please allow popups for this website')
      }
    }
  }

  return { loading, printDocuments, openNewPDFWindow }
}

const toBase64 = async (url: string) => {
  const response = await fetch(`${url}?time=${new Date().getTime()}`)
  const blob = await response.blob()
  if (!blob) {
    throw new Error('Failed to fetch image')
  }

  const base64Data = await convertBlobToBase64(blob)
  return base64Data
}

export default useJSPDF
