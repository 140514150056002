import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { ServicePricesFormattedType } from 'types'

type ServicePricesProps = {
  servicePrices: ServicePricesFormattedType
}

function ServicePrices({ servicePrices }: ServicePricesProps) {
  const translations = useSelector(selectTranslations)

  const { full, maintain, inspect } = servicePrices

  const servicesTitle = translations.services ?? 'Services'
  const maintainTitle = translations.service_offer_2 ?? 'Maintain'
  const inspectTitle = translations.service_offer_1 ?? 'Inspect'
  const fullServiceTitle = translations.service_offer_3 ?? 'Full Service'

  const renderServices = () => {
    if (
      (servicePrices.full !== null && servicePrices.full !== '') ||
      (servicePrices.maintain !== null && servicePrices.maintain !== '') ||
      (servicePrices.inspect !== null && servicePrices.inspect !== '')
    ) {
      return (
        <Row className='services-row mt-5'>
          <Col className='ak-bold'>
            <i className='karcher-icon pr-3'>&#xe913;</i>
            {servicesTitle}:
          </Col>
          {inspect && (
            <Col key='services-inspect'>
              <span>
                {inspectTitle}: {inspect}
              </span>
            </Col>
          )}
          {maintain && (
            <Col key='services-maintain'>
              <span>
                {maintainTitle}: {maintain}
              </span>
            </Col>
          )}
          {full && (
            <Col key='services-full'>
              <span>
                {fullServiceTitle}: {full}
              </span>
            </Col>
          )}
        </Row>
      )
    }
    return null
  }

  return <span>{renderServices()}</span>
}

export default ServicePrices
