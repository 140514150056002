import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import HorizontalRule from 'components/HorizontalRule'

type CompatibleProductsProps = {
  compatibleProducts: any
}

const CompatibleMachines = ({ compatibleProducts }: CompatibleProductsProps) => {
  const { onDynamicNavigate } = useNavigation()
  const translations = useSelector(selectTranslations)

  const headline = translations?.compatible_machines ?? 'COMPATIBLE MACHINES'
  const actualMachinesTitle = translations?.actual_machines ?? 'Actual Machines'
  const oldMachinesTitle = translations?.old_machines ?? 'Old Machines'

  if (!compatibleProducts) return null

  const onGoNextPage = (pageId: number) => {
    onDynamicNavigate(pageId)
  }

  const renderProducts = (products: any, actualTitle: string, oldTitle: string) => (
    <>
      <Col md='12' className='compatible_machine_col pl-0 pr-0'>
        <h6 className='headline'>{products.current.length ? actualTitle : <span />}</h6>
        <Row>
          {products.current.map((product: any) => (
            <Col key={`${product.id}curr`} md='3' className='pl-0 pr-0'>
              <p
                role='button'
                tabIndex={0}
                className='current_product'
                onClick={() => onGoNextPage(product.id)}
              >
                <i className='karcher-icon arrow-next'>
                  <span dangerouslySetInnerHTML={{ __html: '&#xe804;' }} />
                </i>
                {` ${product.name}`}
              </p>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        md='12'
        className='compatible_machine_col pl-0 pr-0'
        style={{ marginTop: '10px', color: '#757575' }}
      >
        <h6 className='headline'>{products.past.length ? oldTitle : <span />}</h6>
        <Row>
          {products.past.map((product: any) => (
            <Col key={product.id} md='3' className='pl-0 pr-0'>
              <p
                role='button'
                tabIndex={0}
                className='past_product'
                onClick={() => onGoNextPage(product.id)}
              >
                <i className='demo-icon icon-a_069_feedback_filled'>&#xe804;</i>
                {` ${product.name}`}
              </p>
            </Col>
          ))}
        </Row>
      </Col>
    </>
  )

  return (
    <Container
      className='compatible-machines-wrapper mt-4'
      id={PRODUCT_DETAILS_SECTIONS.COMPATIBLE_MACHINES}
    >
      <h6 className='ak-bold'>{headline}</h6>

      <HorizontalRule />

      <Row>{renderProducts(compatibleProducts, actualMachinesTitle, oldMachinesTitle)}</Row>
    </Container>
  )
}

export default CompatibleMachines
