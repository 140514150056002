import { ImageHeadlineFourColumnsType } from 'types'
import { getCard } from '../pdfLayouts'

export const getImageHeadlineTextFourCol = (headlines: ImageHeadlineFourColumnsType[]) => {
  const firstImage = headlines.map((item) => item.image_first)
  const secondImage = headlines.map((item) => item.image_second)
  const thirdImage = headlines.map((item) => item.image_third)
  const fourthImage = headlines.map((item) => item.image_fourth)

  let str = ''

  headlines.forEach((item, index) => {
    str += `${getCard(firstImage[index], item.headline_first, item.text_first)}`
    str += `${getCard(secondImage[index], item.headline_second, item.text_second)}`
    str += `${getCard(thirdImage[index], item.headline_third, item.text_third)}`
    str += `${getCard(fourthImage[index], item.headline_fourth, item.text_fourth)}`
  })

  str = `
    <div class="card-row-wrap">
      <div class="card-wrap">
        ${str}
      </div>
    </div>
  `
  return str
}
