import { RootState } from 'redux/reducers'
import { CountryItemType, CurrentCountryType, CurrentLanguageType } from 'types'

export const selectCountries = (state: RootState): CountryItemType[] => state.countries.data
export const selectCountriesLoading = (state: RootState): boolean => state.countries.loading
export const selectCountriesError = (state: RootState): boolean => state.countries.error
export const selectCurrentCountry = (state: RootState): CurrentCountryType | null =>
  state.countries.currentCountry
export const selectCurrentLanguage = (state: RootState): CurrentLanguageType | null =>
  state.countries.currentLanguage
