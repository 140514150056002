import { Col, Container, Row } from 'react-bootstrap'
import { HeadlineTextType } from 'types'
import { extractAnchorsFromText } from 'config/libs/helpers'

type HeadLineTextProps = {
  layoutData: HeadlineTextType
}

const HeadLineText = ({ layoutData }: HeadLineTextProps) => {
  const { headline, text } = layoutData

  const textWithAnchors = extractAnchorsFromText(text ?? '')

  return (
    <Container className='headline-text-wrapper pt-4'>
      <Row>
        <Col md='9'>
          {headline && (
            <h1
              dangerouslySetInnerHTML={{
                __html: headline,
              }}
            />
          )}
          <p
            dangerouslySetInnerHTML={{
              __html: textWithAnchors || '',
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default HeadLineText
