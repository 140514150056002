import { useRef, useState } from 'react'
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FavoriteContentType, FavoriteFolderType, PageContentResponseType } from 'types'
import { MY_FAVORITES_FOLDER, TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import { selectFavoritesContent } from 'redux/selector/favoritesSelector'
import { useFavorites } from 'features/Favorites/hooks/useFavorites'
import FavFolderTree, { FolderTreeRef } from 'features/Favorites/screens/FavFolderTree'
import 'styles/modal/confirmModal.scss'

export type FavoritesModalProps = {
  showModal: boolean
  itemName: string
  pageContent?: PageContentResponseType
  favoritesContent?: FavoriteContentType
  handleClose: () => void
  popupTitle?: string
}

const FavoritesModal = (props: FavoritesModalProps): React.ReactElement => {
  const { handleClose, showModal, itemName, pageContent, favoritesContent, popupTitle } = props

  const translations = useSelector(selectTranslations)
  const favoriteContent = useSelector(selectFavoritesContent)

  const { addItemToFavorites, editItemInFavorites } = useFavorites()

  const [title, setTitle] = useState<string>(itemName ?? '')
  const [isCanCreateFolder, setIsCanCreateFolder] = useState<boolean>(false)
  const [activeFolder, setActiveFolder] = useState<FavoriteFolderType>()

  const favFolderTreeRef = useRef<FolderTreeRef>(null)

  const favoritesTitle =
    popupTitle || (translations.add_to_favorites ?? TRANSLATIONS.addToFavorites)
  const myFavoritesFolderName = translations?.my_favorites ?? MY_FAVORITES_FOLDER.folderName
  const cancelText = translations.cancel ?? 'Cancel'
  const saveText = translations.save ?? 'Save'
  const newFolderText = translations.new_folder ?? 'New Folder'
  const createNewFolderText = translations.create_new_folder ?? 'Create New Folder'

  const handleCancel = () => {
    handleClose()
  }

  /**
   * Save item to favorites for the new one or edit item in favorites
   */
  const handleSave = () => {
    if (pageContent) {
      const favoriteExtend = {
        position: favoriteContent?.length + 1,
        title,
        folderID: activeFolder?.id ?? MY_FAVORITES_FOLDER.id,
        folderName: activeFolder?.folderName ?? myFavoritesFolderName,
      }

      /** Add item to favorite local DB and Redux */
      addItemToFavorites(pageContent, favoriteExtend)
    } else if (favoritesContent) {
      const favoritesContentUpdated = {
        ...favoritesContent,
        title,
        folderID: activeFolder?.id ?? MY_FAVORITES_FOLDER.id,
        folderName: activeFolder?.folderName ?? myFavoritesFolderName,
      }
      editItemInFavorites(favoritesContent.id, favoritesContentUpdated)
    }
    handleClose()
  }

  const handleCanCreateNewFolder = () => {
    favFolderTreeRef.current?.onCanCreateNewFolder()
    setIsCanCreateFolder(!isCanCreateFolder)
  }

  const handleCreateNewFolder = () => {
    favFolderTreeRef.current?.onCreateNewFolder()
    setIsCanCreateFolder(false)
  }

  const handleActiveFolderData = (activeFolderData: FavoriteFolderType) => {
    if (!activeFolderData) return

    setActiveFolder(activeFolderData)
  }

  const renderInputTitle = () => {
    return (
      <InputGroup>
        <FormControl
          onChange={(event) => setTitle(event.target.value)}
          defaultValue={title}
          style={{ borderRadius: 'unset', borderColor: '#ced4da', boxShadow: 'none' }}
        />
      </InputGroup>
    )
  }

  return (
    <Modal show={showModal} onHide={handleClose} size='sm' className='confirm-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='confirm-modal-heading'>{favoritesTitle.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderInputTitle()}

        <FavFolderTree
          ref={favFolderTreeRef}
          onActiveFolderData={handleActiveFolderData}
          isModal
          onExportFolder={() => {}}
          onExportFolderShort={() => {}}
        />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <div>
          {isCanCreateFolder ? (
            <>
              <Button variant='primary' className='light-button' onClick={handleCanCreateNewFolder}>
                {cancelText.toUpperCase()}
              </Button>
              <Button
                variant='secondary'
                className='app-button mx-3'
                onClick={handleCreateNewFolder}
              >
                {createNewFolderText.toUpperCase()}
              </Button>
            </>
          ) : (
            <Button variant='secondary' className='app-button' onClick={handleCanCreateNewFolder}>
              {newFolderText.toUpperCase()}
            </Button>
          )}
        </div>

        <div>
          <Button variant='secondary' className='light-button mx-3' onClick={handleCancel}>
            {cancelText.toUpperCase()}
          </Button>
          <Button variant='primary' className='app-button' onClick={handleSave}>
            {saveText.toUpperCase()}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default FavoritesModal
