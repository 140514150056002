import DCAImage from 'components/DCAImage'
import HorizontalRule from 'components/HorizontalRule'
import React, { useMemo } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { FeatureBenefitsItemType } from 'types'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'

type Props = {
  featureBenefits: FeatureBenefitsItemType[]
}

function FeaturesAndBenefit(props: Props): React.ReactElement {
  const { featureBenefits } = props
  const translations = useSelector(selectTranslations)
  const headline = translations.feature_and_benefits ?? 'Features and Benefits'

  const getImageUrls = (item: FeatureBenefitsItemType): string[] => {
    const imgUrlArr: string[] = []
    if (item.images && Array.isArray(item.images)) {
      item.images.forEach((image: { type: string; url: string }) => {
        if (image.type === 'full' && image.url) {
          const imageName = image.url.split('/').pop()
          if (imageName && imageName !== 'placeholder.jpg' && imageName !== 'placeholder.png') {
            imgUrlArr.push(image.url)
          }
        }
      })
    }
    return imgUrlArr
  }

  const imagesArr = useMemo(() => {
    const urls: string[] = []
    featureBenefits.slice(0, 3).forEach((item) => {
      const imgUrls = getImageUrls(item)
      if (imgUrls.length > 0) {
        urls.push(imgUrls[0])
      } else {
        urls.push('')
      }
    })
    return urls
  }, [featureBenefits])

  let listDataArr: FeatureBenefitsItemType[] = []
  let imageDataArr: FeatureBenefitsItemType[] = []

  if (featureBenefits.length > 3) {
    listDataArr = featureBenefits.slice(3)
  }

  if (featureBenefits.length >= 3 && imagesArr.length === 3) {
    imageDataArr = featureBenefits.slice(0, 3)
  } else {
    listDataArr = featureBenefits.slice(0)
  }

  const renderDataWithImages = () => {
    return (
      <Row>
        {imageDataArr.map((data, index) => {
          const listArr = data.benefits.map((item) => (
            <span key={item}>
              <span>{item} </span>
            </span>
          ))
          return (
            <Col md='4' key={`${Math.random().toString(36)}_features`}>
              <Card className='feature-card'>
                {imagesArr[index] !== '' ? (
                  <DCAImage
                    showEnlargedImage
                    type='cardImage'
                    variant='top'
                    src={imagesArr[index]}
                  />
                ) : null}
                <Card.Title className='card-title mt-4'>{data.feature}</Card.Title>
                <Card.Text className='card-text'>{listArr}</Card.Text>
              </Card>
            </Col>
          )
        })}
      </Row>
    )
  }

  const renderGroupData = () => {
    return (
      <Row>
        {listDataArr.map((data) => {
          const benefitsText = data.benefits.map((benefit) => (
            <li key={benefit}>
              <span>{benefit} </span>
            </li>
          ))
          return (
            <Col md='6' key={`${Math.random().toString(36)}_features`}>
              <Card className='feature-div p-3'>
                <Card.Title className='card-title'>{data.feature}</Card.Title>
                <Card.Text className='card-text'>{benefitsText}</Card.Text>
              </Card>
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <Container
      className='feature-benefit-wrapper pt-4'
      id={PRODUCT_DETAILS_SECTIONS.FEATURE_BENEFITS}
    >
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>

      <HorizontalRule />

      {renderDataWithImages()}

      {renderGroupData()}
    </Container>
  )
}

export default FeaturesAndBenefit
