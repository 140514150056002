const universalSchema = {
  dcaSettings: 'name, export, language, createdAt, syncAt, country, year',
  dcaFavFolder: 'id, parentID, folderName',
  dcaFavContent: '++id, folderID, pageId, title, type, country, language, year, position',
  dcaProductCompare: '++id,productID',
  dcaMarking:
    '++id, key, language, createdAt, country, text, startOffset, endOffset, comment, highlightColor',
  dcaOnboarding: 'id, isFirstTime, data',
  dcaDownload: 'id, state, percent, bytesWritten, totalBytes, downloadFinishedAt',
}

const homeAndGardenSchema = {
  dcaProductDetails:
    '++id,pageId,productId,partnumber,productName,productType,businessUnit,export,year,country,language,createdAt,data,syncAt',
}

const professionalSchema = {
  dcaProductDetails:
    '++id,pageId,productId,partnumber,productName,productType,businessUnit,export,year,country,language,createdAt,data,syncAt',
}

const commonSchema = {
  dcaPages: '++id,pageId,content,export,year,country,language,createdAt,style,syncAt',
  dcaProductList:
    '++id,productgroupId,export,year,country,language,createdAt,productGroupData,data,syncAt',
  dcaSubProductGroup: '++id,parentId,data,year,country,language,export,createdAt',
  dcaProductGroup:
    '++id,productgroupId,export,year,country,language,createdAt,isDropdownAvailable,dropdown,data,syncAt',
  dcaStructure: '++id,export,year,country,language,createdAt,data,syncAt',
  dcaTranslations: '++id,export,year,country,language,createdAt,data,syncAt',
  dcaSearchManualContent:
    '++id,pageId,content,export,year,country,language,createdAt,style,headline,text',
}

export { universalSchema, homeAndGardenSchema, professionalSchema, commonSchema }
