import { getCurrentCountryDb } from 'database/queries/country'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import BusinessUnitEntry from 'features/Common/screens/BusinessUnitEntry/BusinessUnitEntry'
import LanguageSelection from 'features/Common/screens/LanguageSelection/LanguageSelection'
import ComparisonScreen from 'features/Comparison/screens/Comparison'
import FavoritesScreen from 'features/Favorites/screens/Favorites'
import ManualContent from 'features/ManualContent/screens/ManualContent'
import PDF from 'features/PDF/PDF'
import ProductDetail from 'features/Product/screens/ProductDetail/ProductDetail'
import ProductGroupList from 'features/Product/screens/ProductGroupList/ProductGroupList'
import ProductGroupOverview from 'features/Product/screens/ProductGroupOverview/ProductGroupOverview'
import ProductList from 'features/Product/screens/ProductList/ProductList'
import ProductFinder from 'features/ProductFinder/ProductFinder'
import SearchScreen from 'features/Search/screens/Search'
import CookiePolicy from 'features/Settings/screens/CookiePolicy'
import Imprint from 'features/Settings/screens/Imprint'
import PrivacyPolicy from 'features/Settings/screens/PrivacyPolicy'
import Settings from 'features/Settings/screens/Settings'

export const AppRoutes = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const init = async () => {
      const currentCountry = await getCurrentCountryDb()
      if (!currentCountry) {
        navigate('/country-selection')
      }
      setIsLoading(false)
    }
    init()
  }, [])

  if (isLoading) return null

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/business-entry' />} />
      <Route path='/' element={<Navigate to='/business-entry' replace />} />
      <Route path='/country-selection' element={<LanguageSelection />} />
      <Route path='/business-entry' element={<BusinessUnitEntry />} />
      <Route path='/manual-content/:pageId' element={<ManualContent />} />
      <Route path='/group-overview/:pageId' element={<ProductGroupOverview />} />
      <Route path='/group-list/:pageId' element={<ProductGroupList />} />
      <Route path='/product-list/:pageId' element={<ProductList />} />
      <Route path='/product/:pageId' element={<ProductDetail />} />
      <Route path='/productFinder/:productGroupCode?' element={<ProductFinder />} />
      <Route path='/settings' element={<Settings />} />
      <Route path='/cookie-policy' element={<CookiePolicy />} />
      <Route path='/search/:searchKey' element={<SearchScreen />} />
      <Route path='/comparison' element={<ComparisonScreen />} />
      <Route path='/favorites' element={<FavoritesScreen />} />
      <Route path='/pdf' element={<PDF />} />
      <Route path='/imprint' element={<Imprint />} />
      <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
    </Routes>
  )
}
