import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FavoriteContentType, FavoriteFolderType } from 'types'
import { MY_FAVORITES_FOLDER } from 'config/constants'

export interface FavoritesState {
  selectedFolderId: number
  favoritesContent: FavoriteContentType[]
  favoritesFolder: FavoriteFolderType[]
}

const initialState: FavoritesState = {
  selectedFolderId: MY_FAVORITES_FOLDER.id,
  favoritesContent: [],
  favoritesFolder: [],
}

const favoritesSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    loadFavorites(state, action: PayloadAction<FavoritesState>) {
      state.favoritesContent = action.payload.favoritesContent
      state.favoritesFolder = action.payload.favoritesFolder
    },
    updateFavorites(state, action: PayloadAction<FavoriteContentType[]>) {
      state.favoritesContent = action.payload
    },
    addItemToFavoritesContent(state, action: PayloadAction<FavoriteContentType>) {
      state.favoritesContent.push(action.payload)
    },
    addItemToFavoritesFolder(state, action: PayloadAction<FavoriteFolderType>) {
      state.favoritesFolder.push(action.payload)
    },
    setSelectedFolderId(state, action: PayloadAction<number>) {
      state.selectedFolderId = action.payload
    },
    resetFavorites: () => initialState,
  },
})

export const {
  loadFavorites,
  updateFavorites,
  addItemToFavoritesContent,
  addItemToFavoritesFolder,
  setSelectedFolderId,
  resetFavorites,
} = favoritesSlice.actions

export default favoritesSlice.reducer
