import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { faBan, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  MarkClassName,
  MarkingEventType,
  MarkingTooltipData,
  changeColorMark,
  deleteMark,
} from '../markingFunctions'
import 'styles/components/markingTooltip.scss'
import MarkingComment, { MarkingCommentRef } from './MarkingComment'

const MarkingTooltip = () => {
  const markingCommentRef = useRef<MarkingCommentRef>(null)

  const [activeMarkId, setActiveMarkId] = useState<string>()

  useEffect(() => {
    const handleMarkMouseover = (event: CustomEvent<HTMLElement>) => {
      const markElement = event.detail
      const markId = markElement.id

      if (!markElement || !markId) return

      if (activeMarkId !== markId) {
        closeComment()
        setActiveMarkId(markElement.id)
      }
    }

    document.addEventListener(MarkingEventType.MARK_MOUSEOVER, handleMarkMouseover as EventListener)

    return () => {
      document.removeEventListener(
        MarkingEventType.MARK_MOUSEOVER,
        handleMarkMouseover as EventListener,
      )
    }
  }, [activeMarkId])

  const closeComment = () => {
    markingCommentRef.current?.closeComment()
  }

  const openComment = () => {
    markingCommentRef.current?.openComment()
  }

  return (
    <React.Fragment>
      <Tooltip
        id={MarkingTooltipData.ID}
        clickable
        className='marking-tooltip-container'
        style={{ backgroundColor: '#e3e3e3' }}
      >
        <div className='marking-icon' onClick={() => deleteMark(activeMarkId)}>
          <FontAwesomeIcon size='2x' icon={faBan} />
        </div>

        <button
          className='marking-button yellow-mark'
          onClick={() => changeColorMark(activeMarkId, MarkClassName.YELLOW_MARK)}
        />

        <button
          className='marking-button red-mark'
          onClick={() => changeColorMark(activeMarkId, MarkClassName.RED_MARK)}
        />

        <button
          className='marking-button green-mark'
          onClick={() => changeColorMark(activeMarkId, MarkClassName.GREEN_MARK)}
        />

        <div className='marking-separator' />

        <div className='marking-icon' onClick={openComment}>
          <FontAwesomeIcon size='2x' className='comment-dca' icon={faComment} />
        </div>
      </Tooltip>
      <MarkingComment ref={markingCommentRef} activeMarkId={activeMarkId} />
    </React.Fragment>
  )
}

export default MarkingTooltip
