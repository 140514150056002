import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppOnlineState {
  isOnline: boolean
}

const initialState: AppOnlineState = {
  isOnline: false,
}

const appOnlineSlice = createSlice({
  name: 'appOnline',
  initialState,
  reducers: {
    setOnlineStatus(state, action: PayloadAction<boolean>) {
      state.isOnline = action.payload
    },
  },
})

export const { setOnlineStatus } = appOnlineSlice.actions

export default appOnlineSlice.reducer
