import { RootState } from 'redux/reducers'

export const selectHasProductFinder = (state: RootState): boolean =>
  state.productFinder.hasProductFinder
export const selectHasProductFinderLoading = (state: RootState): boolean =>
  state.productFinder.availabilityFinderLoading
export const selectHasProductFinderError = (state: RootState): boolean =>
  state.productFinder.availabilityFinderError
export const selectProductGroupHasProductFinder = (
  state: RootState,
  productGroupId: number,
): boolean => state.productFinder.productGroupHasProductFinder[productGroupId]

export const selectProductFinderLoading = (state: RootState) => state.productFinder.productGroupFinderLoading