import { API_YEAR } from 'config/constants'
import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage, toLocalImgName } from 'config/utils/CommonFunction'
import { ASSET_KEYS, DocumentType } from 'redux/download/download.reducer'
import {
  getDownloadExportResponseType
} from 'types/export.Type'
import { GET_DOWNLOAD_EXPORT, GET_EXPORT_URL, GET_IMAGE_ASSET_PRESIGNED_URLS, GET_IMAGE_CHANGE_LOG_PRESIGNED_URL } from './queries/exportQueries'

const getExportUrlQuery = async (pageId: number): Promise<string> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()
    const { data } = await getGraphQLClient().query({
      query: GET_EXPORT_URL(pageId, currentLanguage.languageCode, currentCountry.code, API_YEAR),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getExportUrl
  } catch (error) {
    console.error('Error getExportUrlQuery:', error)
    throw new Error('Failed to getExportUrlQuery')
  }
}
const getDownloadContent = async ({
  homeAndGarden,
  professional,
}: {
  homeAndGarden?: boolean
  professional?: boolean
}): Promise<getDownloadExportResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const categories: [keyof getDownloadExportResponseType] = ['AK_DCA_COMMON']
    if (homeAndGarden) {
      categories.push('AK_DCA_HOME_AND_GARDEN')
    }
    if (professional) {
      categories.push('AK_DCA_PROFESSIONAL')
    }
    const { data } = await getGraphQLClient().query({
      query: GET_DOWNLOAD_EXPORT(
        currentLanguage.languageCode,
        currentCountry.code,
        API_YEAR,
        categories,
      ),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getDownloadExport
  } catch (error) {
    console.error('Error getDownloadContentAll:', error)
    throw new Error('Failed to getDownloadContentAll')
  }
}


export const getImageAssetPresignedUrls = async (
  type: (typeof ASSET_KEYS)[number]
): Promise<string[]> => {
  const currentCountry = await getCurrentCountry()
  const currentLanguage = await getCurrentLanguage()


  const assetType = (() => {
    switch (type) {
      case DocumentType.PRO_ASSET:
        return 'PROFESSIONAL';
      case DocumentType.HOME_AND_GARDEN_ASSET:
        return 'HOME_AND_GARDEN';
      default:
        return 'COMMON';
    }
  })();
  const { data } = await getGraphQLClient().query({
    query: GET_IMAGE_ASSET_PRESIGNED_URLS(
      assetType,
      currentLanguage.languageCode,
      currentCountry.code
    ),
  });
  return data.getImageAssetPresignedUrls;
};

export const getAssetChangeLogPresignedUrl = async (
  type: 'COMMON' | 'HOME_AND_GARDEN' | 'PROFESSIONAL'
): Promise<string> => {
  const currentCountry = await getCurrentCountry()
  const currentLanguage = await getCurrentLanguage()

  const { data } = await getGraphQLClient().query({
    query: GET_IMAGE_CHANGE_LOG_PRESIGNED_URL(
      type,
      currentLanguage.languageCode,
      currentCountry.code,
      API_YEAR
    ),
  });

  return data?.getImageChangeLogPresignedUrl;
};

const fetchAllImageLinks = async (type: (typeof ASSET_KEYS)[number]) => {
  try {
    // 'COMMON' | 'HOME_AND_GARDEN' | 'PROFESSIONAL'
    const assetType = (() => {
      switch (type) {
        case DocumentType.HOME_AND_GARDEN_ASSET:
          return 'HOME_AND_GARDEN';
        case DocumentType.PRO_ASSET:
          return 'PROFESSIONAL';
        default:
          return 'COMMON';
      }
    })();
    const presignedUrl = await getAssetChangeLogPresignedUrl(assetType);
    const response = await fetch(presignedUrl);

    const responseJson: {
      hostName: string;
      pathNames: string[];
    }[] = await response.json();

    const images: { url: string; fileName: string }[] = [];

    responseJson.forEach((item) => {
      images.push(
        ...item.pathNames.map((path) => ({
          url: item.hostName + path,
          fileName: toLocalImgName(item.hostName + path),
        }))
      );
    });

    return images;
  } catch (error) {
    return [];
  }
};

export { fetchAllImageLinks, getDownloadContent, getExportUrlQuery }

