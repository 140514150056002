import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CountryItemType, CurrentCountryType, CurrentLanguageType } from 'types'

export type CountriesState = {
  loading: boolean
  error: boolean
  data: CountryItemType[]
  currentCountry: CurrentCountryType | null
  currentLanguage: CurrentLanguageType | null
}

const initialState: CountriesState = {
  loading: false,
  error: false,
  data: [],
  currentCountry: null,
  currentLanguage: null,
}

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    getCountries: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    getCountriesSuccess: (state, action: PayloadAction<CountryItemType[]>) => {
      state.loading = false
      state.error = false
      state.data = action.payload
    },
    getCountriesError: (state) => {
      state.loading = false
      state.error = true
      state.data = []
    },
    getCountriesLoading: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    setCurrentCountry: (state, action: PayloadAction<CurrentCountryType>) => {
      state.currentCountry = action.payload
    },
    setCurrentLanguage: (state, action: PayloadAction<CurrentLanguageType>) => {
      state.currentLanguage = action.payload
    },
    resetCountries: () => initialState,
  },
})

export const {
  setCurrentCountry,
  setCurrentLanguage,
  getCountries,
  getCountriesSuccess,
  getCountriesError,
  getCountriesLoading,
  resetCountries,
} = countriesSlice.actions

export default countriesSlice.reducer
