import { ContentRibbonType, ContentType, StructureItemType } from 'types'
import { LAYOUTS } from 'config/constants'
import { getContentRibbon } from 'config/utils/CommonFunction'
import { getBreadcrumbData, printDocument } from '../pdfHelper'
import { getCustomHTML, horizontalRule } from '../pdfLayouts'
import { pageBreak } from '../styles'
import { getFullWidthImages } from './getFullWidthImages'
import { getHeadlineText } from './getHeadlineText'
import { getImageGrid } from './getImageGrid'
import { getImageHeadlineText } from './getImageHeadlineText'
import { getImageHeadlineTextFiftyFifty } from './getImgHeadlineFiftyFifty'
import { getImageHeadlineTextFourCol } from './getImgHeadlineFourColumn'
import { getImageHeadlineTextThreeCol } from './getImgHeadlineThreeColumn'
import { getImageHeadlineTextTwoCol } from './getImgHeadlineTwoColumn'
import { getTableContent } from './getTableContent'
import { getTextContent } from './getTextContent'

type LayoutConfigType = {
  layout: string
  values: any[]
}
const getLayout = (layoutData: LayoutConfigType) => {
  switch (layoutData.layout) {
    case LAYOUTS.CONTENT_TEXT:
      return getTextContent(layoutData.values)

    case LAYOUTS.CONTENT_HEADLINE_TEXT:
      return getHeadlineText(layoutData.values)

    case LAYOUTS.IMAGE_HEADLINE_TEXT:
      return getImageHeadlineText(layoutData.values)

    case LAYOUTS.IMAGE_HEADLINE_TEXT_FIFTY_FIFTY:
      return getImageHeadlineTextFiftyFifty(layoutData.values)

    case LAYOUTS.CONTENT_IMAGE:
      return getFullWidthImages(layoutData.values)

    case LAYOUTS.IMAGE_HEADLINE_TEXT_TWO_COL:
      return getImageHeadlineTextTwoCol(layoutData.values)

    case LAYOUTS.IMAGE_HEADLINE_TEXT_THREE_COL:
      return getImageHeadlineTextThreeCol(layoutData.values)

    case LAYOUTS.IMAGE_HEADLINE_TEXT_FOUR_COL:
      return getImageHeadlineTextFourCol(layoutData.values)

    case LAYOUTS.HORIZONTAL_RULE:
      return horizontalRule()

    case LAYOUTS.IMAGE_GRID:
      return getImageGrid(layoutData.values[0])

    case LAYOUTS.CONTENT_TABLE:
      return getTableContent(layoutData.values[0])

    default:
      return ''
  }
}

export const toManualContentHTML = (data: ContentType[]) => {
  let htmlStr = ''
  for (const content of data) {
    if (typeof content.config === 'string') {
      htmlStr += getLayout(JSON.parse(content.config))
    }
  }
  return htmlStr
}

export const createManualContentPDF = (
  data: {
    manualContents: ContentType[]
    ribbonData: ContentRibbonType
    pageId: number
    structure: StructureItemType[] | null
  },
  onDone?: () => void,
) => {
  const { manualContents, ribbonData, pageId, structure } = data
  const breadCrumbData = getBreadcrumbData(pageId, structure)
  const manualContentHTML = toManualContentHTML(manualContents)
  const parseRibbonData = getContentRibbon(ribbonData.text ?? '')
  const htmlDOM = getCustomHTML({
    ribbonData: {
      headline: ribbonData.headline ?? '',
      ...parseRibbonData,
    },
    breadCrumbData,
    pageStyles: manualContentStyle,
    title: ribbonData.headline,
  })
  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += manualContentHTML
  printDocument(htmlDOM, onDone)
}

export const manualContentStyle = `
    table {
      display: table;
      border-spacing: 0px;
      border-color: white;
      border-collapse: collapse;
      word-break: break-all;
    }
    .table tr:nth-child(odd) {
      background-color: #e3e3e3;
    }
    .table td {
      padding: .5rem;
    }
    .image-small {
      width: 75px;
      height: 75px;
      padding: .5em;
    }
    .card-row-wrap {
      padding-top: 25px;
      background-color: #FFF;
    }
    .card-wrap {
      display: flex;
      flex-direction: row;
      background-color: #FFF;
      gap: 20px;
      ${pageBreak}
    }
    .card-wrap .card {
      flex: 1;
      background-color: #FFF;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: auto;
      background-color: #FFF;
    }
    .ribbon-text {
      margin-top: 20px;
      padding: 20px;
      background-color: #FFED00;
      ${pageBreak}
    }
    .full-img-wrap {
      padding-top: 25px;
      ${pageBreak}
    }
    .full-img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
    .headline-text {
      padding-top: 25px;
      ${pageBreak}
    }
    .img-headline-fifty-fifty {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
      ${pageBreak}
    }
    .img-headline-fifty-fifty .img-wrap {
      flex: 1;
    }
    .img-headline-fifty-fifty .desc-wrap {
      flex: 1;
    }
    .image-grid {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
      ${pageBreak}
    }
    .image-grid .image-big {
      flex: 7;
      position: relative;
    }
    .image-grid .image-big h1 {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #ffff;
      z-index: 1000;
    }
    .image-grid .image-small-wrap {
      flex: 3;
      display: flex;
      flex-direction: column;
    }
    .image-grid .image-small {
      flex: 1;
      width: 100%;
      margin-bottom: 6px;
      padding: 0;
      margin-left: 10px;
      position: relative;
    }
    .image-grid .image-small img {
      object-fit: cover;
      height: 100%;
    }
    .image-grid .image-small span {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-weight: 700;
      color: #fff;
      z-index: 9999;
    }
    .image-grid .image-small:last-child {
      margin-bottom: 0;
    }
    .text-content {
      margin-top: 25px;
      ${pageBreak}
    }
    .image-headline-text-wrap {
      margin-top: 25px;
      ${pageBreak}
    }
    .horizontal-rule {
      width:100%;
      background:gray;
      height: 1.5px;
      break-after: page;
    }
    .table-wrap {
      padding-top: 25px;
      ${pageBreak}
    }
    .table-wrap-table {
      break-inside: auto;
    }
    .table-wrap-tbody {
      vertical-align: top;
    }
    .table-wrap-tr {
      ${pageBreak}
    }
    .table-tr-data {
      ${pageBreak}
    }
  `
