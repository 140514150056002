import { Button, Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

const PolicyBlock = () => {
  const translations = useSelector(selectTranslations)
  const { showModal, hideModal } = useGlobalModalContext()

  const privacyStatement = translations?.privacy_statement ?? 'Privacy Statement'
  const privacyPolicyText = translations?.privacy_policy_text ?? <span />

  const onShowTogglePolicyMagnify = () => {
    showModal('MAGNIFY_MODAL', {
      showModal: true,
      heading: privacyStatement,
      innerHtml: privacyPolicyText,
      handleClose: hideModal,
    })
  }

  return (
    <>
      <Card className='policy-block setting-card'>
        <Row>
          <Col md='10'>
            <h6 className='settings-heading'>
              <i className='karcher-icon settings-icons'>&#xe918;</i>
              {privacyStatement}
            </h6>
          </Col>
          <Col md='2'>
            <Button className='button-magnify' onClick={onShowTogglePolicyMagnify}>
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </Col>
        </Row>

        <p
          className='px-3 pt-2'
          dangerouslySetInnerHTML={{
            __html: privacyPolicyText,
          }}
        />
      </Card>
    </>
  )
}

export default PolicyBlock
