import { gql } from 'apollo-boost'

export const GET_TRANSLATIONS = (languageCode: string, countryCode: string, year: number) => gql`
    {
      getTranslations(input: {
        year: ${year},
        countryCode: "${countryCode}",
        languageCode: "${languageCode}"
      }) {
        export,
        year,
        country,
        language,
        createdAt,
        data
      }
    }
  `
