import { getProductListDbById } from 'database/queries/product'
import { getProductListQuery } from 'services'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ProductGroupDataType, ProductListItemType, ProductListResponseType } from 'types'
import { PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { isObjectEmpty } from 'config/utils/CommonFunction'
import { APP_CONTENT_ID, APP_HEADER_ID, useStyleContent } from 'config/utils/header'
import { RootState } from 'redux/reducers'
import { getPageContent } from 'redux/reducers/pageContentReducer'
import { selectPageContent } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import usePreviousValue from 'hooks/usePreviousValue'
import ErrorPage from 'features/Common/screens/ErrorPage'
import LoadingPage from 'features/Common/screens/LoadingPage'
import { useProductComparison } from 'features/Comparison/hooks/useProductComparison'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import ProductListUI from './ProductList.ui'

const ProductList: React.FC = () => {
  useStyleContent()
  const dispatch = useDispatch()
  const { onDynamicNavigate } = useNavigation()
  const { onAddOrRemoveProductCompare } = useProductComparison()
  const { setPageInfoAnalytics } = useAnalytics()
  const { fetchData } = useDataFetcher()

  const { pageId } = useParams<{ pageId: string }>()
  const pageIdNumber = Number(pageId)

  const previousPageId = usePreviousValue(pageIdNumber)

  const pageData = useSelector((state: RootState) => selectPageContent(state, pageIdNumber))
  const { data, error, loading } = pageData ?? {}

  const [productList, setProductList] = useState<ProductListItemType[]>()
  const [productGroup, setProductGroup] = useState<ProductGroupDataType>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.PRODUCT_LIST,
      pageType: PAGE_TYPES.PRODUCT_LIST,
      pageId: pageIdNumber,
    })
  }, [])

  useEffect(() => {
    if (!previousPageId || previousPageId !== pageIdNumber) {
      dispatch(getPageContent(pageIdNumber))
    }
  }, [dispatch, pageIdNumber, previousPageId])

  useEffect(() => {
    const handleProductListData = async () => {
      try {
        if (!data) {
          return
        }

        const productListResponse = await fetchData<number, ProductListResponseType>(
          getProductListQuery,
          getProductListDbById,
          pageIdNumber,
          pageIdNumber,
        )

        if (productListResponse) {
          setProductList(productListResponse?.data ?? [])

          const productGroupData: ProductGroupDataType = JSON.parse(
            productListResponse.productGroupData ?? '',
          )
          productGroupData && setProductGroup(productGroupData)
        }
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (loading === false && !isObjectEmpty(data)) handleProductListData()
  }, [data, pageIdNumber])

  // Error handling
  useEffect(() => {
    if (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }, [error])

  const onGoNextPage = (pageId: number) => {
    onDynamicNavigate(pageId)
  }

  const onAddProductToComparison = (
    event: React.MouseEvent<HTMLDivElement>,
    productData: ProductListItemType,
  ) => {
    event.stopPropagation()
    onAddOrRemoveProductCompare(productData)
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }

    if (isError) {
      return <ErrorPage />
    }

    if (data && productList) {
      return (
        <ProductListUI
          data={data}
          productList={productList}
          productGroup={productGroup}
          onGoNextPage={onGoNextPage}
          onAddProductToComparison={onAddProductToComparison}
        />
      )
    }

    return null
  }

  return (
    <Container className='product-list-container px-0' fluid>
      <div id={APP_HEADER_ID}>
        <Header pageId={pageIdNumber} />
        <Breadcrumb pageId={pageIdNumber} />
      </div>
      <div id={APP_CONTENT_ID}>{renderContent()}</div>
    </Container>
  )
}

export default ProductList
