import { EquipmentItemType } from 'types'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getEquipment = (equipment: EquipmentItemType[], translations: TranslationsPDFType) => {
  if (!equipment || !equipment.length) return ''

  let leftStr = ''
  let rightStr = ''

  equipment.forEach((item: EquipmentItemType, index: number) => {
    const valueDataFlag = item.value === '1'
    if (index % 2 === 0) {
      leftStr += `<tr><td style="height:auto;">■ ${item.label} ${valueDataFlag ? '' : `, ${item.value}`}</td></tr>`
    } else {
      rightStr += `<tr ><td style="height:auto;">■ ${item.label} ${valueDataFlag ? '' : `, ${item.value}`}</td></tr>`
    }
  })

  return `<div class="equipment-sec">
    <h4 style="">${tr(translations, 'equipment', 'EQUIPMENT').toLocaleUpperCase()}</h4>
    <div class="line"></div>
    <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px">
      <div style="width: 100%">
        <table class="table" style="width: 100%; font-size: 10px;">
          <tbody>
            ${leftStr}
          </tbody>
        </table>
      </div>
      
      <div style="width: 100%">
        <table class="table" style="width: 100%; font-size: 10px;">
          <tbody> 
            ${rightStr}
          </tbody>
        </table>
      </div>

    </div>
  </div>`
}

export default getEquipment
