import { universal } from 'database/db'
import { ProductCompareType } from 'types'

export async function saveProductToCompareDb(productCompare: ProductCompareType[]) {
  try {
    await universal.dcaProductCompare.put({ id: 1, products: productCompare })

    return { save: true }
  } catch (error) {
    console.error('saveProductToCompareDb err', error)
    throw error
  }
}

export const getProductCompareDb = async (): Promise<ProductCompareType[]> => {
  try {
    const comparisonData = await universal.dcaProductCompare.get(1)
    if (comparisonData) {
      return comparisonData.products
    }
    return []
  } catch (error) {
    console.error('getProductCompareDb error:', error)
    throw error
  }
}
