import { common } from 'database/db'
import getStructureQuery from 'services/structure'
import { StructureDataResponseType } from 'types/structureType'
import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  getStructure,
  getStructureError,
  getStructureSuccess,
} from 'redux/reducers/structureReducer'
import { selectAppOnline } from 'redux/selector'

function* fetchStructureOnline() {
  try {
    const response: StructureDataResponseType = yield call(getStructureQuery)
    return response
  } catch (error) {
    throw new Error('Failed to fetch structure from API')
  }
}

function* fetchStructureOffline() {
  try {
    const response: StructureDataResponseType[] = yield common.table('dcaStructure').toArray()
    if (response.length > 0) {
      return response[0]
    } else {
      throw new Error('No data found in local database')
    }
  } catch (error) {
    throw new Error('Failed to fetch structure from local database')
  }
}

export function* getStructureSaga(
  data?: PayloadAction<{
    resolve: () => void
    reject: () => void
  }>,
) {
  const { resolve, reject } = data?.payload || {}
  try {
    const isOnline: boolean = yield select(selectAppOnline)
    const structure: StructureDataResponseType = isOnline
      ? yield fetchStructureOnline()
      : yield fetchStructureOffline()
    if (structure) {
      yield put(getStructureSuccess(structure.data))

      if (resolve) {
        resolve()
      }
    } else {
      throw new Error('Failed to fetch structure')
    }
  } catch (error) {
    yield put(getStructureError())
    if (reject) {
      reject()
    }
  }
}

export default function* watchStructureSaga() {
  yield takeEvery(getStructure.type, getStructureSaga)
}
