import { useSelector } from 'react-redux'
import { selectAppOnline } from 'redux/selector'

type DataFetcherReturn = {
  fetchData: <T, U>(
    onlineApi: (params: T) => Promise<U | undefined>,
    offlineApi: (params: T) => Promise<U | undefined>,
    onlineParams: T,
    offlineParams: T,
  ) => Promise<U | undefined>
}

/**
 * Custom hook to fetch data from API or local database
 * @returns
 */
const useDataFetcher = (): DataFetcherReturn => {
  const isAppOnline = useSelector(selectAppOnline)

  /**
   * T is the type of the params for the API
   * U is the type of the response from the API
   * @param onlineApi
   * @param offlineApi
   * @param onlineParams
   * @param offlineParams
   * @returns
   */
  const fetchData = async <T, U>(
    onlineApi: (params: T) => Promise<U | undefined>,
    offlineApi: (params: T) => Promise<U | undefined>,
    onlineParams: T,
    offlineParams: T,
  ): Promise<U | undefined> => {
    try {
      const data = await (isAppOnline ? onlineApi(onlineParams) : offlineApi(offlineParams))
      return data
    } catch (error) {
      console.error('fetchData error', error)
      throw error
    }
  }

  return { fetchData }
}

export default useDataFetcher
