import { LAYOUTS, PAGE_TYPES } from 'config/constants'
import { ProductDetailPDFWrapper } from 'features/PDF/screens/ProductDetailPDF'
import ProductGroupListPDF from 'features/PDF/screens/ProductGroupListPDF'
import ProductListPDF from 'features/PDF/screens/ProductListPDF'
import ComparisonPDF from './ComparisonPDF'
import ManualContentPDF from './ManualContentPDF'

type PDFContentProps = {
  type: string | undefined
  pageId: number
  pageIds?: number[] | any
  onDownloadStatus: (result: boolean) => void
}
const PDFContent = ({ type, ...itemProps }: PDFContentProps) => {
  switch (type) {
    case LAYOUTS.PRODUCT_DETAILS:
      return <ProductDetailPDFWrapper {...itemProps} />
    case LAYOUTS.PRODUCT_LIST:
      return <ProductListPDF {...itemProps} />
    case LAYOUTS.PRODUCT_GROUPS_LIST:
      return <ProductGroupListPDF {...itemProps} />
    case LAYOUTS.MANUAL_CONTENT:
      return <ManualContentPDF {...itemProps} />
    case PAGE_TYPES.COMPARISON:
      return <ComparisonPDF {...itemProps} />
    default:
      return null
  }
}

export default PDFContent
