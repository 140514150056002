import { replaceSpaceByDash } from 'helper/common'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  onbaordingAnalyticsParamsType,
  onsiteSearchAnalyticsParamsType,
  pageInfoAnalyticsParamsType,
} from 'config/adobeAnalytics/types'
import {
  selectCurrentCountry,
  selectCurrentLanguage,
  selectIsAdobeAnalyticsAvailable,
} from 'redux/selector'
import packageJson from '../../package.json'

function isElectron() {
  return window.electron?.isDesktop()
   
}

const useAnalytics = () => {
  const isAdobeAvailable = useSelector(selectIsAdobeAnalyticsAvailable)
  const currentCountry = useSelector(selectCurrentCountry)
  const currentLanguage = useSelector(selectCurrentLanguage)
  const { version } = packageJson

  const initAdobeAnalytics = useCallback(() => {
    if (!isAdobeAvailable) return

    window.digitalData.application.applicationInfo.language = currentLanguage?.languageCode ?? ''
    window.digitalData.application.applicationInfo.country = currentCountry?.name ?? ''
    window.digitalData.application.applicationInfo.version = version

    if (isElectron()) {
      window.digitalData.application.applicationInfo.typo = 'desktop'
    }
  }, [isAdobeAvailable, currentLanguage])

  const setPageInfoAnalytics = ({ pageName, pageId, pageType }: pageInfoAnalyticsParamsType) => {
    if (!isAdobeAvailable) return

    if (pageName) {
      window.digitalData.page.pageInfo.pageName = replaceSpaceByDash(pageName)
    }
    if (pageType) {
      window.digitalData.page.pageInfo.pageType = pageType
    }
    if (pageId) {
      window.digitalData.page.pageInfo.pageId = pageId
    }
  }

  const setApplicationMode = (mode: 'online' | 'offline') => {
    if (!isAdobeAvailable) return

    window.digitalData.application.applicationInfo.mode = mode
  }

  const setSearchAnalytics = ({
    searchTerm,
    searchTotalResult,
  }: onsiteSearchAnalyticsParamsType) => {
    if (!isAdobeAvailable) return

    if (searchTerm) {
      window.digitalData.page.pageInfo.onsiteSearchTerm = searchTerm
    }
    if (searchTotalResult) {
      window.digitalData.page.pageInfo.onsiteSearchResult = searchTotalResult
    }
  }

  const setOnboardingAnalytics = ({ step }: onbaordingAnalyticsParamsType) => {
    if (!isAdobeAvailable) return

    if (step) {
      window.digitalData.attribute.onboarding.step = step
    }
  }

  return {
    initAdobeAnalytics,
    setPageInfoAnalytics,
    setApplicationMode,
    setSearchAnalytics,
    setOnboardingAnalytics,
  }
}

export default useAnalytics
