import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons'

const ButtonScrollToTop: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return <FontAwesomeIcon icon={faArrowAltCircleUp} onClick={scrollToTop} size='3x' />
}

export default ButtonScrollToTop
