import { universal } from 'database/db'
import { getItemFromTable } from 'database/queries/common'
import { saveCountriesDb } from 'database/queries/country'
import getCountriesQuery from 'services/country'
import { CountriesResponseType } from 'types'
import { dbCurrentEntries } from 'config/constants'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  getCountries,
  getCountriesError,
  getCountriesLoading,
  getCountriesSuccess,
} from 'redux/reducers/countriesReducer'
import { selectAppOnline } from 'redux/selector'

function* fetchCountriesOnline() {
  try {
    yield put(getCountriesLoading())
    const countries: CountriesResponseType = yield call(getCountriesQuery)
    yield saveCountriesDb(countries)
    return countries
  } catch (error) {
    throw new Error('Failed to fetch countries from API')
  }
}

function* fetchCountriesOffline() {
  try {
    const countries: CountriesResponseType = yield getItemFromTable(
      universal,
      'dcaSettings',
      dbCurrentEntries.COUNTRIES,
    )
    return countries
  } catch (error) {
    throw new Error('Failed to fetch countries from local database')
  }
}

export function* fetchCountries() {
  try {
    const isOnline: boolean = yield select(selectAppOnline)
    const countries: CountriesResponseType = isOnline
      ? yield fetchCountriesOnline()
      : yield fetchCountriesOffline()
    const sortedCountries = [...countries.data].sort((a, b) => a.name.localeCompare(b.name))
    yield put(getCountriesSuccess(sortedCountries))
  } catch (error) {
    yield put(getCountriesError())
  }
}

export default function* countriesSaga() {
  yield takeLatest(getCountries.type, fetchCountries)
}
