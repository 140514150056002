import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import moment from 'moment'

import * as globals from './globals'

let signInInProgress = false

const callAuth = async (): Promise<string> => {
  if (signInInProgress) {
    // If a sign-in attempt is already in progress, wait for 1 second and try again
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return callAuth()
  }

  try {
    signInInProgress = true
    const user = await Auth.signIn(
      process.env.REACT_APP_COGNITO_USERNAME || '',
      process.env.REACT_APP_COGNITO_PASSWORD,
    )

    const userToken = user.signInUserSession.idToken.jwtToken
    globals.SET_APP_DATA(globals.AppDataKeyEnum.userToken, userToken)
    return userToken
  } catch (error) {
    console.error('callAuth error', error)
    throw error
  } finally {
    signInInProgress = false
  }
}

export const getCognitoToken = async () => {
  let userToken = globals.GET_APP_DATA(globals.AppDataKeyEnum.userToken)
  try {
    const data: CognitoUserSession = await Auth.currentSession()
    if (!userToken) {
      userToken = data.getAccessToken().getJwtToken()
      globals.SET_APP_DATA(globals.AppDataKeyEnum.userToken, userToken)
    }
  } catch (error) {
    console.error('getCognitoToken error', error)
    userToken = await callAuth()
  }

  return userToken
}

export const doDaysDifferBetweenDates = (date1: string, date2: string) => {
  const dt1 = moment(date1).startOf('day')
  const dt2 = moment(date2).startOf('day')
  return !dt1.isSame(dt2)
}

/**
 * Sorts an array of objects by a string property in ascending order.
 *
 * @param arr The array of objects to sort.
 * @param key The string property to sort the objects by.
 * @returns A sorted copy of the original array.
 */
export const sortByStringPropertyAscending = <T extends Record<string, string | number | object>>(
  arr: T[],
  key: keyof T,
): T[] => {
  return [...arr].sort((a: T, b: T) => String(a[key]).localeCompare(String(b[key])))
}

export function extractAnchorsFromText(text: string): string {
  const anchorTags: string[] = text.split('<a')
  let extractedAnchors = ''

  for (const tag of anchorTags) {
    if (tag.includes('globalAppNavigation')) {
      const anchorDataArr: string[] = tag.split(')}}>')
      const anchorText: string = anchorDataArr[1]
      const anchorURL: string = anchorDataArr[0].split('globalAppNavigation(')[1]
      const earlierHTML: string = anchorDataArr[0].split('onClick=')[0]

      const hrefAttribute: string =
        anchorURL.startsWith('\'') || anchorURL.startsWith('"')
          ? `href=${anchorURL}`
          : `href="${anchorURL}"`

      const anchorHTML: string =
        anchorURL.includes('http') || anchorURL.includes('https') || isNaN(Number(anchorURL))
          ? `<a target="_blank" ${hrefAttribute} class='dca-anchor' listener="true">${anchorText}`
          : `<a ${earlierHTML} class='dca-anchor' data-id='${anchorURL}'>${anchorText}`

      extractedAnchors += anchorHTML
    } else {
      extractedAnchors += tag
    }
  }

  return extractedAnchors
}
export const extractNumberFromGlobalAppNavigationLinks = (text: string): number => {
  if (!text) {
    return 0
  }

  const linkTags: string[] = text.split('<a')
  let number = 0
  for (const linkTag of linkTags) {
    const numberMatch = linkTag.match(/\d+/)
    number = numberMatch ? Number(numberMatch[0]) : 0
  }

  return number
}

/**
 * Removes anchor tags with globalAppNavigation links from the input text.
 * @param {string} text - The input text to remove anchor tags from.
 * @returns {string} The input text without anchor tags with globalAppNavigation links.
 */
export const removeGlobalAppNavigationLinks = (text: string): string => {
  if (!text) {
    return ''
  }

  const anchorTags: string[] = text.split('<a')
  let textWithoutLinks = ''

  for (const anchorTag of anchorTags) {
    if (anchorTag.includes('globalAppNavigation')) {
      const anchorData: string[] = anchorTag.split(')}}>')
      const anchorText: string = anchorData[1]
      const anchorLink: string = anchorData[0]
        .split('globalAppNavigation(')[1]
        .replace(/['"]+/g, '')
      const earlier: string = anchorTag.split('href=')[0]

      if (anchorLink) {
        const fullTag = `${earlier} style="color:#007bff">${anchorText}`
        textWithoutLinks += fullTag
      }
    } else {
      textWithoutLinks += anchorTag
    }
  }

  return textWithoutLinks
}

/**
 * Find and return the value of the first text object in the given array
 * where the type property is 'short'.
 * @param {Array} textArray - An array of text objects
 * @returns {String | Null} - The value of the first text object with type 'short', or null if none found
 */
export const findShortTextValue = (
  textArray: {
    value: string
    type: 'long' | 'short'
  }[],
) => {
  // Check if input is an array
  if (Array.isArray(textArray)) {
    // Find the first text object where the type property is 'short'
    const shortTextObject = textArray.find(({ type }) => type === 'short')
    // If a short text object was found, return its value property
    if (shortTextObject) return shortTextObject.value
  }
  // Return null if no short text object was found
  return null
}

/**
 * Upper-cases the first letter of each word in the given string.
 * @param str
 * @returns
 */
export function capitalizeFirstLetterOfEachWord(str?: string): string {
  if (!str) return ''

  const lowerCaseStr = str.toLowerCase()

  return lowerCaseStr
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function formatMoney(number: number, currencySymbol: string = '€') {
  if (!number) return '0';
  const numberFormatted = number.toFixed(2);
  const price = numberFormatted.toString();

  // Replace the ',' with '.' and the '.' with ','
  const formattedPrice = price.replace(/,/g, '#').replace(/\./g, ',').replace(/#/g, '.');
  return `${formattedPrice} ${currencySymbol}`;
}

export function getCurrencySymbol(formattedCurrency: string) {
  if (typeof formattedCurrency !== 'string') {
    return undefined;
  }
  const currencyCodeRegExp = /[^\d,.]+/g;
  const symbolMatch = formattedCurrency.match(currencyCodeRegExp);
  if (symbolMatch) {
    return symbolMatch[0];
  }
}