import { Container } from 'react-bootstrap'
import { ContentTextType } from 'types'
import 'styles/components/anchor.scss'

type TextProps = {
  layoutData: ContentTextType
}

const Text = ({ layoutData }: TextProps) => {
  if (!layoutData.text) {
    return null
  }
  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: layoutData.text }} />
    </Container>
  )
}

export default Text
