import { replaceSpaceByDash } from 'helper/common'
import isArray from 'lodash/isArray'
import {
  categoryAnalyticsParamsType,
  favoriteAnalyticsParamsType,
  productAnalyticsParamsType,
  productDetailsCategoryAnalyticsParamsType,
  videoAnalyticsParamsType,
} from './types'

const getFileName = (str: string): string => str.replace(/^.*[\\/]/, '')

export const productCompareAnalytics = (prods: Array<unknown>) => {
  if (prods && isArray(prods)) {
    window.digitalData.attribute.comparisonProducts = prods.join(',')
  }
}

export const favoriteAnalytics = ({
  saveButtonFlag,
  favoriteProducts,
}: favoriteAnalyticsParamsType) => {
  if (saveButtonFlag) {
    window.digitalData.attribute.favorite.save = saveButtonFlag
  }
  if (favoriteProducts) {
    window.digitalData.attribute.favorite.products = favoriteProducts
  }
}

export const categoryAnalytics = ({
  primaryCategory,
  subCategory1,
  subCategory2,
  groupId,
}: categoryAnalyticsParamsType) => {
  if (primaryCategory) {
    window.digitalData.page.category.primaryCategory = replaceSpaceByDash(primaryCategory)
  }
  if (subCategory1) {
    window.digitalData.page.category.subCategory1 = replaceSpaceByDash(subCategory1)
  }
  if (subCategory2) {
    window.digitalData.page.category.subCategory2 = replaceSpaceByDash(subCategory2)
  }
  if (groupId) {
    window.digitalData.page.category.productGroupID = groupId
  }
}

export const productDetailsCategoryAnalytics = ({
  primaryCategory,
  subCategory1,
  subCategory2,
  subCategory3,
  subCategory4,
  subCategory5,
}: productDetailsCategoryAnalyticsParamsType) => {
  if (primaryCategory) {
    window.digitalData.product.category.primaryCategory = replaceSpaceByDash(primaryCategory)
  }
  if (subCategory1) {
    window.digitalData.product.category.subCategory1 = replaceSpaceByDash(subCategory1)
  }
  if (subCategory2) {
    window.digitalData.product.category.subCategory2 = replaceSpaceByDash(subCategory2)
  }
  if (subCategory3) {
    window.digitalData.product.category.subCategory3 = replaceSpaceByDash(subCategory3)
  }
  if (subCategory4) {
    window.digitalData.product.category.subCategory4 = replaceSpaceByDash(subCategory4)
  }
  if (subCategory5) {
    window.digitalData.product.category.subCategory5 = replaceSpaceByDash(subCategory5)
  }
}

export const clearProductDetailsCategoryAnalytics = () => {
  window.digitalData.product.category.subCategory4 = ''
  window.digitalData.product.category.subCategory5 = ''
}

export const productAnalytics = ({ sku, ean, name, type }: productAnalyticsParamsType) => {
  if (sku) {
    window.digitalData.productInfo.sku = sku
  }
  if (ean) {
    window.digitalData.productInfo.ean = ean
  }
  if (name) {
    window.digitalData.productInfo.productName = name
  }
  if (type) {
    window.digitalData.productInfo.productType = type
  }
}

export const clearProductAnalytics = () => {
  window.digitalData.productInfo.sku = ''
  window.digitalData.productInfo.ean = ''
  window.digitalData.productInfo.productName = ''
  window.digitalData.productInfo.productType = ''
}

export const videoAnalytics = ({
  videoName,
  videoEvent,
  videoMilestone,
}: videoAnalyticsParamsType) => {
  if (videoName) {
    window.digitalData.attribute.media.videoName = getFileName(videoName)
  }
  if (videoEvent) {
    window.digitalData.attribute.media.videoEvent = videoEvent
  }
  if (videoMilestone) {
    window.digitalData.attribute.media.videoMilestone = videoMilestone
  }
}

export const linkDataAnalytics = (linkName: string) => {
  window.digitalData.link.linkName = linkName
}

export const addFilterAnalytics = (filter: Array<string>, status: string) => {
  if (status === 'productFinder') {
    window.digitalData.attribute.productFinder.filter = filter && filter.join(':')
  } else {
    window.digitalData.attribute.productFinder.filter = filter && filter.join('|')
  }
}

export const clearFilterAnalytics = () => {
  window.digitalData.attribute.productFinder.filter = ''
}
