import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

const ImprintBlock = () => {
  const translations = useSelector(selectTranslations)
  const { showModal, hideModal } = useGlobalModalContext()

  const imprintText = translations?.imprint ?? 'Imprint'
  const imprintPolicyText = translations?.imprint_policy_text ?? <span />

  const onShowToggleImprintMagnify = () => {
    showModal('MAGNIFY_MODAL', {
      showModal: true,
      heading: imprintText,
      innerHtml: imprintPolicyText,
      handleClose: hideModal,
    })
  }

  return (
    <React.Fragment>
      <Card className='imprint-block setting-card'>
        <Row>
          <Col md='10'>
            <h6 className='settings-heading'>
              <i className='karcher-icon settings-icons'>&#xe823;</i>
              {imprintText}
            </h6>
          </Col>
          <Col md='2'>
            <Button className='button-magnify' onClick={onShowToggleImprintMagnify}>
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </Col>
        </Row>

        <p
          className='px-3 pt-2'
          dangerouslySetInnerHTML={{
            __html: imprintPolicyText,
          }}
        />
      </Card>
    </React.Fragment>
  )
}

export default ImprintBlock
