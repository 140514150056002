import React from 'react'
import { Modal } from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { ImageItemType } from 'types'
import 'styles/modal/imageViewerModal.scss'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DCAImage from 'components/DCAImage'

export type ImageViewerProps = {
  images: ImageItemType[]
  selectedImageIndex: number
  handleClose: () => void
}

function ImageViewerModal({ images, selectedImageIndex = 0, handleClose }: ImageViewerProps): React.ReactElement {
  const imagesFull: string[] = images.reduce<string[]>((acc, imageData) => {
    const imageFullUrl = imageData.urls.find((url) => url.type === 'full')
    if (imageFullUrl) {
      acc.push(imageFullUrl.url)
    }
    return acc
  }, [])

  function renderArrow(
    onClickHandler: () => void,
    className: string,
    symbol: string,
    disabled: boolean,
  ) {
    if (!disabled) {
      return (
        <i
          className={`karcher-icon ${className}`}
          role='button'
          tabIndex={0}
          onClick={onClickHandler}
        >
          <span dangerouslySetInnerHTML={{ __html: symbol }} />
        </i>

      )
    }
    return null
  }

  function renderThumbs() {
    return (
      imagesFull.map((url) => (
        <DCAImage
          key={url}
          src={url}
          alt={`thumbnail ${url}`}
          style={{ cursor: 'pointer', objectFit: 'cover', width: 80, height: 70}}
        />
      ))
    )
  }

  return (
    <Modal dialogClassName="fullscreen-modal" show={true} onHide={handleClose} animation={false}>
      <Modal.Body >
        <div className='close-modal-btn' onClick={handleClose}>
          <FontAwesomeIcon className='close-modal-icon' icon={faClose} />
        </div>
        <Carousel
          emulateTouch
          selectedItem={selectedImageIndex}
          showArrows={false}
          showStatus={false}
          showThumbs
          preventMovementUntilSwipeScrollTolerance
          showIndicators={false}
          renderArrowNext={(onClick) =>
            renderArrow(onClick, 'arrow-next', '&#xe804;', !imagesFull.length)
          }
          renderArrowPrev={(onClick) =>
            renderArrow(onClick, 'arrow-prev', '&#xe803;', !imagesFull.length)
          }
          renderThumbs={renderThumbs}
        >
          {imagesFull.map((url) => (
            <div className='fill' key={url}>
              <DCAImage key={url} src={url} alt='product-image' className='main-image' />
            </div>
          ))}
        </Carousel>
      </Modal.Body>

    </Modal>
  )
}

export default ImageViewerModal
