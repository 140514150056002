import { Card, Col, Container, Row } from 'react-bootstrap'
import { ImageHeadlineTwoColumnsType } from 'types'
import { extractAnchorsFromText } from 'config/libs/helpers'
import DCAImage from 'components/DCAImage'

type Props = {
  layoutData: ImageHeadlineTwoColumnsType
}

type RenderItemProps = {
  image?: string
  headline?: string
  text?: string
  link?: number
  pageId?: number
}

const ImageHeadlineTwoColumns = ({ layoutData }: Props) => {
  const renderItem = ({ image, headline, text }: RenderItemProps) => {
    const textWithAnchors = extractAnchorsFromText(text ?? '')

    return (
      <Card className='card-wrapper-no-border px-4'>
        {image && <DCAImage src={image} type='cardImage' />}
        {headline && <h5 className='ak-bold mt-3'>{headline}</h5>}
        {textWithAnchors && <div dangerouslySetInnerHTML={{ __html: textWithAnchors }} />}
      </Card>
    )
  }

  return (
    <Container className='image-headline-two-columns pt-4'>
      <Row>
        <Col md={6} lg={6}>
          {renderItem({
            image: layoutData.image_left,
            headline: layoutData.headline_left,
            text: layoutData.text_left,
          })}
        </Col>

        <Col md={6} lg={6}>
          {renderItem({
            image: layoutData.image_right,
            headline: layoutData.headline_right,
            text: layoutData.text_right,
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default ImageHeadlineTwoColumns
