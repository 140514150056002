import { Row, Col, Accordion, Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import HorizontalRule from 'components/HorizontalRule'

const ConfigurableComponents = ({ configurableComponents }: { configurableComponents: any }) => {
  const translations = useSelector(selectTranslations)

  const headline = translations?.configurable_component ?? 'CONFIGURABLE COMPONENT'

  if (!configurableComponents) return null
  const renderProducts = (compatibleProducts: any) => (
    <>
      <Col md='6' className='compatible_machine_col pl-0 pr-0'>
        {compatibleProducts.map((row: any, index: number) => {
          if (index % 2 === 0) {
            return (
              <Accordion
                defaultActiveKey='0'
                key={Math.random().toString(36)}
                className='configurable-component-acc'
              >
                <Card>
                  <Card.Header>
                    <i className='demo-icon icon-a_069_feedback_filled'>&#xe80c;</i>
                    {`  ${row.name}`}
                  </Card.Header>
                  <Accordion.Collapse eventKey='1'>
                    <Card.Body>{row.description}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )
          }
          return null
        })}
      </Col>
      <Col md='6' className='compatible_machine_col pl-0 pr-0'>
        {compatibleProducts.map((row: any, index: number) => {
          if (index % 2 !== 0) {
            return (
              <Accordion
                defaultActiveKey='0'
                key={Math.random().toString(36)}
                className='configurable-component-acc'
              >
                <Card>
                  <Card.Header>
                    <i className='demo-icon icon-a_069_feedback_filled'>&#xe80c;</i>
                    {row.name}
                  </Card.Header>
                  <Accordion.Collapse eventKey='1'>
                    <Card.Body>{row.description}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )
          }
          return null
        })}
      </Col>
    </>
  )

  return (
    <Container
      className='configurable-components-wrapper mt-4'
      id={PRODUCT_DETAILS_SECTIONS.CONFIGURABLE_COMPONENT}
    >
      <h6 className='ak-bold'>{headline}</h6>

      <HorizontalRule />

      <Row>{renderProducts(configurableComponents)}</Row>
    </Container>
  )
}

export default ConfigurableComponents
