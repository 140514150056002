import { gql } from 'apollo-boost'

export const GET_STRUCTURE = (languageCode: string, countryCode: string, year: number) => gql`
    {
      getStructure(input: {
        languageCode: "${languageCode}",
        countryCode: "${countryCode}",
        year:${year}
      }
      ){
        export
        year
        country
        language
        createdAt
        data {
          id
          title
          parentId
          sorting
          type
        }
      }
    }
  `
