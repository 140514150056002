import { common, homeDb, professionalDb } from 'database/db'
import { IDcaProductDetails } from 'database/dbTypes'
import { SearchContent, SearchItem, SearchQueryDataKey, SearchResponseType } from 'types/searchType'
import { ProductDetailItemType } from 'types'

function getInitialResponse(): Record<
  SearchQueryDataKey,
  { docCount: number; items: (SearchItem | SearchContent)[] }
> {
  return Object.values(SearchQueryDataKey).reduce((acc, type) => {
    acc[type] = { docCount: 0, items: [] }
    return acc
  }, {} as Record<SearchQueryDataKey, { docCount: number; items: (SearchItem | SearchContent)[] }>)
}

async function fetchProducts(db: any, searchKey: string) {
  // Remove spaces from searchKey
  const processedSearchKey = searchKey.replace(/\s+/g, '').toLocaleLowerCase()
  return db.dcaProductDetails
    .filter((item: IDcaProductDetails) => {
      // The idea is to fetch items where the processed productName or partnumber starts with the processedSearchKey.
      const processedProductName = item.productName.replace(/\s+/g, '').toLocaleLowerCase()
      const processedPartnumber = item.partnumber.replace(/\s+/g, '').toLocaleLowerCase()
      return (
        processedProductName.startsWith(processedSearchKey) ||
        processedPartnumber.startsWith(processedSearchKey)
      )
    })
    .toArray()
}

async function fetchContents(searchKey: string) {
  const processedSearchKey = searchKey?.replace(/\s+/g, '').toLocaleLowerCase()

  return common.dcaSearchManualContent
    .filter((item) => {
      const processedHeadline = item?.headline?.replace(/\s+/g, '').toLocaleLowerCase() ?? ''
      const processedText = item?.text?.replace(/\s+/g, '').toLocaleLowerCase() ?? ''
      return (
        processedHeadline.startsWith(processedSearchKey) ||
        processedText.startsWith(processedSearchKey)
      )
    })
    .toArray()
}

// Function to determine the product category
function determineCategory(item: ProductDetailItemType) {
  if (item.businessUnit === 'hg' && item.productType === 'machine') return SearchQueryDataKey.hg
  if (item.businessUnit === 'pro' && item.productType === 'machine') return SearchQueryDataKey.pro
  if (item.productType === 'accessory') return SearchQueryDataKey.accessories
  if (item.productType === 'detergent') return SearchQueryDataKey.detergents
  if (item.productType === 'pastproduct') return SearchQueryDataKey.old
  return null
}

export const searchDb = async (searchKey: string): Promise<SearchResponseType> => {
  try {
    const response = getInitialResponse()
    const homeProducts = await fetchProducts(homeDb, searchKey)
    const proProducts = await fetchProducts(professionalDb, searchKey)
    const allProducts: ProductDetailItemType[] = [...homeProducts, ...proProducts]

    const contents = await fetchContents(searchKey)
    if (allProducts.length === 0 && contents.length === 0) {
      throw new Error('Do not have any product with this search key')
    }

    allProducts.forEach((item) => {
      const categoryKey = determineCategory(item)

      if (categoryKey) {
        const { data } = item
        const prepareItem: SearchItem = {
          id: data.id,
          name: data.name,
          partnumber: data.partnumber,
          partnumberFormatted: data.partnumberFormatted,
          productType: data.productType,
          images: data.images,
          productgroupId: data.productgroupId,
          price: data.price,
          priceFormatted: data.priceFormatted,
          priceGross: data.priceGross,
          priceGrossFormatted: data.priceGrossFormatted,
          priceNet: data.priceNet,
          priceNetFormatted: data.priceNetFormatted,
          // eslint-disable-next-line camelcase
          rootline_updated: data.rootline_updated,
        }
        response[categoryKey].items.push(prepareItem)
        response[categoryKey].docCount += 1
      }
    })

    response[SearchQueryDataKey.content].items = contents as SearchContent[]
    response[SearchQueryDataKey.content].docCount = contents.length

    return response
  } catch (error) {
    console.error('Error searchDb:', error)
    return getInitialResponse()
  }
}
