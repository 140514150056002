import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { selectCurrentLanguage, selectTranslations } from 'redux/selector'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import useCookie from '../hooks/useCookie'
import useAnalytics from 'hooks/useAnalytics'

const CookiePolicy = () => {
  const currentLanguage = useSelector(selectCurrentLanguage)
  const translations = useSelector(selectTranslations)
  const { initCookeBanner, isCookieFunction, isCookieTemplate } = useCookie()
  const { setPageInfoAnalytics } = useAnalytics()

  const [isCookiesSet, setIsCookiesSet] = useState<boolean>(false)
  const [isInit, setIsInit] = useState<boolean>(false)

  const cookiePolicy = translations?.cookie_policy ?? TRANSLATIONS.cookiePolicy

  useEffect(() => {
    /** Analytics */
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.COOKIE_POLICY,
      pageType: PAGE_TYPES.COOKIE_POLICY,
    })

    setIsInit(true)
  }, [])

  useEffect(() => {
    if (currentLanguage && isInit) {
      initCookeBanner(currentLanguage)
    }
  }, [isInit, currentLanguage])

  /**
   * Check if Cookie Template and Function is set
   * then active show popup cookie settings
   */
  useEffect(() => {
    if (isCookieTemplate && isCookieFunction) {
      setIsCookiesSet(true)
    }
  }, [isCookieTemplate, isCookieFunction])

  return (
    <Container className='cookie-policy-screen px-0' fluid>
      <div style={{ pointerEvents: isCookiesSet ? undefined : 'none' }}>
        <Header pageId={PAGE_IDS.COOKIE_POLICY} />
      </div>
      <Breadcrumb pageId={PAGE_IDS.COOKIE_POLICY} staticTitle={cookiePolicy} />

      <Container>
        <br />
        <h1>{cookiePolicy.toUpperCase()}</h1>
        <br />
        <h5>{translations?.cookie_definition_headline ?? TRANSLATIONS.cookieDefinitionHeadline}</h5>
        <span>{translations?.cookie_definition ?? TRANSLATIONS.cookieDefinition}</span>
        <br />
        <br />
        <br />
        <h5>{translations?.cookie_settings_headline ?? TRANSLATIONS.cookieSettingsHeadline}</h5>
        <span>
          {translations?.cookie_settings_definition ?? TRANSLATIONS.cookieSettingsDefinition}
        </span>
        <br />
        <br />
        <button id='ot-sdk-btn' className='app-button ot-sdk-show-settings'>
          {TRANSLATIONS.cookieSettingsHeadline.toUpperCase()}
        </button>
        <br />
        <br />
        <br />
        <div id='ot-sdk-cookie-policy'></div>
      </Container>
    </Container>
  )
}
export default CookiePolicy
