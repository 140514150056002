import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/hooks/useSelector'
import { getPageContent } from 'redux/reducers/pageContentReducer';
import { selectPageContent } from 'redux/selector';

const usePageContent = (pageId: number) => {
  const pageData = useSelector(state => selectPageContent(state, pageId))
  const dispatch = useDispatch();
  const { data, error, loading } = pageData ?? {}

  useEffect(() => {
    if (data?.pageId === undefined) {
      dispatch(getPageContent(pageId))
    }
  }, [pageId])

  return { data, error, loading }
}

export { usePageContent }