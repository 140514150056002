import { useState } from 'react'

type OnDoneFunc = () => void

// This hook is used to handle the loading state when exporting a PDF
export const usePDFLoading = (onExportPDF?: (onDone: OnDoneFunc) => void) => {
  const [pdfLoading, setPdfLoading] = useState(false)

  const handleExportPDF = () => {
    if (!onExportPDF) return

    setPdfLoading(true)

    const onFinishExport = () => {
      setPdfLoading(false)
    }
    
    onExportPDF(onFinishExport)
  }

  return {
    pdfLoading,
    handleExportPDF,
  }
}
