import { getProductDetailDbById } from 'database/queries/product'
import { getProductDetailQuery } from 'services'
import { useEffect, useState } from 'react'
import { ProductDetailItemType, ProductDetailResponseType } from 'types'
import { LAYOUTS } from 'config/constants'
import useDataFetcher from 'hooks/useDataFetcher'
import { usePageContent } from './pageContent'

const useProductDetail = (pageId: number) => {
  const { fetchData } = useDataFetcher()

  const { data, error: pageError, loading: pageLoading } = usePageContent(pageId)
  const [productDetail, setProductDetail] = useState<ProductDetailItemType>()
  const [contentLoading, setContentLoading] = useState(false)
  const [contentError, setError] = useState(false)
  useEffect(() => {
    if (data?.pageId !== undefined && !pageLoading) {
      handleProductDetailData()
    }
  }, [data, pageLoading])

  const handleProductDetailData = async () => {
    try {
      setContentLoading(true)
      const productContent = data?.content?.find((item) => item.type === LAYOUTS.PRODUCT_DETAILS)
      const config = JSON.parse(productContent?.config ?? '{}')
      if (config) {
        const productDetailResponse = await fetchData<number, ProductDetailResponseType>(
          getProductDetailQuery,
          getProductDetailDbById,
          config.productId,
          config.productId,
        )

        if (!productDetailResponse) return

        const { data } = productDetailResponse
        const productDetailData: ProductDetailItemType = JSON.parse(data ?? {})
        if (productDetailData) {
          setProductDetail(productDetailData)
        }
      }
    } catch (error) {
      setError(true)
    } finally {
      setContentLoading(false)
    }
  }

  return {
    productDetail,
    error: pageError || contentError,
    loading: pageLoading || contentLoading,
  }
}

export default useProductDetail
