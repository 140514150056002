import { HeadlineTextType } from 'types'
import { formatAnchorTag } from '../pdfHelper'

export const getHeadlineText = (headlines: HeadlineTextType[]) => {
  let str = ''

  headlines.forEach((item) => {
    const headlineStr = item.headline ? `<h1>${item.headline}</h1>` : ''
    const descStr = item.text ? `<p>${item.text}</p>` : ''
    str += `
    <div class="headline-text">
      ${headlineStr}
      ${formatAnchorTag(descStr)}
    </div>
  `
  })
  return str
}
