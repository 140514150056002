import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getApplications = (applicationArr: string[], translations: TranslationsPDFType) => {
  if (!applicationArr || !applicationArr.length) return ''

  const [leftStr, rightStr] = applicationArr.reduce(
    ([left, right], item, index) => {
      const row = `<tr><td>■ ${item}</td></tr>`
      return index % 2 === 0 ? [left + row, right] : [left, right + row]
    },
    ['', ''],
  )

  return `<div class="applications-sec">
      <h4>${tr(translations, 'applications', 'APPLICATIONS').toLocaleUpperCase()}</h4>
      <div class="line"></div>
      <div class="small" style="display:grid; grid-template-columns: 1fr 1fr; gap: 10px;">
        <div style="width: 100%">
          <table class="table" style="width: 100%; font-size: 10px;">
            <tbody>
              ${leftStr}
            </tbody>
          </table>
        </div>
        <div style="width: 100%">
          <table class="table" style="width: 100%; font-size: 10px;">
            <tbody>
              ${rightStr}
            </tbody>
          </table>
        </div>

      </div>
    </div>`
}

export default getApplications
