import images from 'assets/images/images'
import { tr } from '../pdfHelper'

const TICK_IMG_VALUE = '1'

export const getEquipment = (equipmentLabels: string[][], translations: Record<string, string>) => {
  let str = ''

  const firstCol = (isFirstCol: boolean) => (isFirstCol ? 'padding-left: 5px;' : '')

  const emptyField = (value: string) => (value === '-' ? 'align-self: center;' : '')

  equipmentLabels.forEach((labels) => {
    str += '<div class="compare-row">'
    labels.forEach((label, index) => {
      str += `
        <div class="compare-col" style="display: flex">
        ${
          label === TICK_IMG_VALUE
            ? `<img src="${images.tick}" style="margin-top: 0px; align-self: center" />`
            : `<label style="${firstCol(index === 0)} ${emptyField(label)}">${label}</label>`
        }
        </div>
      `
    })

    str += '</div>'
  })

  str = `
    <div class="section">
      <h4>${tr(translations, 'equipment', 'EQUIPMENT').toLocaleUpperCase()}</h4>
      <div class="compare-wrap">
        ${str}
      </div>
    </div>`
  return str
}
