import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProductGroupResponseType } from 'types'

export interface ProductGroupType {
  data: ProductGroupResponseType
  loading: boolean
  error: boolean
}

export type ProductGroupState = {
  [productGroupId: string]: ProductGroupType
}

const initialState: ProductGroupState = {}

export const productGroupSlice = createSlice({
  name: 'productGroup',
  initialState,
  reducers: {
    getProductGroup(state, action: PayloadAction<string>) {
      const productGroupId = action.payload
      state[productGroupId] = {
        loading: true,
        error: false,
        data: {} as ProductGroupResponseType,
      }
    },
    getProductGroupSuccess(
      state,
      action: PayloadAction<{ productGroupId: string; obj: ProductGroupResponseType }>,
    ) {
      const { productGroupId, obj } = action.payload
      state[productGroupId] = {
        loading: false,
        error: false,
        data: obj,
      }
    },
    getProductGroupError(state, action: PayloadAction<string>) {
      const productGroupId = action.payload
      state[productGroupId] = {
        loading: false,
        error: true,
        data: {} as ProductGroupResponseType,
      }
    },
    resetProductGroup: () => initialState,
  },
})

export const { getProductGroup, getProductGroupSuccess, getProductGroupError, resetProductGroup } =
  productGroupSlice.actions

export default productGroupSlice.reducer
