import images from 'assets/images/images';
import DCAImage from 'components/DCAImage';
import { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { cancelAllInprogressDownloads, deleteAllDownload } from 'redux/download/download.hook';
import { DocumentType } from 'redux/download/download.reducer';
import { selectDownloadData } from 'redux/download/download.selector';
import { selectAppOnline, selectTranslations } from 'redux/selector';
import 'styles/modal/downloadModal.scss';
import { ProgressBar } from './ProgressBar';
import { RendererEvent } from 'electron-shared';
import { showAlert } from 'config/utils/CommonFunction';

export type DownloadModalProps = {
  showModal: boolean
  handleHide: () => void
}

const DownloadModal = ({ showModal, handleHide }: DownloadModalProps) => {
  const t = useSelector(selectTranslations)
  const downloadData = selectDownloadData();
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isOnline = useSelector(selectAppOnline);

  const activeDownloadCount = downloadData.filter(
    (i) => i.state === 'DOWNLOADING' || i.state === 'PAUSED'
  ).length;

  const downloadStartTxt = t?.download_starts_now ?? 'DOWNLOAD STARTS NOW'

  const CHECK_BOXES_DATA = {
    [DocumentType.AK_DCA_COMMON]: t.common_content || 'General Content',
    [DocumentType.AK_DCA_HOME_AND_GARDEN]: t.home_and_garden_database || 'Home & Garden Content',
    [DocumentType.AK_DCA_PROFESSIONAL]: t.professional_database || 'Professional Content',
    [DocumentType.COMMON_ASSET]: t.common_images || 'General Images',
    [DocumentType.HOME_AND_GARDEN_ASSET]: t.home_and_garden_images || 'Home & Garden Images',
    [DocumentType.PRO_ASSET]: t.professional_images || 'Professional Images'
  };

  const onClickDeleteAll = async () => {
    if (!isOnline) {
      showAlert(t?.user_offline_message || 'Sorry, No internet connection is available. Please try again.')
      return
    }

    const response = await window.electron?.asyncInvoke(RendererEvent.SHOW_ALERT, {
      type: 'warning',
      buttons: [t.delete_all || 'Delete', t.cancel || 'Cancel'],
      defaultId: 1,
      cancelId: 0,
      title: t.delete_all || 'Delete All',
      message: t.delete_all_offline_storage || 'Do you want to delete all the data from the offline storage?',
    })

    // 0 is delete, 1 is cancel
    if (response === 1) return;

    setDeleteLoading(true);
    const isSuccessfulDelete = await deleteAllDownload(dispatch);

    if (isSuccessfulDelete) {
      handleHide()
    }
    setDeleteLoading(false);
  }

  const onClickCancelDownload = async () => {
    cancelAllInprogressDownloads(dispatch);
    handleHide();
  };

  return (
    <Modal show={showModal} onHide={handleHide} className='download-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='download-modal-heading'>
          {downloadStartTxt.toUpperCase()}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped hover>
          <thead>
            <tr>
              <th>{t.category || 'Category'}</th>
              <th>{t.required_storage || 'Required Storage'}</th>
              <th>{t.remaining_download_time || 'Remaining Download Time'}</th>
            </tr>
          </thead>
          <tbody>
            {downloadData.map((item) => (
              <tr key={CHECK_BOXES_DATA[item.id]}>
                <td>{item.id}</td>
                <td>{convertFileSize(item.totalBytes)}</td>
                <td>
                  <ProgressBar data={item} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <div className='modal-buttons'>
          {isOnline && (
            <Button variant='primary' className='text-uppercase' onClick={onClickDeleteAll}>
              {deleteLoading ?
                <DCAImage style={{ width: 15 }} src={images.loading} /> :
                (t.delete_all || 'DELETE ALL').toLocaleUpperCase()
              }
            </Button>
          )}

          {activeDownloadCount > 0 && (
            <Button variant='secondary' className='text-uppercase' onClick={onClickCancelDownload}>
              {(t.cancel_download || 'CANCEL DOWNLOAD').toLocaleUpperCase()}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}


const convertFileSize = (bytes: number) => {
  if (bytes < 10 ** 2) return `${bytes}B`;
  if (bytes < 10 ** 6) return `${Math.round(bytes / 10 ** 2) / 10}KB`;
  if (bytes < 10 ** 9) return `${Math.round(bytes / 10 ** 5) / 10}MB`;
  return `${Math.round(bytes / 10 ** 8) / 10}GB`;
};

export default DownloadModal
