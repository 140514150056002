import DCAImage from 'components/DCAImage'
import { extractAnchorsFromText } from 'config/libs/helpers'
import { Container, Row } from 'react-bootstrap'
import { ImageHeadlineTextType } from 'types'

type ImageHeadlineTextProps = {
  layoutData: ImageHeadlineTextType
}

const ImageHeadlineText = ({ layoutData }: ImageHeadlineTextProps) => {
  const { image, headline, text } = layoutData

  const textWithAnchors = extractAnchorsFromText(text ?? '')

  return (
    <Container className='image-headline-text-wrapper pt-4'>
      <Row>
        {image && <DCAImage src={image} type='lazyImage' className='w-100' />}
        {headline && (
          <h5
            dangerouslySetInnerHTML={{
              __html: headline.toUpperCase(),
            }}
          />
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: textWithAnchors,
          }}
        />
      </Row>
    </Container>
  )
}

export default ImageHeadlineText
