import { VideoItemType } from 'types'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const toVideoLink = (id: number) =>
  `https://mycliplister.com/static/html/kaercherAppLandingPage.html?keytype=500&rk=${id}&lang=fr`

export const getVideos = (videos: VideoItemType[], translations: TranslationsPDFType) => {
  if (videos.length === 0) {
    return ''
  }

  return `
  <div class="video-sec">
    <h4>${tr(translations, 'videos', 'Videos')}</h4>
    <div class="line"></div>
    <div class="video-list">
      ${videos
        .map((video, index) => {
          return `
        <div style="display: flex;">
          <div style="display: flex; align-items: center; flex-direction: column">
            <div style="width: 125px; height: 125px;" class="qr-code-url" id="${toVideoLink(
              video.id,
            )}"></div>
            <a 
              href="${toVideoLink(video.id)}" 
              class="small"
              style="padding-top: 10px"
            >
              ${tr(translations, 'videos', 'Videos')} ${index + 1}
            </a>
          </div>
        </div>

        `
        })
        .join('')}
    </div>
  </div>
  `
}
