import { useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { sortByStringPropertyAscending } from 'config/libs/helpers'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import {
  AccCategoriesType,
  AccessoryItemType,
  CategoryMapType,
  CategoryType,
  DetCategoriesType,
} from 'types'
import HorizontalRule from 'components/HorizontalRule'
import Accessory from './Accessory'

export type FilterStateType = Partial<{
  accessorySelectedCategoryId: number,
  accessorySelectedStandardOptionId: number,
  detergentSelectedCategoryId: number
}>

type AccessoriesPropsType = {
  accessories: AccessoryItemType[]
  accessoryCategories?: AccCategoriesType
  detergentCategories?: DetCategoriesType
  onUpdateFilterValue?: (obj: FilterStateType) => void
  onGoNextPage?: (pageId: number) => void,
  filterValue?: FilterStateType
}


const NUMBER_ACCESSORIES_INIT = 4
const NUMBER_ACCESSORIES_LOAD_MORE = 4


const Accessories = ({
  accessories,
  accessoryCategories,
  detergentCategories,
  filterValue = {},
  onUpdateFilterValue = () => { },
  onGoNextPage = () => { },
}: AccessoriesPropsType) => {
  const translations = useSelector(selectTranslations)
  const showMore = translations.show_more ?? 'Show More'
  const showAll = translations?.show_all ?? 'Show all'
  const allText = translations.all ?? 'All'
  const accessoriesHeadline = translations.accessories ?? 'ACCESSORIES'
  const cleaningAgentsHeadline = translations.cleaning_agents ?? 'CLEANING AGENTS'
  const headline = accessoryCategories ? accessoriesHeadline : cleaningAgentsHeadline
  const isFromAccessory = !!accessoryCategories

  const SECTION_ID = accessoryCategories
    ? PRODUCT_DETAILS_SECTIONS.ACCESSORIES
    : PRODUCT_DETAILS_SECTIONS.DETERGENTS
    const INIT_CATEGORY_FILTER = {
      id: 0,
      title: translations.all ?? 'All',
    }
  const STANDARD_OPTIONAL_DATA: CategoryType[] = [
    {
      id: 1,
      title: translations.standard_optional_accessories ?? 'Standard/Optional',
    },
    {
      id: 2,
      title: translations.standard_accessories ?? 'Standard',
    },
    {
      id: 3,
      title: translations.optional_accessories ?? 'Optional',
    },
  ]

  const [displayedAccessories, setDisplayedAccessories] = useState<AccessoryItemType[]>(accessories)
  const [shownAccessories, setShownAccessories] = useState<AccessoryItemType[]>(
    accessories.slice(0, NUMBER_ACCESSORIES_INIT),
  )

  const [categories, setCategories] = useState<CategoryType[]>([])
  const [categoriesMap, setCategoriesMap] = useState<CategoryMapType>({})

  const selectedCategoryId = isFromAccessory ? filterValue.accessorySelectedCategoryId : filterValue.detergentSelectedCategoryId
  const selectedCategories = categories.find((category) => category.id === selectedCategoryId) || INIT_CATEGORY_FILTER
  const selectedStandardOption = isFromAccessory
    ? (STANDARD_OPTIONAL_DATA.find((filter) => filter.id === filterValue.accessorySelectedStandardOptionId) || STANDARD_OPTIONAL_DATA[0])
    : STANDARD_OPTIONAL_DATA[0]


  useEffect(() => {
    let categoriesSort: CategoryType[] = []

    if (isFromAccessory) {
      categoriesSort = sortByStringPropertyAscending<CategoryType>(
        accessoryCategories.accCategories,
        'title',
      )
      setCategoriesMap(accessoryCategories.map)
    } else if (detergentCategories) {
      categoriesSort = sortByStringPropertyAscending<CategoryType>(
        detergentCategories.detCategories,
        'title',
      )
      setCategoriesMap(detergentCategories.map)
    }

    setCategories(categoriesSort)
  }, [])

  /** Handle filter data accessories */
  useEffect(() => {
    let filterAccessories: AccessoryItemType[] = accessories
    const isStandard = selectedStandardOption.title === STANDARD_OPTIONAL_DATA[1].title
    const isOptional = selectedStandardOption.title === STANDARD_OPTIONAL_DATA[2].title
    if (selectedCategories.id !== 0) {
      const productIdsForFilter = categoriesMap[selectedCategories.id]
      filterAccessories = filterAccessories.filter((accessory) =>
        productIdsForFilter.includes(accessory.id),
      )
    }

    if (isStandard) {
      filterAccessories = filterAccessories.filter((accessory) => accessory.isStandardAccessory)
    }

    if (isOptional) {
      filterAccessories = filterAccessories.filter((accessory) => !accessory.isStandardAccessory)
    }

    setDisplayedAccessories(filterAccessories)
    setShownAccessories(filterAccessories.slice(0, NUMBER_ACCESSORIES_INIT))
  }, [selectedCategories.id, selectedStandardOption.id])

  if (!accessories.length) return null

  const onShowMore = () => {
    const lastIndex = shownAccessories.length - 1
    const nextIndex =
      displayedAccessories.findIndex((p) => p.id === shownAccessories[lastIndex].id) + 1

    setShownAccessories(
      shownAccessories.concat(
        displayedAccessories.slice(nextIndex, nextIndex + NUMBER_ACCESSORIES_LOAD_MORE),
      ),
    )
  }

  const onShowAll = () => {
    setShownAccessories(displayedAccessories)
  }


  const onFilterCategories = (eventKey: string | null) => {
    if (!eventKey) return

    const activeCategoryId = parseInt(eventKey)

    if (isFromAccessory) {
      onUpdateFilterValue({ accessorySelectedCategoryId: activeCategoryId })
    } else {
      onUpdateFilterValue({ detergentSelectedCategoryId: activeCategoryId })
    }
  }

  const onFilterStandardOptional = (eventKey: string | null) => {
    if (!eventKey) return

    const activeFilterStandardOptional = STANDARD_OPTIONAL_DATA.find(
      (filter) => filter.title === eventKey,
    )

    if (activeFilterStandardOptional) {
      onUpdateFilterValue({ accessorySelectedStandardOptionId: activeFilterStandardOptional.id })
    }
  }

  const renderFilterCategories = () => {
    return (
      <Dropdown className='accessories-dropdown' onSelect={onFilterCategories}>
        <Dropdown.Toggle className='w-100'>{selectedCategories.title}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key={0} eventKey='0'>
            {allText}
          </Dropdown.Item>
          {categories.map((category) => (
            <Dropdown.Item key={category.id} eventKey={category.id.toString()}>
              {category.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const renderFilterStandardOptional = () => {
    if (detergentCategories) return null

    return (
      <Dropdown className='accessories-dropdown' onSelect={onFilterStandardOptional}>
        <Dropdown.Toggle className='w-100'>{selectedStandardOption.title}</Dropdown.Toggle>
        <Dropdown.Menu>
          {STANDARD_OPTIONAL_DATA.map((filter) => (
            <Dropdown.Item key={filter.id} eventKey={filter.title}>
              {filter.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const renderAccessories = () => {
    return (
      <Row>
        {shownAccessories.map((accessory) => {
          return (
            <Col md='3' key={accessory.id} className='mt-3'>
              <Accessory accessory={accessory} onGoNextPage={onGoNextPage} />
            </Col>
          )
        })}
      </Row>
    )
  }

  const renderButtonLoadMore = () => {

    if (shownAccessories.length >= displayedAccessories.length) return null

    return (
      <div>
        <Button variant='primary' className='btn-load-more' onClick={onShowMore}>
          {showMore} ({NUMBER_ACCESSORIES_LOAD_MORE}/{displayedAccessories.length - shownAccessories.length})
        </Button>

        <Button variant='primary' className='btn-load-more ms-2' onClick={onShowAll}>
          {showAll} ({displayedAccessories.length - shownAccessories.length})
        </Button>
      </div>

    )
  }

  return (
    <Container className='accessories-wrapper pt-4' id={SECTION_ID}>
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>

      <HorizontalRule />

      {renderFilterCategories()}

      {renderFilterStandardOptional()}

      {renderAccessories()}

      {renderButtonLoadMore()}
    </Container>
  )
}

export default Accessories
