import { toast } from 'react-toastify'
import { LAYOUTS } from 'config/constants'
import { printDocument } from '../pdfHelper'
import { getDefaultHTML, horizontalRule } from '../pdfLayouts'
import { getManualContentHTML } from './getManualContentHTML'
import { getProductDetailHTML } from './getProductDetailHTML'
import { getProductGroupListHTML } from './getProductGroupListHTML'
import { getProductListHTML } from './getProductListHTML'
import { Config } from './type'

type PDFInfoType = {
  type: string
  pageId: number
}

const toCombinedPDFHTML = async (data: PDFInfoType, config: Config) => {
  try {
    switch (data.type) {
      case LAYOUTS.PRODUCT_DETAILS:
        return await getProductDetailHTML(data.pageId, config)
      case LAYOUTS.PRODUCT_LIST:
        return await getProductListHTML(data.pageId, config)
      case LAYOUTS.PRODUCT_GROUPS_LIST:
        return await getProductGroupListHTML(data.pageId, config)
      case LAYOUTS.MANUAL_CONTENT:
        return await getManualContentHTML(data.pageId, config)
      default:
        return ''
    }
  } catch (error) {
    return ''
  }
}

export const createCombinedPDF = async (data: PDFInfoType[], config: Config) => {
  const toastId = toast('Loading PDF...', { autoClose: false })

  const htmlDOM = getDefaultHTML({ pageStyles: '', title: '' }, false)
  const htmlArr = await Promise.all(data.map((item) => toCombinedPDFHTML(item, config)))

  const contentHTML = htmlDOM.getElementById('main')!

  for (let i = 0; i < htmlArr.length; i++) {
    let content = ''
    if (i === 0) {
      content += '<div>'
    } else {
      content += '<div style="margin-top: 30px;">'
    }

    content += htmlArr[i]

    if (i < htmlArr.length - 1) {
      content += horizontalRule()
    }
    content += '</div>'

    contentHTML.innerHTML += content
  }
  toast.dismiss(toastId)

  printDocument(htmlDOM)
}
