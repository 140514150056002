import { LAYOUTS } from 'config/constants';
import { getProductGroupDbById, getSubProductGroupDbByIds } from 'database/queries/product';
import useDataFetcher from 'hooks/useDataFetcher';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePageContent } from 'redux/hooks/pageContent'
import { useSelector } from 'redux/hooks/useSelector';
import { getProductGroupProductFinder } from 'redux/reducers/productFinderReducer';
import { selectProductFinderLoading, selectProductGroupHasProductFinder } from 'redux/selector';
import { getProductGroupQuery, getSubProductGroupQuery } from 'services';
import { ProductGroupItemType, ProductGroupResponseType, SubProductGroupItemType, SubProductGroupResponseType } from 'types';
import HeaderPDF from '../components/HeaderPDF';
import DividerPDF from '../components/DividerPDF';
import ProductGroupListUI from 'features/Product/screens/ProductGroupList/ProductGroupList.ui';

type ProductGroupListPDFProps = {
  pageId: number
  onDownloadStatus: (status: boolean) => void
}
const ProductGroupListPDF = ({ pageId, onDownloadStatus }: ProductGroupListPDFProps) => {
  const dispatch = useDispatch()
  const { data, loading, error: errorPage } = usePageContent(pageId);
  const { fetchData } = useDataFetcher()
  const isGroupHasProductFinder: boolean = useSelector((state) =>
    selectProductGroupHasProductFinder(state, pageId),
  )
  const loadingProductFinder = useSelector(selectProductFinderLoading)
  const [productGroups, setProductGroups] = useState<ProductGroupItemType[]>()
  const [subProductGroups, setSubProductGroups] = useState<SubProductGroupItemType[]>()
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getProductGroupProductFinder(pageId))
  }, [])

  useEffect(() => {
    const handleSubProductGroup = async (productGroupsFilter: ProductGroupItemType[]) => {
      const productGroupIds = productGroupsFilter.map((item) => parseInt(item.id))
      const subProductGroup = await fetchData<number[], SubProductGroupResponseType>(
        getSubProductGroupQuery,
        getSubProductGroupDbByIds,
        productGroupIds,
        productGroupIds,
      )
      if (!subProductGroup) return

      setSubProductGroups(subProductGroup.data)
    }

    const handleProductGroup = async () => {
      try {
        if (data?.pageId === undefined) return

        const productGroupListObj = data.content.find(
          (item) => item.type === LAYOUTS.PRODUCT_GROUPS_LIST,
        )

        if (!productGroupListObj) return

        const productGroupConfig = JSON.parse(productGroupListObj.config || '{}')

        if (!productGroupConfig.productgroupId) return

        const productGroupsResponse = await fetchData<number, ProductGroupResponseType>(
          getProductGroupQuery,
          getProductGroupDbById,
          productGroupConfig.productgroupId,
          productGroupConfig.productgroupId,
        )

        if (!productGroupsResponse) return

        const productGroupsFilter = productGroupsResponse?.data?.filter((item) => {
          const [headerText] = item.texts

          return headerText
        })

        setProductGroups(productGroupsFilter)

        // Handle sub product group
        await handleSubProductGroup(productGroupsFilter)

      } catch (e) {
        setIsError(true)
      }
    }

    if (!loading && data?.pageId !== undefined) {
      handleProductGroup()
    }
  }, [data, loading])

  const hasDataLoaded = data?.pageId !== undefined && productGroups && subProductGroups && !loadingProductFinder && !isError

  if (hasDataLoaded) {
    onDownloadStatus(true)
  }

  if (isError || errorPage) {
    onDownloadStatus(false)
  }

  if (hasDataLoaded) {
    return (
      <div className='group-list-container px-0'>
        <HeaderPDF />
        <DividerPDF />
        <ProductGroupListUI
          data={data}
          productGroups={productGroups}
          subProductGroups={subProductGroups}
          isGroupHasProductFinder={isGroupHasProductFinder}
          isDropdownAvailable={false}
          dropdownData={[]}
          onGoNexPage={() => { }}
        />
      </div>
    )
  }
  return null
}


export default ProductGroupListPDF