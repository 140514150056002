import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import './pageStyles.scss'

type PageProps = {
  children?: React.ReactNode
  pageId: number
  /**
   * Determines whether padding-top should be applied to the page content.
   * This should be set to false when the children have sticky components like Ribbons or Anchors.
   * These components need to apply padding themselves, because sticky positioning does not work as expected
   * if the wrapper does not have a defined height initially.
   */
  shouldPaddingTop?: boolean
  targetRef?: React.RefObject<HTMLDivElement>
  className?: string
}

const Page = ({ children, pageId, shouldPaddingTop = true, targetRef, className }: PageProps) => {
  const location = useLocation()
  const titleTxt = location.state?.staticTitle ?? ''

  const headerHeight = useHeaderHeight()

  return (
    <Container fluid className={`page-container ${className}`} ref={targetRef}>
      <Row className='page-header' id='page-header'>
        <Col sm={12} className='page-header-item'>
          <Header pageId={pageId} />
        </Col>
        <Col sm={12} className='page-header-item'>
          <Breadcrumb pageId={pageId} staticTitle={titleTxt} />
        </Col>
      </Row>
      <Row className='page-content' style={shouldPaddingTop ? { paddingTop: headerHeight } : {}}>
        {children}
      </Row>
    </Container>
  )
}

export default Page
