import { useEffect, useState } from 'react'
import Migration from 'features/Common/screens/Migration'

type MigrationProps = {
  children: React.ReactNode
}

const NEW_WEB_DB_FIRST_VERSION = 40

const MigrationDB = ({ children }: MigrationProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [isNeedMigration, setIsNeedMigration] = useState(false)

  useEffect(() => {
    const init = async () => {
      const isMigrated = localStorage.getItem('dbMigration')
      const dbs = await indexedDB.databases()

      if (!isMigrated && dbs.length > 0) {
        const firstDBVer = dbs[0].version ?? 0
        if (firstDBVer < NEW_WEB_DB_FIRST_VERSION) {
          setIsNeedMigration(true)
        }
      } else {
        setIsNeedMigration(false)
        localStorage.setItem('dbMigration', 'true')
      }

      setIsLoading(false)
    }

    init()
  }, [])

  if (isLoading) {
    return <></>
  }

  return isNeedMigration ? <Migration /> : <>{children}</>
}

export default MigrationDB
