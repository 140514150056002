import { IconItemType } from 'types'

const getIcons = (icons: IconItemType[]): string => {
  if (!icons || !icons.length) {
    return ''
  }

  const imgStr = icons
    .map((_, index) => `<img class="image-small" src="${icons[index].url}" alt="">`)
    .join('')

  return `<div class="row icon-sec" style="flex-wrap: wrap;">
    ${imgStr}
  </div>`
}

export default getIcons
