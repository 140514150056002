import { ProductDetailItemType } from 'types'
import { tr } from '../pdfHelper'

export const getImageSection = (
  products: ProductDetailItemType[],
  translations: Record<string, string>,
) => {
  const images = products.map(
    (product) => product?.images?.[0]?.urls?.find((item) => item.type === 'full')?.url,
  )
  let str = ''
  
  images.forEach((image, index) => {
    const product = products[index]
    str += `
      <div class="compare-col">
        <img src="${image}">
        <span class="bold">${product.name}</span>
        <span class="bold">
          ${tr(translations, 'order_number', 'Order Number')}: ${product.partnumberFormatted}
        </span>
        <span class="bold">
          ${tr(translations, 'ean', 'EAN')}: ${product.ean}
        </span>
      </div>
    `
  })

  str = `
    <div class="compare-row">
      <div class="compare-col"></div>
      ${str}
    </div>
  `
  return str
}

