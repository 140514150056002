import useDataFetcher from 'hooks/useDataFetcher'
import { ProductGroupDataType, ProductListItemType, ProductListResponseType } from 'types';
import { useEffect, useState } from 'react';
import { getProductListQuery } from 'services';
import { getProductListDbById } from 'database/queries/product';

const useProductList = (pageId: number) => {
  const { fetchData } = useDataFetcher()
  const [productList, setProductList] = useState<ProductListItemType[]>()
  const [productGroup, setProductGroup] = useState<ProductGroupDataType>()
  const [contentError, setError] = useState(false)
  const [contentLoading, setContentLoading] = useState(false);

  const handleProductListData = async () => {
    try {
      const productListResponse = await fetchData<number, ProductListResponseType>(
        getProductListQuery,
        getProductListDbById,
        pageId,
        pageId,
      )

      if (productListResponse) {
        setProductList(productListResponse?.data ?? [])

        const productGroupData: ProductGroupDataType = JSON.parse(
          productListResponse.productGroupData ?? '',
        )
        productGroupData && setProductGroup(productGroupData)
      }
    } catch (e) {
      setError(true)
    } finally {
      setContentLoading(false)
    }
  }

  useEffect(() => {
    handleProductListData()
  }, [])

  return {
    productList,
    productGroup,
    error: contentError,
    loading: contentLoading,
  }
}

export {
  useProductList
}