import { gql } from 'apollo-boost'

export const GET_COUNTRIES = (year: number) => gql`
  {
    getCountryListByYear(input: { year: ${year} }) {
      export
      country
      year
      createdAt
      language
      data {
        name
        code
        languages {
          languageCode
          languageName
        }
      }
    }
  }
`
