import React from 'react'
import { SubProductGroupItemType } from 'types'

interface SubProductGroupProps {
  subProducts: SubProductGroupItemType[]
  onGoNextPage: (pageId: number) => void
}

const SubProductGroup = ({
  subProducts,
  onGoNextPage,
}: SubProductGroupProps): React.ReactElement => {
  const renderItem = () => {
    if (!subProducts) return []

    return subProducts.map((item) => (
      <li role='button' tabIndex={0} onClick={() => onGoNextPage(parseInt(item.id))} key={item.id}>
        {item.title}
      </li>
    ))
  }

  return <ul className='ak-ul'>{renderItem()}</ul>
}

export default SubProductGroup
