import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import 'styles/modal/confirmModal.scss'

export type ConfirmModalProps = {
  showModal: boolean
  message: string
  buttonHeading?: string
  handleClose: () => void
  handleSave?: () => void
}

const ConfirmModal = (props: ConfirmModalProps): React.ReactElement => {
  const { handleClose, handleSave, message, buttonHeading, showModal } = props
  const translations = useSelector(selectTranslations)

  const warningText = translations.warning ?? 'Warning'
  const cancelText = translations.cancel ?? 'Cancel'
  const okText = translations.ok ?? 'OK'
  const deleteText = translations.delete ?? 'DELETE'

  const handleCancel = () => {
    handleClose()
  }

  const handleOk = () => {
    if (handleSave) {
      handleSave()
    }
    handleClose()
  }

  const buttonText = buttonHeading ? deleteText : okText

  return (
    <Modal show={showModal} onHide={handleClose} size='sm' className='confirm-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='confirm-modal-heading'>{warningText}!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        {handleSave && (
          <div className='modal-buttons p-3'>
            <Button variant='secondary' className='text-uppercase' onClick={handleCancel}>
              {cancelText}
            </Button>
            <Button variant='primary' className='text-uppercase' onClick={handleOk}>
              {buttonText}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
