import { getProductCompareDb, saveProductToCompareDb } from 'database/queries/comparison'
import { useDispatch, useSelector } from 'react-redux'
import {
  AccessoryItemType,
  ProductDetailItemType,
  ProductListItemType,
  ProductListRootLineItemType,
} from 'types'
import { saveProductToCompare } from 'redux/reducers/comparisonReducer'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { showAlert } from 'config/utils/CommonFunction'


export const useProductComparison = () => {
  const dispatch = useDispatch()
  const { showModal, hideModal } = useGlobalModalContext()

  const productCompare = useSelector(selectProductComparison)

  const onLoadProductCompare = async () => {
    try {
      const products = await getProductCompareDb()
      dispatch(saveProductToCompare(products))
    } catch (error) {
      console.error('Error loading product comparison:', error)
    }
  }

  const onAddOrRemoveProductCompare = async (
    productData:
      | ProductListItemType
      | ProductDetailItemType
      | ProductListRootLineItemType
      | AccessoryItemType,
  ) => {
    let newProductCompare = [...productCompare]
    const isProductExisted = productCompare.find((product) => product.id === productData.id)

    if (isProductExisted) {
      newProductCompare = newProductCompare.filter((product) => product.id !== productData.id)
    } else {
      if (productCompare.length >= 4) {
        return showAlert('You can only compare 4 products at a time')
      }
      const activeProduct = {
        id: Number(productData.id),
      }
      newProductCompare.push(activeProduct)
      showModal('COMPARE_MODAL', {
        showModal: true,
        productData: productData,
        handleClose: hideModal,
      })
    }

    try {
      await saveProductToCompareDb(newProductCompare)
      dispatch(saveProductToCompare(newProductCompare))
    } catch (error) {
      console.error('Error saving product comparison:', error)
    }
  }

  return {
    onAddOrRemoveProductCompare,
    onLoadProductCompare,
  }
}
