import { ImageHeadlineTextFiftyFiftyType } from '../../../types/manualContentType'
import { formatAnchorTag } from '../pdfHelper'

const getImageWrap = (imageUrl?: string) => `
  <div class="img-wrap">
    <img src="${imageUrl}" alt="" srcset="" />
  </div>
`

export const getImageHeadlineTextFiftyFifty = (headlines: ImageHeadlineTextFiftyFiftyType[]) => {
  let str = ''
  headlines.forEach((item) => {
    const headlineStr = item.headline ? `<h3>${item.headline}</h3>` : ''
    const descStr = item.text ? formatAnchorTag(item.text) : ''
    if (!item.imageRight) {
      str += getImageWrap(item.image)
    }
    const textStyle = item.imageRight ? 'padding-right: 20px;' : 'padding-left: 20px;'
    str += `
      <div class="desc-wrap" style="${textStyle}">
        ${headlineStr}
        ${descStr}
      </div>
    `

    if (item.imageRight) {
      str += getImageWrap(item.image)
    }
  })

  str = `
      <div class="img-headline-fifty-fifty">
      ${str}
      </div>
    `
  return str
}
