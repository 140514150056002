import { TRANSLATIONS } from './translations'

export const dbCurrentEntries = {
  COUNTRIES: 'Countries',
  CURRENT_LANGUAGE: 'CurrentLanguage',
  CURRENT_COUNTRY: 'CurrentCountry',
  TRANSLATIONS: 'Translations',
  STRUCTURE: 'Structure',
  BUSINESS_HEADLINE: 'businessHeadline',
  VERSIONS: 'Versions',
  TYPE: 'downloadType',
  DOWNLOAD_TIME: 'downloadTime',
  AK_CACHE_IMAGES_TIME: 'AK_CACHE_IMAGES_TIME',
}

export const PRODUCT_DETAILS_SECTIONS = {
  ANCHOR: 'anchor',
  RIBBON: 'ribbon',
  DESCRIPTION: 'description',
  TECH_DATA: 'techdata',
  EQUIPMENT: 'equipment',
  ICONS: 'icons',
  IMAGES: 'images',
  TEXTS: 'texts',
  ROOT_LINE: 'rootline',
  FEATURE_BENEFITS: 'featureBenefits',
  DOCUMENTS: 'documents',
  VIDEOS: 'videos',
  ACCESSORIES: 'accessories',
  DETERGENTS: 'detergents',
  CLEANING_AGENTS: 'detergents',
  DETERGENT_WARNINGS: 'detergentWarnings',
  APPLICATIONS: 'applications',
  COMPATIBLE_MACHINES: 'compatibleProducts',
  CONFIGURABLE_COMPONENT: 'configurableComponents',
}

export const COMMON = {
  PAST_PRODUCT: 'pastproduct',
}

export const MY_FAVORITES_FOLDER = {
  id: 2,
  parentID: 1,
  folderName: TRANSLATIONS.myFavorites,
}

export const ALL_FOLDER = {
  id: 1,
  parentID: 0,
  folderName: TRANSLATIONS.all,
}
