import { formatAnchorTag } from '../pdfHelper'

export const getTextContent = (contents: { text: string }[]) => {
  let str = ''

  contents.forEach((item) => {
    str += `<div class="text-content">
      ${formatAnchorTag(item.text)}
    </div>`
  })

  return str
}
