import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FavoriteContentType } from 'types'
import { ALL_FOLDER } from 'config/constants'
import { selectAppOnline, selectStructure, selectTranslations } from 'redux/selector'
import { selectFavoritesContent, selectSelectedFolderId } from 'redux/selector/favoritesSelector'
import useNavigation from 'hooks/useNavigation'
import { FavoriteItem } from 'features/Favorites/components/FavoriteItem'
import { createCombinedPDF } from 'features/NewPDF/CombinedPDF/CombinedPDF'
import { useFavorites } from '../hooks/useFavorites'

export const FavoritesList = () => {
  const favoriteContent = useSelector(selectFavoritesContent)
  const selectedFolderId = useSelector(selectSelectedFolderId)
  const translations = useSelector(selectTranslations)
  const structure = useSelector(selectStructure) || []
  const isAppOnline = useSelector(selectAppOnline)

  const { updatedFavorites } = useFavorites()
  const { onDynamicNavigate } = useNavigation()

  const [favorites, setFavorites] = useState<FavoriteContentType[] | undefined>()
  /**
   * Filter the favorites list based on the selected folder
   */
  useEffect(() => {
    if (Array.isArray(favoriteContent)) {
      let favorite
      if (selectedFolderId === ALL_FOLDER.id) {
        favorite = [...favoriteContent]
      } else {
        favorite = favoriteContent.filter((favorite) => favorite.folderID === selectedFolderId)
      }
      setFavorites(favorite)
    }
  }, [favoriteContent, selectedFolderId])

  /**
   * Moves an item from one position to another within the favorites list
   * @param dragIndex The index of the item being dragged
   * @param hoverIndex The index of the item being hovered over
   */
  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setFavorites((prevFavorites: FavoriteContentType[] | undefined) => {
      if (!prevFavorites) return prevFavorites

      const updatedFavorites = update(prevFavorites, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevFavorites[dragIndex] as FavoriteContentType],
        ],
      })

      // Update the position property of each item based on its new index
      const updatedFavoritesWithPositions = updatedFavorites.map((item, index) => ({
        ...item,
        position: index + 1,
      }))

      return updatedFavoritesWithPositions
    })
  }, [])

  /**
   * Updates the favorites list in the database with the new order
   */
  const moveItemFinish = useCallback(() => {
    if (!favorites) return
    updatedFavorites(favorites)
  }, [favorites])

  const onGoNextPage = (nextPageId: number) => onDynamicNavigate(nextPageId)

  const createPDF = (data: FavoriteContentType) => {
    createCombinedPDF([{ type: data.type, pageId: data.pageId }], {
      isAppOnline,
      translations,
      structure,
    })
  }

  const renderItem = useCallback(
    (item: FavoriteContentType, index: number) => {
      return (
        <FavoriteItem
          key={item.id}
          id={item.id}
          index={index}
          data={item}
          moveItem={moveItem}
          moveItemFinish={moveItemFinish}
          onGoNextPage={onGoNextPage}
          onSavePDF={createPDF}
        />
      )
    },
    [moveItem],
  )

  if (!favorites || favorites.length === 0) return null

  const favoriteContentSort = favorites.sort((a, b) => a.position - b.position)
  return (
    <div className='favorites-list'>
      {favoriteContentSort.map((favorite, i) => renderItem(favorite, i))}
    </div>
  )
}
