import { ReactElement, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { LAYOUTS } from 'config/constants'
import { PageContentResponseType, ProductGroupItemType } from 'types'
import DCAImage from 'components/DCAImage'
import ButtonScrollToTop from 'components/ButtonScrollToTop'
import ImageGrid from 'features/ManualContent/components/ImageGrid'
import ImageHeadlineThreeColumns from 'features/ManualContent/components/ImageHeadlineThreeColumns'
import useOnboardingTour from 'features/Onboarding/hooks/useOnboarding'
import 'styles/pages/productGroupOverview.scss'

type UIProps = {
  data: PageContentResponseType | object
  productGroups: ProductGroupItemType[] | undefined
  onGoNext: (pageId: number) => void
}

type ProductGroupsProps = {
  productGroups: ProductGroupItemType[] | undefined
  onGoNext: (pageId: number) => void
}

const ProductGroups = ({ productGroups = [], onGoNext }: ProductGroupsProps) => {
  if (!productGroups) return <div />

  return (
    <Container className='product-groups-wrapper py-4' fluid>
      <Container>
        <Row xs={1} sm={2} md={4} className='g-4'>
          {productGroups.map((item: ProductGroupItemType) => {
            const { name, texts } = item
            const productGroupImage = texts.find((text) => text.type === 'group')
            const productGroupImageSrc = productGroupImage?.images?.find(
              (image) => image.type === 'medium',
            )

            return (
              <Col key={item.id}>
                <Card className='card-wrapper cursor-pointer' onClick={() => onGoNext(parseInt(item.id))}>
                  {productGroupImageSrc && (
                    <div className='card-image-wrapper'>
                      <DCAImage type='lazyImage' variant='top' src={productGroupImageSrc.url} />
                    </div>
                  )}
                  <p className='center-p'>{name}</p>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Container>
  )
}

const ProductGroupOverviewUI = ({
  data = {},
  productGroups = [],
  onGoNext,
}: UIProps): ReactElement => {
  const { isFirstTimeOnboarding, runOnboardingTour } = useOnboardingTour()

  useEffect(() => {
    const autoRunOnboarding = async () => {
      const isRunOnboarding = await isFirstTimeOnboarding()
      if (isRunOnboarding) {
        runOnboardingTour()
      }
    }

    autoRunOnboarding()
  }, [])

  if (!('content' in data)) return <></>

  const { content = [] } = data

  const renderContent = () => {
    return content.map((item) => {
      if (item.type === LAYOUTS.PRODUCT_GROUPS_OVERVIEW) {
        return <ProductGroups key={item.id} productGroups={productGroups} onGoNext={onGoNext} />
      }

      if (item.type === LAYOUTS.MANUAL_CONTENT) {
        const layoutConfig = JSON.parse(item.config)
        const { layout, values } = layoutConfig

        switch (layout) {
          case LAYOUTS.IMAGE_GRID: {
            const layoutData = values[0]

            return <ImageGrid key={item.id} layoutData={layoutData} />
          }
          case LAYOUTS.IMAGE_HEADLINE_TEXT_THREE_COL: {
            const layoutData = values[0]

            return <ImageHeadlineThreeColumns key={item.id} layoutData={layoutData} />
          }

          default:
            return null
        }
      }

      return null
    })
  }

  const renderFooter = () => {
    return (
      <div className='footer'>
        <ButtonScrollToTop />
      </div>
    )
  }

  return (
    <>
      {renderContent()}

      {renderFooter()}
    </>
  )
}

export default ProductGroupOverviewUI
