import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { TextItemType } from 'types'

function Texts({ texts }: { texts: TextItemType[] }) {
  const [isShowFull, setIsShowFull] = useState<boolean>(false)

  const toggleShow = () => setIsShowFull(!isShowFull)

  const renderText = (text: TextItemType) => {
    let { value } = text
    if (!isShowFull) {
      value = text.value.substring(0, 500)
    }
    if (text.type === 'long') {
      const key = Math.random().toString(36)
      const markup = { __html: ` ${value} ` }
      const textShowFull = isShowFull ? (
        <span onClick={toggleShow}>
          <FontAwesomeIcon className='cursor-pointer' icon={faAngleUp} />
        </span>
      ) : (
        <span className='cursor-pointer' onClick={toggleShow}>
          ... <FontAwesomeIcon icon={faAngleDown} />
        </span>
      )
      return (
        <div key={key}>
          <div className='product-long-desc mt-2' dangerouslySetInnerHTML={markup} />
          {textShowFull}
        </div>
      )
    }
    return null
  }

  return <div>{texts.map((text) => renderText(text))}</div>
}

export default Texts
