import { getOnboardingDb, saveOnboardingDb, updateOnboardingDb } from 'database/queries/onboarding'
import getOnboardingQuery from 'services/onboarding'
import { useSelector } from 'react-redux'
import { selectAppOnline } from 'redux/selector'
import { useAppContext } from 'context/AppContext'

export function useOnboardingTour() {
  const isAppOnline = useSelector(selectAppOnline)
  const { setState } = useAppContext()

  /**
   * Loads onboarding data from the API or database, depending on whether the app is online.
   * If the app is online, the data is fetched from the API and saved to the database.
   */
  const loadOnboardingData = async () => {
    if (!isAppOnline) {
      // If the app is offline, get the data from the database
      const dbData = await getOnboardingDb()
      if (dbData) {
        setState({ onboardingData: dbData.data })
      }
    } else {
      // If the app is online, get the data from the API and save it to the database
      const apiData = await getOnboardingQuery()
      if (apiData) {
        setState({ onboardingData: apiData })
        const dbData = await getOnboardingDb()
        // If the data doesn't exist in the database, set isFirstTime to true
        if (!dbData) {
          const onboardingData = { id: 1, isFirstTime: true, data: apiData }
          saveOnboardingDb(onboardingData)
        } else {
          updateOnboardingDb({ ...dbData, data: apiData })
        }
      }
    }
  }

  /**
   * Sets the app state to run the onboarding tour.
   */
  const runOnboardingTour = async () => {
    setState({ run: true, tourActive: true })
    const dbData = await getOnboardingDb()
    if (dbData) {
      updateOnboardingDb({ ...dbData, isFirstTime: false })
    }
  }

  /**
   * Checks whether the user is onboarding for the first time.
   * Returns true if the user is onboarding for the first time, false otherwise.
   */
  const isFirstTimeOnboarding = async () => {
    const dbData = await getOnboardingDb()
    return !!dbData && dbData.isFirstTime
  }

  return { loadOnboardingData, runOnboardingTour, isFirstTimeOnboarding }
}

export default useOnboardingTour
