// abc def xyz  --->   abc_def_xyz
export const replaceSpaceByDash = (str: string): string =>
  (str || '').toLowerCase().replaceAll(' ', '_')

export const replaceSlashByDoubleQuote = (str: string): string => {
  return str.replaceAll('\\"', '"')
}

export const formatSearchTerm = (str: string): string => {
  return str.replaceAll('\\', '').replaceAll('"', '\\"')
}
