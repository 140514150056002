import { gql } from 'apollo-boost'

export const IS_ADOBE_ANALYTICS_AVAILABLE = (countryCode: string, languageCode: string) => gql`
  {
    isAdobeAnalyticsAvailable(input:
    {
      languageCode: "${languageCode}",
      countryCode: "${countryCode}"
    })
  }
`
