import { common } from 'database/db'
import { getTranslationsQuery } from 'services'
import { PayloadAction } from '@reduxjs/toolkit'
import { TranslationResponseType } from 'types'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  getTranslations,
  getTranslationsError,
  getTranslationsSuccess,
} from 'redux/reducers/translationsReducer'
import { selectAppOnline } from 'redux/selector'

function* fetchTranslationsOnline() {
  try {
    const response: TranslationResponseType = yield call(getTranslationsQuery)
    return JSON.parse(response.data)
  } catch (error) {
    throw new Error('Failed to fetch translations from API')
  }
}

function* fetchTranslationsOffline() {
  try {
    const translations: TranslationResponseType[] = yield common.table('dcaTranslations').toArray()
    const translation = translations[0]
    if (typeof translation.data === 'string') {
      return JSON.parse(translation.data)
    } else {
      return translation.data
    }
  } catch (error) {
    throw new Error('Failed to fetch translations from local database')
  }
}

export function* fetchTranslations(
  data?: PayloadAction<{
    resolve: () => void
    reject: () => void
  }>,
) {
  const { resolve, reject } = data?.payload || {}
  try {
    const isOnline: boolean = yield select(selectAppOnline)
    const translations: {
      [key: string]: string
    }[] = isOnline ? yield fetchTranslationsOnline() : yield fetchTranslationsOffline()

    if (translations[0]) {
      yield put(getTranslationsSuccess(translations[0]))
      if (resolve) {
        resolve()
      }
    } else {
      throw new Error('Failed to fetch translations')
    }
  } catch (error) {
    yield put(getTranslationsError())
    if (reject) {
      reject()
    }
  }
}

export default function* translationsSaga() {
  yield takeLatest(getTranslations.type, fetchTranslations)
}
