import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StructureItemType } from 'types'
import { selectStructure } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import BreadcrumbUI from './Breadcrumb.ui'

type Props = {
  pageId: number
  staticTitle?: string
  id?: string
}

const Breadcrumb = (props: Props): React.ReactElement => {
  const { pageId, staticTitle } = props
  const structure = useSelector(selectStructure)
  const { onDynamicNavigate, onGoBack } = useNavigation()

  const [navigation, setNavigation] = useState<StructureItemType[]>([])
  const [activeRouteObj, setActiveRouteObj] = useState<StructureItemType | undefined>(undefined)

  const findActiveRoute = (id: number): StructureItemType | undefined => {
    if (!structure) return undefined
    return structure.find((item) => item.id === id)
  }

  const buildNavigation = (id: number, nav: StructureItemType[] = []) => {
    const route = findActiveRoute(id)
    if (route) {
      nav.unshift(route)
      if (route.parentId) {
        buildNavigation(route.parentId, nav)
      }
    }
    return nav
  }

  useEffect(() => {
    if (structure) {
      const activeRoute = findActiveRoute(pageId)
      if (activeRoute) {
        setActiveRouteObj(activeRoute)
        const nav = buildNavigation(activeRoute.id)
        setNavigation(nav)
      }
    }
  }, [structure, pageId])

  const onGoToPage = (data: StructureItemType) => {
    onDynamicNavigate(data.id)
  }

  return (
    <BreadcrumbUI
      navigationArr={navigation}
      activeRoute={activeRouteObj}
      staticTitle={staticTitle}
      onGoBack={onGoBack}
      onGoToPage={onGoToPage}
    />
  )
}

export default Breadcrumb
