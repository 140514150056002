import { ImageGridType } from 'types'

export const getImageGrid = (data: ImageGridType) => {
  const imageBig = data.imageBig
  const imageSmallTop = data.imageSmallTop
  const imageSmallMiddle = data.imageSmallMiddle
  const imageSmallBottom = data.imageSmallBottom

  const str = `
    <div class="image-grid">
      <div class="image-big">
        <img src="${imageBig}" alt="" srcset="">
        <h1>${data.headline_imageBig}</h1>
      </div>
      <div class="image-small-wrap">
        <div class="image-small">
          <img src="${imageSmallTop}" alt="" srcset="">
          <span>${data.headline_imageSmallTop}</span>
        </div>
        <div class="image-small">
          <img src="${imageSmallMiddle}" alt="" srcset="">
          <span>${data.headline_imageSmallMiddle}</span>
        </div>
        <div class="image-small">
          <img src="${imageSmallBottom}" alt="" srcset="">
          <span>${data.headline_imageSmallBottom}</span>
        </div>
      </div>
    </div>
  `
  return str
}
