import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getAnalyticsAvailableRequest,
  getAnalyticsAvailableSuccess,
  getAnalyticsAvailableError,
} from 'redux/reducers/analyticsReducer'
import isAdobeAnalyticsAvailableQuery from 'services/adobeAnalytics'
import { IsAdobeAnalyticsAvailableResponseType } from 'types/analyticsType'

function* fetchAnalyticsAvailable() {
  try {
    const response: IsAdobeAnalyticsAvailableResponseType = yield call(
      isAdobeAnalyticsAvailableQuery,
    )
    if (response) {
      yield put(getAnalyticsAvailableSuccess(response.isAdobeAnalyticsAvailable))
    } else yield put(getAnalyticsAvailableError())
  } catch (e) {
    yield put(getAnalyticsAvailableError())
  }
}

function* watchGetAnalyticsAvailableRequest() {
  yield takeLatest(getAnalyticsAvailableRequest.type, fetchAnalyticsAvailable)
}

export default function* analyticsSaga() {
  yield all([watchGetAnalyticsAvailableRequest()])
}
