import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ImageGridType } from 'types'
import useNavigation from 'hooks/useNavigation'
import DCAImage from 'components/DCAImage'
import 'styles/components/imageGrid.scss'

interface ImageCollectionProps {
  layoutData: ImageGridType
}

const ImageGrid: React.FC<ImageCollectionProps> = ({ layoutData }) => {
  const { onDynamicNavigate } = useNavigation()

  const openLink = (pageId: number) => {
    if (!pageId) return

    onDynamicNavigate(pageId)
  }

  return (
    <Container className='image-grid py-3 pt-4'>
      <Row>
        <Col className='section-left cursor-pointer' md={8} lg={8}>
          <div className='image-container'>
            <DCAImage src={layoutData.imageBig} alt='business' className='image-big' />
            <div className='top-left'>
              <h1 className='py-3'>{layoutData.headline_imageBig}</h1>
              <Button
                onClick={() => openLink(layoutData.buttonlink_imageBig)}
                className='theme-btn px-3 btn btn-primary cursor-pointer'
              >
                {layoutData.buttontext_imageBig}
              </Button>
            </div>
          </div>
        </Col>
        <Col className='section-right' md={4}>
          <Row>
            <Col sm={4} xl={12} xs={4} md={12} lg={12}>
              <div className='image-container pb-3'>
                <DCAImage
                  className='img-small m-auto cursor-pointer'
                  type='lazyImage'
                  role='button'
                  onClick={() => openLink(layoutData.link_imageSmallTop)}
                  alt='image small top'
                  src={layoutData.imageSmallTop}
                />
                <div className='bottom-left cursor-pointer'>
                  <h5 className='py-2 pl-2'>{layoutData.headline_imageSmallTop}</h5>
                </div>
              </div>
            </Col>
            <Col sm={4} xl={12} xs={4} md={12} lg={12}>
              <div className='image-container'>
                <DCAImage
                  className='img-small m-auto cursor-pointer'
                  type='lazyImage'
                  role='button'
                  alt='image small middle'
                  onClick={() => openLink(layoutData.link_imageSmallMiddle)}
                  src={layoutData.imageSmallMiddle}
                />
                <div className='bottom-left cursor-pointer'>
                  <h5 className='py-2 pl-2'>{layoutData.headline_imageSmallMiddle}</h5>
                </div>
              </div>
            </Col>
            <Col sm={4} xl={12} xs={4} md={12} lg={12}>
              <div className='image-container pt-3'>
                <DCAImage
                  className='img-small m-auto cursor-pointer'
                  type='lazyImage'
                  role='button'
                  alt='image small bottom'
                  onClick={() => openLink(layoutData.link_imageSmallBottom)}
                  src={layoutData.imageSmallBottom}
                />
                <div className='bottom-left cursor-pointer'>
                  <h5 className='py-2 pl-2'>{layoutData.headline_imageSmallBottom}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ImageGrid
