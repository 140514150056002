import { all } from 'redux-saga/effects'
import countriesSaga from './countriesSaga'
import translationsSaga from './translationsSaga'
import pageContentSaga from './pageContentSaga'
import structureSaga from './structureSaga'
import productFinderSaga from './productFinderSaga'
import analyticsSaga from './analyticsSaga'

export default function* rootSaga() {
  yield all([
    countriesSaga(),
    translationsSaga(),
    pageContentSaga(),
    structureSaga(),
    productFinderSaga(),
    analyticsSaga(),
  ])
}
