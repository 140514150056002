import { API_YEAR } from 'config/constants'
import { CountriesResponseType } from 'types'
import { GET_COUNTRIES } from './queries/countryQueries'
import { getGraphQLClient } from 'config/libs/globals'

const getCountriesQuery = async (): Promise<CountriesResponseType> => {
  try {
    const { data } = await getGraphQLClient().query({
      query: GET_COUNTRIES(API_YEAR),
    })

    const countries = data?.getCountryListByYear
    if (!countries) {
      throw new Error('data is undefined')
    }
    return countries as CountriesResponseType
  } catch (error) {
    console.error('Error fetching countries:', error)
    throw new Error('Failed to fetch countries')
  }
}

export default getCountriesQuery
