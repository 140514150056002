import {
  deleteFavContentDb,
  editFavContentDb,
  getFavoritesDb,
  saveFavContentDb,
  saveFavFolderDb,
  updatedFavoritesDb,
} from 'database/queries/favorites'
import { useDispatch, useSelector } from 'react-redux'
import {
  FavoriteContentType,
  FavoriteExtendDataType,
  FavoriteFolderType,
  PageContentResponseType,
} from 'types'
import { ALL_FOLDER, MY_FAVORITES_FOLDER, TRANSLATIONS } from 'config/constants'
import {
  addItemToFavoritesContent,
  addItemToFavoritesFolder,
  loadFavorites,
  updateFavorites,
} from 'redux/reducers/favoritesReducer'
import { selectTranslations } from 'redux/selector'
import { selectFavoritesContent } from 'redux/selector/favoritesSelector'

export const useFavorites = () => {
  const dispatch = useDispatch()

  const translations = useSelector(selectTranslations)
  const favoriteContent = useSelector(selectFavoritesContent)

  /**
   * Load favorites from local DB
   * If favorites exist in local DB, load them to redux store
   * If not, create default folder
   */
  const onLoadFavorites = async () => {
    try {
      const favorites = await getFavoritesDb()
      if (favorites.favoriteFolder && favorites.favoriteContent) {
        if (favorites.favoriteFolder.length > 0) {
          const updatedFavorites = {
            selectedFolderId: MY_FAVORITES_FOLDER.id,
            favoritesFolder: favorites.favoriteFolder,
            favoritesContent: favorites.favoriteContent,
          }
          dispatch(loadFavorites(updatedFavorites))
        } else {
          const FAVORITE_FOLDER_INIT = {
            ...MY_FAVORITES_FOLDER,
            folderName: translations?.my_favorites ?? TRANSLATIONS.myFavorites,
          }
          const ALL_FOLDER_INIT = {
            ...ALL_FOLDER,
            folderName: translations?.all ?? TRANSLATIONS.all,
          }
          await addFolderFavorites(ALL_FOLDER_INIT)
          await addFolderFavorites(FAVORITE_FOLDER_INIT)
        }
      }
    } catch (error) {
      console.error('onLoadFavorites fail', error)
    }
  }

  const updatedFavorites = (favorites: FavoriteContentType[]) => {
    try {
      updatedFavoritesDb(favorites)
      dispatch(updateFavorites(favorites))
    } catch (error) {
      console.error('updatedFavorites fail', error)
    }
  }

  const addItemToFavorites = async (
    pageContent: PageContentResponseType,
    favoriteExtend: FavoriteExtendDataType,
  ) => {
    try {
      const favoriteContent = {
        folderID: favoriteExtend.folderID,
        title: favoriteExtend.title,
        pageId: pageContent.pageId,
        type: pageContent?.type ?? pageContent.content?.[0].type,
        position: favoriteExtend.position,
        country: pageContent.country,
        language: pageContent.language,
        year: pageContent.year,
      }

      const response = await saveFavContentDb(favoriteContent)

      if (response) {
        dispatch(
          addItemToFavoritesContent({
            ...favoriteContent,
            id: Number(response.id),
          }),
        )
      }
    } catch (error) {
      console.error('Add item to favorites fail', error)
    }
  }

  const editItemInFavorites = async (favId: number, favoriteData: FavoriteContentType) => {
    try {
      const response = await editFavContentDb(favId, favoriteData)
      if (response?.success) {
        const favorites = await getFavoritesDb()
        dispatch(updateFavorites(favorites.favoriteContent))
      }
    } catch (error) {
      console.error('editItemInFavorites fail', error)
    }
  }

  const deleteItemInFavorites = async (favId: number) => {
    try {
      await deleteFavContentDb(favId)
      const favoriteData = favoriteContent.filter((item) => item.id !== favId)
      dispatch(updateFavorites(favoriteData))
    } catch (error) {
      console.error('deleteItemInFavorites fail', error)
    }
  }

  const addFolderFavorites = async (folder: FavoriteFolderType) => {
    try {
      const response = await saveFavFolderDb(folder)
      if (response.success) {
        dispatch(addItemToFavoritesFolder(folder))
      }
    } catch (error) {
      console.error('addFolderFavorites fail', error)
    }
  }

  return {
    onLoadFavorites,
    updatedFavorites,
    addItemToFavorites,
    editItemInFavorites,
    deleteItemInFavorites,
    addFolderFavorites,
  }
}
