export default window.digitalData = {
  application: {
    applicationInfo: {
      namespace: 'kaercher_digital_catalog',
      type: 'browser',
      version: '',
      language: '',
      country: '',
      region: '',
      mode: 'online',
    },
  },
  productInfo: {
    sku: '',
    ean: '',
    productName: '',
    productType: '',
  },
  page: {
    pageInfo: {
      pageName: '',
      pageId: 0,
      pageType: '',
      onsiteSearchTerm: '',
      onsiteSearchResult: '',
    },
    category: {
      primaryCategory: '',
      subCategory1: '',
      subCategory2: '',
      productGroupID: '',
    },
  },
  product: {
    category: {
      primaryCategory: '',
      subCategory1: '',
      subCategory2: '',
      subCategory3: '',
      subCategory4: '',
      subCategory5: '',
    },
  },
  event: {
    eventName: [],
  },
  attribute: {
    comparisonProducts: '',
    productFinder: {
      filter: '',
    },
    media: {
      videoName: '',
      videoEvent: '',
      videoMilestone: '',
    },
    favorite: {
      save: '',
      products: '',
    },
    onboarding: {
      step: 0,
    },
  },
  user: {
    profile: {
      profileInfo: {
        login: 'guest',
      },
    },
  },
  link: {
    linkName: '',
  },
}
