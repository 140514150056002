import {
  PageContentResponseType,
  ProductGroupItemType,
  ProductListRootLineItemType,
  StructureItemType,
  SubProductGroupItemType,
} from 'types'
import { splitArrayIntoChunks } from 'config/utils/CommonFunction'
import { getBreadcrumbData, printDocument, tr } from '../pdfHelper'
import { getCustomHTML } from '../pdfLayouts'
import { RibbonData, TranslationsPDFType } from '../types'

export const toProductGroupListHTML = (
  productGroups: ProductGroupItemType[],
  subProductGroups: SubProductGroupItemType[],
) => {
  const imgArr = productGroups.map((product) => product?.texts?.[0]?.images?.[2]?.url ?? '')
  const productChunks = splitArrayIntoChunks(productGroups, 3)
  const imagesChunks = splitArrayIntoChunks(imgArr, 3)
  let str = ''
  productChunks.forEach((rowItem, rowIndex) => {
    str += '<div class="page-break-auto product-group-row">'
    rowItem.forEach((colItem, colIndex) => {
      const layoutId = Number(colItem.id)
      const currentSubProducts = subProductGroups.filter(
        (obj) => obj.parentId.toString() === layoutId.toString(),
      )

      let subProductGroup = ''
      currentSubProducts.forEach((item) => {
        subProductGroup += `<h5>> ${item.title}</h5>`
      })

      if (colItem) {
        const imgUrl = imagesChunks[rowIndex][colIndex]
        str += `
          <div class="product-group-item">
            <img class="product-group-img" src="${imgUrl}" alt="" />
            <h4>${colItem.name}</h4>
            <p>${colItem.texts[1] && colItem.texts[1].text}</p>
            ${subProductGroup}
          </div>
        `
      }
    })
    str += '</div>'
  })

  str = `
    <div class="product-group-list-wrap">
      ${str}
    </div>
  `
  return str
}

export const toFilterProductListHTML = (
  products: ProductListRootLineItemType[],
  translations: {
    [key: string]: string
  },
) => {
  const imgArr = products.map((productData) => productData?.images?.[0]?.urls?.[2]?.url ?? '')

  const productChunks = splitArrayIntoChunks(products, 3)
  const imagesChunks = splitArrayIntoChunks(imgArr, 3)

  let str = ''
  let showPriceRemark = false
  productChunks.forEach((rowItem, rowIndex) => {
    str += '<div class="page-break-auto product-group-row">'
    rowItem.forEach((colItem, colIndex) => {
      if (colItem) {
        const imgUrl = imagesChunks[rowIndex][colIndex]
        str += `
        <div class="product-item">
          <img src="${imgUrl}" alt="" />
          <h4>${colItem.name}</h4>
          <div class="product-group-detail">${tr(translations, 'order_number', 'Order number')} ${
          colItem.partnumberFormatted
        }</div>`

        if (colItem.priceNetFormatted && colItem.priceGrossFormatted) {
          showPriceRemark = true
          str += `<div class="product-group-detail">${tr(
            translations,
            'net_priceNumber',
            'Netto',
          )} ${colItem.priceNetFormatted}</div> <div class="product-group-detail">${tr(
            translations,
            'gross_priceNumber',
            'Gross',
          )} ${colItem.priceGrossFormatted}</div>`
        }
        str += `<p class="product-item-description">${colItem.texts?.[0]?.value}</p>
        </div>`
      }
    })

    str += '</div>'
  })

  const priceMarkHTML = showPriceRemark
    ? `<p style="width: 95%;">${tr(translations, 'price_remark')}</p>`
    : ''

  str = `<div class="product-group-list-wrap">
        ${str}
        ${priceMarkHTML}
        </div>`
  return str
}

export const createProductGroupListPDF = (
  {
    productGroups,
    subProductGroups,
    pageContent,
    structure,
    ribbonData,
    productList = [],
    isFilterProductList,
    translation,
  }: {
    productGroups: ProductGroupItemType[]
    subProductGroups: SubProductGroupItemType[]
    pageContent: PageContentResponseType
    structure: StructureItemType[] | null
    ribbonData: RibbonData
    productList?: ProductListRootLineItemType[]
    isFilterProductList?: boolean
    translation: TranslationsPDFType
  },
  onDone?: () => void,
) => {
  const pageId = Number(pageContent.pageId)
  const breadCrumbData = getBreadcrumbData(pageId, structure)
  const productHTML = isFilterProductList
    ? toFilterProductListHTML(productList, translation)
    : toProductGroupListHTML(
        productGroups.filter((product) => product.parentId.toString() === pageId.toString()),
        subProductGroups,
      )

  const htmlDOM = getCustomHTML({
    ribbonData,
    breadCrumbData,
    pageStyles: productGroupListStyle,
    title: ribbonData?.headline,
  })

  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += productHTML

  printDocument(htmlDOM, onDone)
}

export const productGroupListStyle = `
.ribbon-text {
  margin-top: 20px;
  padding: 20px;
  background-color: #FFED00;
}
.ribbon-text h1 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.product-group-list-wrap {
  width: 100%;
  margin-top: 10px;
}
.product-group-row {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 20px;
}
.product-group-item {
  box-sizing: border-box;
}
.product-group-item h4 {
  font-size: 14px;
}
.product-group-item h5 {
  font-size: 12px;
  font-weight: bold;
  margin: 2px 0;
}
.product-group-item img {
  height: 150px;
  width: 100%;
  object-fit: contain;
}
.product-group-item .product-group-img {
  height: auto;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
}
.product-group-item p {
  margin-bottom: 0;
  font-size: 12px;
}
.product-item-description {
  box-sizing: border-box;
  word-break: break-all;
}
.product-group-detail {
  font-size: 12px;
  font-weight: bold;
}

.product-item h4 {
  font-size: 14px;
}
.product-item h5 {
  font-size: 12px;
  font-weight: bold;
  margin: 2px 0;
}
.product-item img {
  height: 150px;
  width: 100%;
  object-fit: contain;
}
.product-item .product-group-img {
  height: auto;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
}
.product-item p {
  margin-bottom: 0;
  font-size: 12px;
}


`
