import { replaceDatabase, resetOfflineDatabase } from 'database/dbFunctions';
import { deleteAllCompletedDownloads, getCompletedDownloadFiles, updateLatestDownloadFile } from 'database/queries/download';
import { RendererEvent } from 'electron-shared';
import { ASSET_LOCATION, BACKUP_DOCUMENT_LOCATION, DOCUMENT_LOCATION, DOWNLOAD_LOCATION, ZIP_LOCATION } from 'electron-shared/locationName';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { selectTranslations } from 'redux/selector';
import { getDownloadContent, getImageAssetPresignedUrls } from 'services';
import { DownloadTaskData, backgroundDownload, getDownloadData, registerToDownloadEvent, stopAllDownload } from './Downloader';
import { ASSET_KEYS, ASSET_PATCH_SIZE, DOCUMENT_KEYS, DocumentType, DownloadInfo, MAX_IMAGE_SIZE_FOR_PATCH_UPDATE, offlineDownloadReset, overrideDownloadStatus } from './download.reducer';
import { bulkDownloadImages, bulkRemoveFiles, generateInitDownloadData, getAddAndRemoveImageList, getDocumentUrl, isDocumentOutdated } from './download.util';
import { showAlert } from 'config/utils/CommonFunction';

export const useDownload = () => {
  const translations = useSelector(selectTranslations)
  const dispatch = useDispatch()
  const download = async (config: {
    resource: Partial<{
      [key in DocumentType]: boolean;
    }>;
    options?: {
      downloadAnyway?: boolean;
    };
  }): Promise<boolean> => {
    if (!navigator.onLine) {
      showAlert(translations?.user_offline_message || 'Sorry, No internet connection is available. Please try again.')
      return false
    }

    const { resource, options } = config;

    const inProgressTaskIds = getDownloadData()
      .filter(task => task.state === 'DOWNLOADING')
      .map(i => i.id)
    const completedTaskIds = (await getCompletedDownloadFiles()).map(i => i.id)

    const shouldDownloadDocument = (type: DocumentType) =>
      // user choose to download this document
      resource[type] &&
      // no inprogress task for this document
      !inProgressTaskIds.includes(type) &&
      // user choose to download anyway or this document is not downloaded yet
      (options?.downloadAnyway || !completedTaskIds.includes(type));

    const shouldDownloadAsset = (type: DocumentType): boolean | undefined => {
      return (
        resource[type] &&
        !inProgressTaskIds.some((task) => task.includes(type)) &&
        (options?.downloadAnyway || !completedTaskIds.includes(type))
      );
    };

    const dispatchObj: Record<string, null | DownloadInfo> = {}

    // group download task, each group is a list of download task
    const downloadTasks: DownloadTaskData[][] = [[]];

    // document need to download
    const documentsNeedToDownload = DOCUMENT_KEYS.filter((document) =>
      shouldDownloadDocument(document)
    );

    if (documentsNeedToDownload.length > 0) {
      const documentURLs = await getDownloadContent({
        homeAndGarden: true,
        professional: true
      })

      documentsNeedToDownload.forEach((document) => {
        generateInitDownloadData(dispatchObj, document);
        downloadTasks[0].push({
          id: document,
          url: getDocumentUrl(documentURLs, document),
          fileName: document,
          fileLocation: DOCUMENT_LOCATION
        });
      });

    }

    for (const asset of ASSET_KEYS) {
      if (shouldDownloadAsset(asset)) {
        downloadTasks.push([]);
        dispatchObj[DocumentType[asset]] = null;
        const urls = await getImageAssetPresignedUrls(asset);

        urls.forEach((url, index) => {
          generateInitDownloadData(dispatchObj, `${DocumentType[asset]}_${index}`);
          downloadTasks[downloadTasks.length - 1].push({
            id: `${asset}_${index}`,
            url,
            fileName: `${asset}_${index}`,
            fileLocation: ZIP_LOCATION
          });
        });
      }
    }
    if (Object.keys(dispatchObj).length > 0) {
      dispatch(overrideDownloadStatus(dispatchObj))
    }

    const validDownloadTasks = downloadTasks.filter((i) => i.length > 0);
    for (let i = 0; i < validDownloadTasks.length; i++) {
      const tasks = validDownloadTasks[i];
      if (tasks.length > 0) {
        setTimeout(() => {
          tasks.forEach((task) => {
            backgroundDownload(task);
          });
        }, 1000 * i);
      }
    }

    return true
  }

  return { download }
}

export const cleanupDataWhenFinishDownload = async (file: DownloadTaskData) => {
  if (DOCUMENT_KEYS.includes(file.id as DocumentType)) {
    try {
      const documentFile: Buffer = await window.electron?.asyncInvoke(RendererEvent.READ_FILE, file)
      const blob = new Blob([documentFile], { type: 'application/json' })
      await replaceDatabase(file.id, blob)
      // save the file to backup
      await window.electron?.asyncInvoke(RendererEvent.MOVE_TO_BACKUP, file)
    } catch (error) {
      // get file from backup
      try {
        const backupFile = await window.electron?.asyncInvoke(RendererEvent.READ_FILE, { fileLocation: BACKUP_DOCUMENT_LOCATION, fileName: file.fileName })
        const blob = new Blob([backupFile], { type: 'application/json' })
        await replaceDatabase(file.id, blob)
      } catch (error) {
        console.error('Backup file doesn\'t exist', error)
      }
    }
  }
  if (ASSET_KEYS.some(asset => file.id.startsWith(asset))) {
    try {
      await window.electron?.asyncInvoke(RendererEvent.UNZIP_REQUEST, file)
      await window.electron?.asyncInvoke(RendererEvent.REMOVE_FILE, file)
    } catch (error) {
      console.error('Failed to unzip', error)
    }
  }
}

export const deleteAllDownload = async (dispatch: Dispatch<any>) => {
  try {
    // cancel all download
    stopAllDownload();
    // delete database
    await resetOfflineDatabase();
    // delete from realm
    await deleteAllCompletedDownloads()
    // delete file system
    await window.electron?.asyncInvoke(RendererEvent.REMOVE_FOLDER, { folderLocation: DOWNLOAD_LOCATION })
    // delete from redux
    dispatch(offlineDownloadReset());
    return true;
  } catch (error) {
    console.error('deleteAllDownload error', error)
    return false;
  }
}


export const cancelAllInprogressDownloads = async (dispatch: Dispatch<any>) => {
  // cancel from native
  stopAllDownload();
  // update redux
  dispatch(offlineDownloadReset());
  setTimeout(() => {
    syncCompletedData(dispatch);
  }, 500);
};


export const syncCompletedData = async (dispatch: Dispatch<any>) => {
  const completedList = await getCompletedDownloadFiles();
  dispatch(overrideDownloadStatus(completedList.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})))
}

export const usePatchUpdate = () => {
  const { download } = useDownload();

  const updateLatestDownloadAsset = () => {
    // as we store asset in multiple records, we need to update all records
    ASSET_KEYS.forEach((key) => {
      for (let i = 0; i < ASSET_PATCH_SIZE; i++) {
        updateLatestDownloadFile(`${key}_${i}` as DocumentType, Date.now());
      }
    });
  };

  const manualDownloadImages = (imageList: string[]) => {
    if (imageList.length > 0) {
      bulkDownloadImages(imageList).then(() => {
        updateLatestDownloadAsset()
      });
    } else {
      updateLatestDownloadAsset()
    }
  };

  const getThingsNeedToUpdate = async (completedList: DownloadInfo[]) => {
    const thingsNeedToUpdate: Partial<Record<DocumentType, boolean>> = {};
    const allKeys = [...DOCUMENT_KEYS, ...ASSET_KEYS];
    for (const key of allKeys) {
      const item = completedList.find((i) => i.id === key);
      if (item?.state !== 'DONE' || !item?.downloadFinishedAt) continue;
      if (isDocumentOutdated(item.downloadFinishedAt)) {
        thingsNeedToUpdate[key] = true;
      }
    }

    // if one asset need to update, then others also need to update
    if (ASSET_KEYS.some((key) => thingsNeedToUpdate[key])) {
      for (const assetKey of ASSET_KEYS) {
        if (completedList.find((i) => i.id === assetKey)?.state === 'DONE') {
          thingsNeedToUpdate[assetKey] = true;
        }
      }
    }
    return thingsNeedToUpdate;
  };

  const handlePatchUpdate = async () => {
    const isAppOnline = navigator.onLine;
    if (!isAppOnline) return;
    const completedList = await getCompletedDownloadFiles();
    const thingsNeedToUpdate = await getThingsNeedToUpdate(completedList);

    // H&G or Pro asset need updated
    if (ASSET_KEYS.some((key) => thingsNeedToUpdate[key])) {
      const { addList, removeList } = await getAddAndRemoveImageList(thingsNeedToUpdate);
      if (addList.length <= MAX_IMAGE_SIZE_FOR_PATCH_UPDATE) {
        ASSET_KEYS.forEach((key) => {
          // manual update, no need to download zip file
          delete thingsNeedToUpdate[key];
        });
        manualDownloadImages(addList);
      }
      if (removeList.length > 0) {
        bulkRemoveFiles(removeList, ASSET_LOCATION);
      }
    }

    if (Object.keys(thingsNeedToUpdate).length > 0) {
      setTimeout(() => {
        // manual download documents, and assets if difference >= MAX_IMAGE_SIZE_FOR_PATCH_UPDATE
        download({
          resource: thingsNeedToUpdate,
          options: {
            downloadAnyway: true,
          },
        });
        // the app is just opened so we can wait for 5s to let everything ready
      }, 5000);
    }
  };

  return {
    handlePatchUpdate
  }

}

// only run in desktop app
export const useInitDownloadManager = () => {
  const isDesktop = window.electron?.isDesktop()
  const dispatch = useDispatch();
  const { handlePatchUpdate } = usePatchUpdate();
  const handleDownloadWhenAppOpen = async () => {
    try {
      await syncCompletedData(dispatch)
      handlePatchUpdate();
    } catch (error) {
      console.error('handleDownloadWhenAppOpen error', error);
    }
  }

  useEffect(() => {
    if (isDesktop) {
      handleDownloadWhenAppOpen();
      registerToDownloadEvent(dispatch)
    }
  }, [])
}