import moment from 'moment'
import { universal } from 'database/db'
import { dbCurrentEntries } from 'config/constants'
import { CountriesResponseType, CurrentCountryType, CurrentLanguageType } from 'types'

export async function saveCountriesDb(countries: CountriesResponseType) {
  try {
    const result = await universal.dcaSettings.put({
      name: dbCurrentEntries.COUNTRIES,
      export: countries.export,
      language: countries.language,
      createdAt: countries.createdAt,
      country: countries.country,
      year: countries.year,
      syncAt: moment().startOf('day').format(),
      data: countries.data,
    })
    return {
      countries: result,
    }
  } catch (error) {
    console.error('saveCountriesDb', error)
    throw error
  }
}

export async function saveCurrentCountryLangDb(
  currentCountryObjData: CurrentCountryType,
  selectedLanguageData: CurrentLanguageType,
) {
  try {
    if (selectedLanguageData) {
      await universal.dcaSettings.put({
        name: dbCurrentEntries.CURRENT_LANGUAGE,
        data: selectedLanguageData,
      })
    }

    if (currentCountryObjData) {
      await universal.dcaSettings.put({
        name: dbCurrentEntries.CURRENT_COUNTRY,
        data: currentCountryObjData,
      })
    }

    return { save: true }
  } catch (error) {
    console.error('saveCurrentCountryLangDb err', error)
    throw error
  }
}

export async function saveBusinessHeadlineDb(headline: string, pageId: number) {
  try {
    await universal.dcaSettings.put({
      name: dbCurrentEntries.BUSINESS_HEADLINE,
      data: { headline, pageId },
    })

    return { redirect: true }
  } catch (error) {
    console.error('saveBusinessHeadlineDb error', error)
    throw error
  }
}

export async function currentCountryAlreadyExists() {
  try {
    const currentCountry = (await universal.dcaSettings
      .where({ name: dbCurrentEntries.CURRENT_COUNTRY })
      .first()) as CurrentCountryType

    return { redirect: currentCountry !== undefined }
  } catch (error) {
    console.error('currentCountryAlreadyExists error', error)
    throw error
  }
}

export async function getBusinessHeadlineDb() {
  try {
    const result = await universal.dcaSettings.get(dbCurrentEntries.BUSINESS_HEADLINE)

    return result?.data
  } catch (error) {
    console.error('getBusinessHeadlineDb error', error)
    throw error
  }
}

export async function getCurrentLanguageDb() {
  try {
    const result = await universal.dcaSettings.get(dbCurrentEntries.CURRENT_LANGUAGE)

    return result?.data
  } catch (error) {
    console.error('getCurrentLanguageDb error', error)
    throw error
  }
}

export async function getCurrentLanguageCodeDb() {
  try {
    const language = await universal.dcaSettings.get(dbCurrentEntries.CURRENT_LANGUAGE)

    const languageCode = language?.data?.languageCode || ''
    return languageCode
  } catch (error) {
    console.error('getCurrentLanguageCodeDb', error)
    throw error
  }
}

export async function getCurrentCountryDb() {
  try {
    const country = await universal.dcaSettings.get(dbCurrentEntries.CURRENT_COUNTRY)
    if (!country) {
      return null
    }
    return country.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getCountriesDb() {
  try {
    const countries = await universal.dcaSettings.get(dbCurrentEntries.COUNTRIES)

    return { countries }
  } catch (error) {
    console.error(error)
    throw error
  }
}
