import { gql } from 'apollo-boost'

export const SEARCH = (
  searchStr: string,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
  {
    search(input: {
      q: "${searchStr}",
      languageCode: "${languageCode}",
      countryCode: "${countryCode}",
      year:${year}
    }){
      accessories {
        docCount
        items {
          id
          name
          price
          partnumber
          partnumberFormatted
          productType
          images {
            title
            type
            urls {
              type
              url
            }
          }
          productType
        productgroupId
        price
        priceFormatted
        priceGross
        priceGrossFormatted
        priceNet
        priceNetFormatted
        rootline_updated
          texts {
            value
            type
          }
        }
        pgCategories {
        id
        title
      }
      }
      content {
        docCount
        items {
          headline
          pageId
          text
        }
      }
      detergents {
        docCount
        items {
          id
          name
          partnumber
          partnumberFormatted
          productType
          images {
            title
            type
            urls {
              type
              url
            }
          }
           productType
        productgroupId 
        rootline_updated
        price
        priceFormatted
        priceGross
        priceGrossFormatted
        priceNet
        priceNetFormatted
          texts {
            value
            type
          }
        }
         pgCategories {
        id
        title
      }
      }
      old {
      docCount
      items {
        id
        name
        productType
        partnumber
        partnumberFormatted
        price
        priceFormatted
        priceGross
        priceGrossFormatted
        priceNet
        priceNetFormatted
        productgroupId
        rootline_updated
        texts {
          type
          value
        }
      }
      pgCategories {
        id
        title
      }
    }
      hg {
        docCount
        items {
          id
          name
          partnumber
          partnumberFormatted
          productType
          images {
            title
            type
            urls {
              type
              url
            }
          }
          productType
        productgroupId
        rootline_updated
        priceNetFormatted
        priceNet
        priceGrossFormatted
        priceGross
        priceFormatted
        price
        partnumber
          texts {
            value
            type
          }
        }
        pgCategories {
        id
        title
      }
      }
      pro {
        docCount
        items {
          id
          name
          partnumber
          partnumberFormatted
          productType
          images { 
            title
            type 
            urls { 
              type
              url  
            }
          } 
        productType
        productgroupId
        price
        priceFormatted
        priceGross
        priceGrossFormatted
        priceNet
        priceNetFormatted
        rootline_updated
          texts {
            value
            type
          }
        }
        pgCategories {
        id
        title
      }
      }
    }
  }
  `

export const SEARCH_SUGGESTIONS = (
  searchStr: string,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`   
{
  searchSuggestions(input: {
    q: "${searchStr}",
    languageCode: "${languageCode}",
    countryCode: "${countryCode}",
    year:${year}
  }){
    data{
        id,
        name,
        partnumber,
        partnumberFormatted,
    }
  }
}
`
