import DCAImage from 'components/DCAImage'
import { Container } from 'react-bootstrap'
import { ContentImageType } from 'types'

const ContentImage = ({ layoutData }: { layoutData: ContentImageType }) => {
  const { image } = layoutData
  return (
    <Container className='content-image-wrapper pt-4'>
      {image && <DCAImage src={image} type='lazyImage' style={{ width: '100%' }} />}
    </Container>
  )
}

export default ContentImage
