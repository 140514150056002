import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { PAGE_IDS } from 'config/constants'

const ProductFinderUI = ({ pageId }: { pageId: number }): React.ReactElement => {
  const translations = useSelector(selectTranslations)
  return (
    <>
      <Container id='zoovu-assistant' />
      {pageId === PAGE_IDS.PRODUCT_FINDER && (
        <div className='text-center py-4 ak-bold'>{translations.online_only}</div>
      )}
    </>
  )
}

export default ProductFinderUI
