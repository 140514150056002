import { useEffect } from 'react'
import useNavigation from './useNavigation'

/**
 * Create by TonyEveryWhere
 * When an anchor element with the specified class name "dca-anchor" is clicked,
 * 1. The hook intercepts the default browser navigation behavior. Instead of navigating directly to the URL specified in the href attribute of the anchor tag.
 * 2. Get the data-id attribute of the anchor element, and navigates to the page with the specified ID.
 * 3. If the href !== # mean external link, open new tab
 *
 * Example: <a href="#" class="dca-anchor" data-id="123">Click me</a>
 * When the anchor element is clicked, the hook will navigate to the page with the ID 123.
 */
const useLinkNavigation = () => {
  const { onDynamicNavigate } = useNavigation()

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement
      if (!clickedElement.classList.contains('dca-anchor')) return

      const anchorURL = clickedElement.getAttribute('href')
      const anchorDataID = clickedElement.getAttribute('data-id')
      const navigateID = anchorDataID ? Number(anchorDataID) : Number(anchorURL)

      event.preventDefault()

      if (!isNaN(navigateID)) {
        onDynamicNavigate(navigateID)
      } else if (anchorURL) {
        // handle external link
        window.open(anchorURL, '_blank')
      }
    }

    document.addEventListener('click', clickHandler)

    return () => document.removeEventListener('click', clickHandler)
  }, [onDynamicNavigate])
}

export default useLinkNavigation
