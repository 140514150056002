//
//                       _oo0oo_                                    _oo0oo_
//                      o8888888o                                  o8888888o
//                      88" . "88                                  88" . "88
//                      (| -_- |)                                  (| -_- |)
//                      0\  =  /0                                  0\  =  /0
//                    ___/`---'\___                              ___/`---'\___
//                  .' \\|     |// '.                          .' \\|     |// '.
//                 / \\|||  :  |||// \                        / \\|||  :  |||// \
//                / _||||| -:- |||||- \                      / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |                    |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |                    | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /                    \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___                ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.'  >' "".          ."" '<  `.___\_<|>_/___.'  >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |        | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /        \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'===== =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='                                    `=---='
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//     BUDDHA HAS ARRIVED, BUGS SHALL BE VANISHED
import React from 'react'
import { Provider } from 'react-redux'
import { Auth } from 'aws-amplify'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import store from 'redux/configureStore'
import awsConfig from 'config/libs/aws-exports'
import * as serviceWorker from 'config/offlineMode/serviceWorker'
import App from './App'
import './index.css'
import ScrollToTop from 'components/ScrollToTop'

Auth.configure(awsConfig)

const Router = window.electron?.isDesktop() ? HashRouter : BrowserRouter

/**
 * Register service worker for offline mode
 */
serviceWorker.register()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
