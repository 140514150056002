import { ASSET_LOCATION, RendererEvent } from 'electron-shared'
import moment from 'moment'
import { fetchAllImageLinks } from 'services'
import { getDownloadExportResponseType } from 'types/export.Type'
import { splitArrayIntoChunks, toLocalImgName } from 'config/utils/CommonFunction'
import { ASSET_KEYS, DocumentType, INIT_DOWNLOAD_DATA } from './download.reducer'

export const getDocumentUrl = (documentURLs: getDownloadExportResponseType, key: DocumentType) => {
  if (key === DocumentType.AK_DCA_COMMON) {
    return documentURLs.AK_DCA_COMMON
  }
  if (key === DocumentType.AK_DCA_HOME_AND_GARDEN) {
    return documentURLs.AK_DCA_HOME_AND_GARDEN
  }
  if (key === DocumentType.AK_DCA_PROFESSIONAL) {
    return documentURLs.AK_DCA_PROFESSIONAL
  }
  return ''
}

export const generateInitDownloadData = (obj: any, name: string) => {
  // eslint-disable-next-line no-param-reassign
  obj[name] = {
    ...INIT_DOWNLOAD_DATA,
    id: name,
    state: 'DOWNLOADING',
  }
}

export const getAllImageNamesFromServer = async (
  data: Partial<{ [type in DocumentType]: boolean }>,
) => {
  const images: { url: string; fileName: string }[] = []
  if (!ASSET_KEYS.some((key) => data[key])) return images

  for (const key of ASSET_KEYS) {
    if (data[key]) {
      const imageList = await fetchAllImageLinks(key)
      images.push(...imageList)
    }
  }

  return images
}

const IGNORE_IMAGES = ['tick.png']
export const findDifferences = (
  clientImages: string[],
  serverImages: { url: string; fileName: string }[],
) => {
  const clientSet = new Set(clientImages)

  const serverMap = new Map()
  serverImages.forEach((image) => {
    serverMap.set(image.fileName, image.url)
  })

  const addList: string[] = []
  const removeList: string[] = []

  // Find items that are in serverImages but not in clientImages
  for (const element of serverImages) {
    const { fileName, url } = element
    if (!clientSet.has(fileName)) {
      addList.push(url)
    }
  }

  // Find items that are in clientImages but not in serverImages
  for (const element of clientImages) {
    const image = element
    if (!serverMap.has(image)) {
      removeList.push(image)
    }
  }

  // remove ignore images
  const filteredRemoveList = removeList.filter((image) => !IGNORE_IMAGES.includes(image))

  return { addList, removeList: filteredRemoveList }
}

export const getAddAndRemoveImageList = async (
  data: Partial<{ [type in DocumentType]: boolean }>,
) => {
  try {
    const serverImages = await getAllImageNamesFromServer(data)
    if (!serverImages.length) return { addList: [], removeList: [] }

    const clientImages = await window.electron?.asyncInvoke(
      RendererEvent.GET_ALL_FILE_NAMES_IN_FOLDER,
      {
        folderLocation: ASSET_LOCATION,
      },
    )

    return findDifferences(clientImages, serverImages)
  } catch (error) {
    return {
      addList: [],
      removeList: [],
    }
  }
}

export const bulkDownloadImages = async (imageList: string[]) => {
  // fetch 100 images at a time
  const imageChunks = splitArrayIntoChunks(imageList, 100)
  for (const element of imageChunks) {
    const images = element
    await Promise.all(
      images.map(async (imgUrl) => {
        try {
          await window.electron?.asyncInvoke(RendererEvent.DOWNLOAD_SINGLE_IMAGE, {
            url: imgUrl,
            fileName: toLocalImgName(imgUrl),
          })
        } catch (error) {
          console.error('bulkDownloadImages error', error)
        }
      }),
    )
  }
}

export const isDocumentOutdated = (downloadFinishedAt: number) => {
  const lastDownload = new Date(downloadFinishedAt)
  return moment().diff(lastDownload, 'days') > 0
}

const BULK_SIZE = 100
export const bulkRemoveFiles = async (fileNames: string[], directory: string) => {
  // seperate files into arrays, each array with length of BULK_SIZE
  const filesArray = splitArrayIntoChunks(fileNames, BULK_SIZE)
  for (const element of filesArray) {
    const files = element
    // promise all to remove files
    await Promise.all(
      files.map(async (fileName) => {
        try {
          await window.electron?.asyncInvoke(RendererEvent.REMOVE_FILE, {
            fileLocation: directory,
            fileName,
          })
        } catch (error) {
          console.error('bulkRemoveFiles error', error)
        }
      }),
    )
  }
}
