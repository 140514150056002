import DCAImage from 'components/DCAImage'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import useProductDetail from 'redux/hooks/productDetail'
import { selectTranslations } from 'redux/selector'
import { PDFInfoType } from '../hooks/useJSPDF'
import { ProductDetailItemType } from 'types'
import { useEffect, useState } from 'react'
import HeaderPDF from '../components/HeaderPDF'
import DividerPDF from '../components/DividerPDF'
import { formatMoney, getCurrencySymbol } from 'config/libs/helpers'

type ProductDetailShortPDFWrapperProps = {
  products?: PDFInfoType[]
  onDownloadStatus: (index: number, result: boolean) => void
}

const ProductDetailShortPDFWrapper = ({
  products,
  onDownloadStatus,
}: ProductDetailShortPDFWrapperProps) => {
  const [totalNetPrice, setTotalNetPrice] = useState<number>(0)
  const [totalGrossPrice, setTotalGrossPrice] = useState<number>(0)
  const [currency, setCurrency] = useState<string | undefined>('');
  const translations = useSelector(selectTranslations)
  const onFinishLoading = (index: number, data?: ProductDetailItemType) => {
    if (!data) {
      onDownloadStatus(index, false)
    } else {
      onDownloadStatus(index, true)
      const netPrice = data.priceNet || 0
      const grossPrice = data.priceGross || 0
      setTotalNetPrice((prev) => prev + netPrice)
      setTotalGrossPrice((prev) => prev + grossPrice)

      if (data.priceNetFormatted || !currency) {
        setCurrency(getCurrencySymbol(data.priceNetFormatted))
      }
    }
  }

  const itemTxt = translations.article_export ?? 'Item';
  const priceTxt = translations.price_export ?? 'Price';
  const totalPriceTxt = translations.sum_export ?? 'Total price';
  const priceNetTxt = translations.net_priceNumber ?? 'Net*';
  const priceGrossTxt = translations.gross_priceNumber ?? 'Gross*';

  return (
    <>
      <HeaderPDF />
      <DividerPDF />
      <Row style={{ backgroundColor: '#E6E6E6', fontSize: 30, fontWeight: 'bold' }}>
        <Col md='3'>{itemTxt.toUpperCase()}</Col>
        <Col md='5' className='d-flex justify-content-center align-items-center'></Col>
        {totalGrossPrice > 0 ? <Col md='4'>{priceTxt.toUpperCase()}</Col> : null}
      </Row>
      {products?.map((product, index) => (
        <ProductDetailPDF
          key={index}
          pageId={product.pageId}
          onFinishLoading={(result?: ProductDetailItemType) => onFinishLoading(index, result)}
        />
      )
      )}

      {totalNetPrice > 0 ? (
        <Row style={{ backgroundColor: '#E6E6E6', fontSize: 30, fontWeight: 'bold' }}>
          <Col md='3' />
          <Col md='5'>{totalPriceTxt.toUpperCase()}</Col>
          <Col md='4'>
            <div>
              {priceNetTxt}: {formatMoney(totalNetPrice, currency)}
            </div>
            <div>
              {priceGrossTxt}: {formatMoney(totalGrossPrice, currency)}
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

type ProductDetailPDFProps = {
  pageId: number,
  onFinishLoading: (result?: ProductDetailItemType) => void
}

const ProductDetailPDF = ({ pageId, onFinishLoading }: ProductDetailPDFProps) => {
  const { productDetail, loading, error } = useProductDetail(pageId);
  const translations = useSelector(selectTranslations)

  const hasDataLoaded = !!productDetail && !loading && !error;

  useEffect(() => {
    if (hasDataLoaded) {
      onFinishLoading(productDetail)
    }

    if (error) {
      onFinishLoading(undefined)
    }
  }, [hasDataLoaded, error])

  const orderNumber = translations?.order_number ?? 'Order number'
  const ean = translations.ean ? translations.ean : 'EAN: '
  const priceNetTxt = translations.net_priceNumber ?? 'Net*'
  const priceGrossTxt = translations.gross_priceNumber ?? 'Gross*'

  if (!productDetail) return null

  return (
    <Row style={{ marginTop: 30 }}>
      <Col md='3'>
        <DCAImage
          src={productDetail?.images?.[0]?.urls?.[0]?.url}
          alt={productDetail.name}
          style={{ width: 140, height: 'auto' }}
        />
      </Col>
      <Col md='5' style={{ fontSize: 30, fontWeight: 'bold' }}>
        <div>{productDetail.name || null}</div>
        <div>
          {orderNumber}:{' '}
          {productDetail.partnumberFormatted || null}
        </div>
        <div>
          {ean}
          {productDetail.ean}
        </div>
      </Col>
      <Col md='4' style={{ fontSize: 30, fontWeight: 'bold' }}>
        <>
          {!!productDetail.priceNetFormatted && (
            <div>
              {priceNetTxt}: {productDetail.priceNetFormatted}
            </div>
          )}
          {!!productDetail.priceGrossFormatted && (
            <div>
              {priceGrossTxt}: {productDetail.priceGrossFormatted}
            </div>
          )}
        </>
      </Col>
    </Row>
  )
}

export { ProductDetailPDF, ProductDetailShortPDFWrapper }

