import { useCallback, useEffect, useState } from 'react';
import { ProgressBar as ReactProgressBar } from 'react-bootstrap'
import { DownloadInfo } from 'redux/download/download.reducer'
import throttle from 'lodash/throttle';

export const ProgressBar = ({ data }: { data: DownloadInfo }) => {
  // delay 5 seconds for remaining time
  const [timeLeft, setTimeLeft] = useState<number | null>(() => estimateRemainingTime(data));
  const updateRemainingTime = useCallback(
    throttle((info: DownloadInfo) => {
      setTimeLeft(estimateRemainingTime(info));
    }, 5000),
    []
  );

  useEffect(() => {
    updateRemainingTime(data);
  }, [data]);

  const downloadStatusLabel = (() => {
    if (data.state === 'PAUSED') {
      return ' (PAUSED)';
    }
    if (data.state === 'DOWNLOADING') {
      if (timeLeft) {
        return ` (${toRemainingTime(timeLeft)})`;
      }
    }
    return '';
  })();


  const calculateProgress = () => {
    if (data.percent) {
      return Math.floor(data.percent * 100);
    }
    return 0;
  };

  return (
    <ReactProgressBar className='custom-progress-bar' now={data.percent * 100} label={
      <p>
        {`${calculateProgress()}% ${downloadStatusLabel}`}
      </p>
    }>
    </ReactProgressBar>
  )

}



const toRemainingTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const result = [];
  if (hours) {
    result.push(`${hours}h`);
  }
  if (minutes) {
    result.push(`${minutes} min`);
  }
  if (result.length < 2 && remainingSeconds) {
    result.push(`${remainingSeconds} sec`);
  }
  return result.join(' ');
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const estimateRemainingTime = (data: DownloadInfo) => {
  const { createdAt, bytesWritten, totalBytes } = data;
  if (!createdAt || bytesWritten === 0) return null;
  const currentTime = Date.now();
  const elapsedTime = (currentTime - createdAt) / 1000;
  if (elapsedTime < 3) return null;
  const speed = bytesWritten / elapsedTime;
  const remainingBytes = totalBytes - bytesWritten;
  const remainingTime = remainingBytes / speed;
  return Math.max(Math.round(remainingTime), 0);
};