export const getFullWidthImages = (headlines: {image: string}[]) => {
  const images = headlines.map(i => i.image)
  let str = '<div class="full-img-wrap">'
  images.forEach((image) => {
    str += `<img
      src="${image}"
      alt=""
      srcset=""
      class="full-img"
    >`
  })
  str += '</div>'
  return str
}
