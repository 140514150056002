import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { TranslationResponseType } from 'types'
import { GET_TRANSLATIONS } from './queries/translationQueries'
import { API_YEAR } from 'config/constants'

const getTranslationsQuery = async (): Promise<TranslationResponseType> => {
  const currentCountry = await getCurrentCountry()
  const currentLanguage = await getCurrentLanguage()

  try {
    const { data } = await getGraphQLClient().query({
      query: GET_TRANSLATIONS(currentLanguage.languageCode, currentCountry.code, API_YEAR),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getTranslations
  } catch (err) {
    console.error('Error occurs while fetching translations: ', err)
    throw err
  }
}

export { getTranslationsQuery }
