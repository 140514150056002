export const PAGE_IDS = {
  BUSINESS_UNIT_ENTRY: 0,
  LANGUAGE_SELECTION: 1,
  SETTINGS: 2,
  COOKIE_POLICY: 3,
  SEARCH_SCREEN: 4,
  COMPARISON_SCREEN: 5,
  FAVORITES_SCREEN: 6,
  PRODUCT_FINDER: 200335427,
  SALE_GUIDE: [9152, 9159],
  PROFESSIONAL_START_PAGE: 20035426,
  HOME_START_PAGE: 20035385,
}

export const PAGE_NAMES = {
  LANGUAGE_SELECTION: 'language_selection',
  BUSINESS_UNIT_ENTRY: 'business_unit_entry',
  COOKIE_POLICY: 'cookie_policy',
  SETTINGS: 'settings',
  SEARCH: 'search',
  COMPARISON: 'comparison',
  FAVORITES: 'favorites',
  PRODUCT_FINDER: 'product_finder',
  SALE_GUIDE: 'sale_guide',
  PRODUCT_GROUP_OVERVIEW: 'product_group_overview',
  PRODUCT_GROUP_LIST: 'product_group_list',
  PRODUCT_LIST: 'product_list',
  PRODUCT_DETAIL: 'product_detail',
  MANUAL_CONTENT: 'manual_content',
}

export const PAGE_TYPES = {
  LANGUAGE_SELECTION: 'languageselection',
  BUSINESS_UNIT_ENTRY: 'businessunitentry',
  COOKIE_POLICY: 'cookiepolicy',
  PRODUCT_FINDER: 'productfinder',
  SETTINGS: 'settings',
  SEARCH: 'search',
  COMPARISON: 'comparison',
  FAVORITES: 'favorites',
  PRODUCT_GROUP_OVERVIEW: 'productgroupoverview',
  PRODUCT_GROUP_LIST: 'productgrouplist',
  PRODUCT_LIST: 'productlist',
  PRODUCT_DETAIL: 'productdetails',
  MANUAL_CONTENT: 'manualcontent',
}
