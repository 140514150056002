import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { ImageItemType } from 'types'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import DCAImage from 'components/DCAImage'

type ImagePreviewProps = {
  images: ImageItemType[]
}

function ImagePreview({ images }: ImagePreviewProps): React.ReactElement {
  const { showModal, hideModal } = useGlobalModalContext()
  const imagesFull: string[] = images.reduce<string[]>((acc, imageData) => {
    const imageFullUrl = imageData.urls.find((url) => url.type === 'large')
    if (imageFullUrl) {
      acc.push(imageFullUrl.url)
    }
    return acc
  }, [])
  const imagesThumbs: string[] = images.reduce<string[]>((acc, imageData) => {
    const imageThumbUrl = imageData.urls.find((url) => url.type === 'thumb')
    if (imageThumbUrl) {
      acc.push(imageThumbUrl.url)
    }
    return acc
  }, [])

  function renderArrow(
    onClickHandler: () => void,
    className: string,
    symbol: string,
    disabled: boolean,
  ) {
    if (!disabled && imagesFull.length > 1) {
      return (
        <i
          className={`karcher-icon ${className}`}
          role='button'
          tabIndex={0}
          onClick={onClickHandler}
        >
          <span dangerouslySetInnerHTML={{ __html: symbol }} />
        </i>
      )
    }
    return null
  }
  function renderThumbs() {
    return imagesThumbs.map((url, index) => (
      <div key={url} className='thumbnail-wrapper'>
        <div className='arrow-icons'>
          {index === 0 && <i className='karcher-icon arrow-left' style={{ color: 'black' }} />}
          {index === imagesFull.length - 1 && (
            <i className='karcher-icon arrow-right' style={{ color: 'black' }} />
          )}
        </div>
        <DCAImage
          src={url}
          alt={`thumbnail ${url}`}
          className='thumbnail'
          style={{ height: 60, maxWidth: 90, width: 'auto', display: 'block', margin: '0 auto' }}
          onClick={() => handleOpenImageViewer(index)}
        />
      </div>
    ))
  }

  function handleOpenImageViewer(index: number) {
    showModal('IMAGE_VIEWER_MODAL', { images, selectedImageIndex: index, handleClose: hideModal })
  }
  return (
    <>
      <Carousel
        emulateTouch
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs
        renderArrowNext={(onClick) =>
          renderArrow(onClick, 'arrow-next', '&#xe804;', !imagesFull.length)
        }
        renderArrowPrev={(onClick) =>
          renderArrow(onClick, 'arrow-prev', '&#xe803;', !imagesFull.length)
        }
        renderThumbs={renderThumbs}
      >
        {imagesFull.map((url, index) => (
          <div
            key={url}
            className='image-wrapper cursor-pointer'
            onClick={() => handleOpenImageViewer(index)}
          >
            <DCAImage
              src={url}
              style={{ maxWidth: '100%', maxHeight: '100%', margin: '0 auto', width: 'auto' }}
              showEnlargedImage
              alt='product-image'
            />
          </div>
        ))}
      </Carousel>
    </>
  )
}

export default ImagePreview
