import { createSlice } from '@reduxjs/toolkit'

export type NavigationState = {
  productDetail: {
    filterState: {
      [pageId: number]: Partial<{
        accessorySelectedCategoryId: number,
        accessorySelectedStandardOptionId: number,
        detergentSelectedCategoryId: number
      }>
    }
  }
}

const initialState: NavigationState = {
  productDetail: { filterState: {} }
}

const navigationSlice = createSlice({
  name: 'navigationState',
  initialState,
  reducers: {
    updateProductDetailFilterState: (state, action) => {
      state.productDetail.filterState[action.payload.pageId] = action.payload.filterState
    },
    clearProductDetailFilterState: (state) => {
      state.productDetail.filterState = {}
    }
  }
})

export const { clearProductDetailFilterState, updateProductDetailFilterState } = navigationSlice.actions

export default navigationSlice.reducer