/**
 * @fileoverview This is api service to get structure list
 * to construct breadcrumb in application
 * @return {object} Structure of hierarchy to represent parents and child
 * of respective screens
 */

import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { StructureDataResponseType } from 'types/structureType'
import { getGraphQLClient } from 'config/libs/globals'
import { GET_STRUCTURE } from './queries/structureQueries'
import { API_YEAR } from 'config/constants'

const getStructureQuery = async (): Promise<StructureDataResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: GET_STRUCTURE(currentLanguage.languageCode, currentCountry.code, API_YEAR),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getStructure
  } catch (error) {
    console.error('Error fetching structure:', error)
    throw new Error('Failed to fetch structure')
  }
}

export default getStructureQuery
