import { Col, Container, Row } from 'react-bootstrap'
import { ImageHeadlineTextFiftyFiftyType } from 'types'
import { extractAnchorsFromText } from 'config/libs/helpers'
import DCAImage from 'components/DCAImage'

type ImageHeadlineTextFiftyFiftyProps = {
  layoutData: ImageHeadlineTextFiftyFiftyType
}

const ImageHeadlineTextFiftyFifty = ({ layoutData }: ImageHeadlineTextFiftyFiftyProps) => {
  const { image, headline, text, imageRight } = layoutData
  const textWithAnchors = extractAnchorsFromText(text ?? '')

  const renderImage = (image?: string) => {
    if (!image) return null

    return <DCAImage src={image} type='lazyImage' className='w-100' />
  }

  const renderContent = (headline?: string, text?: string) => {
    return (
      <>
        {headline && (
          <h5
            className='ak-bold'
            dangerouslySetInnerHTML={{
              __html: headline,
            }}
          />
        )}
        {text && (
          <p
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
      </>
    )
  }

  return (
    <Container className='image-fifty-fifty-wrapper pt-4'>
      <Row>
        <Col md='6' xs='6' className='px-4'>
          {imageRight ? renderContent(headline, textWithAnchors) : renderImage(image)}
        </Col>
        <Col md='6' xs='6' className='mr-2'>
          {imageRight ? renderImage(image) : renderContent(headline, textWithAnchors)}
        </Col>
      </Row>
    </Container>
  )
}

export default ImageHeadlineTextFiftyFifty
