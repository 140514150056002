import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectStructure } from 'redux/selector'
import { StructureItemType } from 'types'

type Props = {
  pageId?: number
}

const BreadCrumbPDF = ({ pageId }: Props) => {
  const structure = useSelector(selectStructure)

  const [navigation, setNavigation] = useState<StructureItemType[]>([])

  const findActiveRoute = (id: number): StructureItemType | undefined => {
    if (!structure) return undefined
    return structure.find((item) => item.id === id)
  }

  const buildNavigation = (id: number, nav: StructureItemType[] = []) => {
    const route = findActiveRoute(id)
    if (route) {
      nav.unshift(route)
      if (route.parentId) {
        buildNavigation(route.parentId, nav)
      }
    }
    return nav
  }

  useEffect(() => {
    if (!pageId) return

    if (structure) {
      const activeRoute = findActiveRoute(pageId)
      if (activeRoute) {
        const nav = buildNavigation(activeRoute.id)
        setNavigation(nav)
      }
    }
  }, [structure, pageId])

  if (!navigation || navigation.length <= 1) {
    return null
  }

  return (
    <span className='catalogue-container'>
      {navigation.length === 2 && <span className='ak-bold'>{navigation[1].title}</span>}

      {navigation.length > 2 &&
        navigation.map((route, index) => {
          if (index === 0 || index === 1) {
            return null
          }

          return (
            <span
              key={`breadcrumb-link-${route.id}`}
              className={'breadcrumb-link'}
              tabIndex={0}
              role='button'
            >
              {route.title} {index < navigation.length - 1 && '>'}{' '}
            </span>
          )
        })}
    </span>
  )
}

export default BreadCrumbPDF
