import { ImageHeadlineThreeColumnsType } from 'types'
import { getCard } from '../pdfLayouts'

export const getImageHeadlineTextThreeCol = (headlines: ImageHeadlineThreeColumnsType[]) => {
  const leftImage = headlines.map((item) => item.image_left)
  const middleImage = headlines.map((item) => item.image_middle)
  const rightImage = headlines.map((item) => item.image_right)
  let str = ''

  headlines.forEach((item, index) => {
    str += `${getCard(leftImage[index], item.headline_left, item.text_left)}`
    str += `${getCard(middleImage[index], item.headline_middle, item.text_middle)}`
    str += `${getCard(rightImage[index], item.headline_right, item.text_right)}`
  })

  str = `
    <div class="card-row-wrap">
      <div class="card-wrap">
        ${str}
      </div>
    </div>
  `
  return str
}
