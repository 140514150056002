import { CompareListTechDataItem, EquipmentItemType, TechDataItemType, TextItemType } from 'types'
import { AkTableRowType, AkTableType } from 'components/AkTable/AkTable'

// Returns the unique items in an array of objects based on the "name" property
const getUniqueTechDataItems = (
  nameArray: CompareListTechDataItem[],
): CompareListTechDataItem[] => {
  const uniqueNames = Array.from(new Set(nameArray.map((item) => item.name)))
  return uniqueNames
    .map((name) => nameArray.find((item) => item.name === name))
    .filter((item): item is CompareListTechDataItem => item !== undefined)
}

// Returns a single row of data for the "techTable"
const getTableRow = (
  item: CompareListTechDataItem,
  compareListTechData: TechDataItemType[][],
): AkTableRowType => {
  const techDataValues = compareListTechData.map((element) => {
    const childElement = element.find((el) => el.name === item.name)
    return { value: childElement ? childElement.value : '-' }
  })
  
  return [{ value: `${item.label} ${item.unit ? `(${item.unit})` : ''}` }, ...techDataValues]
}

// Main function that gets the "techTable" data
export const getTechDataTable = (compareListTechData: TechDataItemType[][]): AkTableType => {
  // Get an array of objects containing the "name", "unit", "label", and "value" properties for each item in "compareListTechData"
  const nameArray: CompareListTechDataItem[] = []
  compareListTechData.forEach((item) => {
    item.forEach((element) => {
      const { name, unit, label } = element
      nameArray.push({ name, unit, label, value: '' })
    })
  })

  // Get an array of unique objects based on the "name" property
  const uniqueTechDataArray = getUniqueTechDataItems(nameArray)

  // Create an array of arrays containing the "techTable" data
  const techTable = uniqueTechDataArray.map((item) => {
    return getTableRow(item, compareListTechData)
  })

  return techTable
}

/**
 * Returns unique equipment items
 * @param compareListEquipment array of arrays containing equipment data
 * @returns array of unique equipment items
 */
const getUniqueEquipmentDataItems = (
  compareListEquipment: EquipmentItemType[][],
): EquipmentItemType[] => {
  const nameArray: EquipmentItemType[] = []

  compareListEquipment.forEach((item) => {
    item.forEach((element) => {
      const { name } = element
      const { label } = element
      const obj = { name, label, value: '-' }
      if (!nameArray.some((e) => e.name === obj.name)) {
        nameArray.push(obj)
      }
    })
  })

  return nameArray
}

/**
 * Returns equipment table data
 * @param uniqueNameArray array of unique equipment items
 * @param compareListEquipment array of arrays containing equipment data
 * @returns array of arrays containing equipment table data
 */
const getEquipmentTableData = (
  uniqueEquipmentDataArray: EquipmentItemType[],
  compareListEquipment: EquipmentItemType[][],
): AkTableType => {
  const equipmentTable = uniqueEquipmentDataArray.map((item) => {
    const equipmentDataValues = compareListEquipment.map((element) => {
      const childElement = element.find((e) => e.name === item.name)
      return { value: childElement ? childElement.value : '-' }
    })

    return [{ value: item.label }, ...equipmentDataValues]
  })

  return equipmentTable
}

/**
 * Returns equipment table
 * @param compareListEquipment array of arrays containing equipment data
 * @returns array of arrays containing equipment table data
 */
export const getEquipmentTable = (compareListEquipment: EquipmentItemType[][]): AkTableType => {
  const uniqueNameArray = getUniqueEquipmentDataItems(compareListEquipment)
  const equipmentTable = getEquipmentTableData(uniqueNameArray, compareListEquipment)
  return equipmentTable
}

/**
 * Returns a table of short descriptions
 * @param compareListShortDesc array of short descriptions
 * @returns table of short descriptions
 */
export function getShortDescriptionTable(compareListShortDesc: TextItemType[]): AkTableType {
  if (!compareListShortDesc || compareListShortDesc.length === 0) return []

  const shortDescTable: AkTableType = [[]]
  const values = compareListShortDesc.map(({ value }) => ({ value }))

  shortDescTable[0].push({ value: '' }, ...values)
  return shortDescTable
}
