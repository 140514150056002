import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AnalyticsState {
  loading: boolean
  error: boolean
  isAdobeAnalyticsAvailable: boolean
}

const initialState: AnalyticsState = {
  loading: true,
  error: false,
  isAdobeAnalyticsAvailable: false,
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    getAnalyticsAvailableRequest: () => initialState,
    getAnalyticsAvailableError: (state) => {
      state.loading = false
      state.error = true
      state.isAdobeAnalyticsAvailable = false
    },
    getAnalyticsAvailableSuccess: (state, action: PayloadAction<boolean>) => {
      state.loading = false
      state.error = false
      state.isAdobeAnalyticsAvailable = action.payload
    },
    resetAnalytics: () => initialState,
  },
})

export const {
  getAnalyticsAvailableRequest,
  getAnalyticsAvailableError,
  getAnalyticsAvailableSuccess,
  resetAnalytics,
} = analyticsSlice.actions

export default analyticsSlice.reducer
