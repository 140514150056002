import { universal } from 'database/db'
import { IDcaOnboarding } from 'database/dbTypes'

export const saveOnboardingDb = async (onboardingData: IDcaOnboarding) => {
  try {
    const id = await universal.dcaOnboarding.put(onboardingData)
    return { success: true, id }
  } catch (error) {
    console.error('saveOnboardingDb Fail', error)
  }
}

export const updateOnboardingDb = async (onboardingData: IDcaOnboarding) => {
  try {
    const id = await universal.dcaOnboarding.update(1, onboardingData)
    return { success: true, id }
  } catch (error) {
    console.error('updateOnboardingDb Fail', error)
  }
}

export const getOnboardingDb = async (): Promise<IDcaOnboarding | undefined> => {
  try {
    const onboardingData = await universal.dcaOnboarding.get(1)
    return onboardingData
  } catch (error) {
    console.error('Error getting onboarding content from database:', error)
    throw error
  }
}
