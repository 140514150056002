import { common } from 'database/db'
import { PageContentResponseType } from 'types'

export async function savePageContentDb(pageContent: PageContentResponseType) {
  try {
    const existingPageContent = await common.dcaPages.get(pageContent.pageId)
    if (existingPageContent) {
      // Page content already exists, update the record
      const result = await common.dcaPages.update(pageContent.pageId, pageContent)
      return result
    } else {
      // Page content does not exist, add a new record
      const result = await common.dcaPages.put(pageContent)
      return result
    }
  } catch (error) {
    console.error('Error savePageContentDb', error)
    throw error
  }
}

export async function getPageContentDb(pageId: number) {
  try {
    const result = await common.dcaPages.where({ pageId: pageId }).first()
    return result
  } catch (error) {
    console.error('Error getPageContentDb', error)
    throw error
  }
}
