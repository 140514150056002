import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import { PageContentResponseType } from 'types/pageContentType'
import { GET_PAGE_CONTENT } from './queries/pageContentQueries'
import { API_YEAR } from 'config/constants'

const getPageContentQuery = async (pageId: number): Promise<PageContentResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: GET_PAGE_CONTENT(pageId, currentLanguage.languageCode, currentCountry.code, API_YEAR),
    })

    const pageContents = data?.getPageContent
    if (!pageContents) {
      throw new Error('data is undefined')
    }
    return pageContents
  } catch (error) {
    console.warn('Error fetching page content:', error)
    throw new Error('Failed to fetch page content')
  }
}

export default getPageContentQuery
