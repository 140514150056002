import { CurrentLanguageType } from 'types'

const COOKIE_SRC = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'

const useCookie = () => {
  const DOMAIN_SCRIPT =
    process.env.REACT_APP_DEPLOYMENT_ENV !== 'prod'
      ? '3b27ce15-54b7-40d3-a897-615113f520e8-test'
      : '3b27ce15-54b7-40d3-a897-615113f520e8'

  const isCookieTemplate = !!document.querySelector('script#cookie-template')
  const isCookieFunction = !!document.querySelector('script#cookie-function')

  const initCookeBanner = (currentLanguage: CurrentLanguageType) => {
    const cookieTemplate = document.querySelector('script#cookie-template')
    const cookieFunction = document.querySelector('script#cookie-function')
    if (cookieTemplate) {
      cookieTemplate.remove()
    }
    if (cookieFunction) {
      cookieFunction.remove()
    }

    const cookieTemplateScript = document.createElement('script')
    cookieTemplateScript.src = COOKIE_SRC
    cookieTemplateScript.setAttribute('data-language', `${currentLanguage?.languageCode}`)

    cookieTemplateScript.type = 'text/javascript'
    cookieTemplateScript.setAttribute('data-domain-script', DOMAIN_SCRIPT)
    cookieTemplateScript.setAttribute('charset', 'UTF-8')
    cookieTemplateScript.async = true
    cookieTemplateScript.id = 'cookie-template'

    const cookieFunctionScript = document.createElement('script')
    cookieFunctionScript.type = 'text/javascript'
    cookieFunctionScript.text = 'function OptanonWrapper() {}'
    cookieFunctionScript.id = 'cookie-function'

    const script = document.getElementsByTagName('script')[0]
    script.parentNode?.insertBefore(cookieTemplateScript, script)
    script.parentNode?.insertBefore(cookieFunctionScript, script)
  }

  return { initCookeBanner, isCookieTemplate, isCookieFunction }
}

export default useCookie
