import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductCompareType } from 'types/productType'

export interface ComparisonState {
  productCompare: ProductCompareType[]
}

const initialState: ComparisonState = {
  productCompare: [],
}

const comparisonSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    saveProductToCompare(state, action: PayloadAction<ProductCompareType[]>) {
      state.productCompare = action.payload
    },
    resetComparison: () => initialState,
  },
})

export const { saveProductToCompare, resetComparison } = comparisonSlice.actions

export default comparisonSlice.reducer
