import React, { useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ContentAnchorItemType,
  ContentDownloadType,
  ContentImageType,
  ContentRibbonType,
  ContentTextType,
  ContentType,
  HeadlineTextType,
  ImageHeadlineFourColumnsType,
  ImageHeadlineTextFiftyFiftyType,
  ImageHeadlineTextType,
  ImageHeadlineThreeColumnsType,
  ImageHeadlineTwoColumnsType,
  PageContentResponseType,
} from 'types'
import { LAYOUTS, PAGE_IDS } from 'config/constants'
import { selectStructure, selectTranslations } from 'redux/selector'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import { initMarking } from 'features/Marking/markingFunctions'
import { createManualContentPDF } from 'features/NewPDF/ManualContentPDF/ManualContentPDF'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import HorizontalRule from 'components/HorizontalRule'
import ContentAnchor from '../components/ContentAnchor'
import ContentDownload from '../components/ContentDownload'
import ContentImage from '../components/ContentImage'
import ContentRibbon from '../components/ContentRibbon/ContentRibbon'
import Text from '../components/ContentText'
import HeadLineText from '../components/HeadlineText'
import ImageHeadlineFourColumns from '../components/ImageHeadlineFourColumns'
import ImageHeadlineText from '../components/ImageHeadlineText'
import ImageHeadlineTextFiftyFifty from '../components/ImageHeadlineTextFiftyFifty'
import ImageHeadlineThreeColumns from '../components/ImageHeadlineThreeColumns'
import ImageHeadlineTwoColumns from '../components/ImageHeadlineTwoColumns'
import './manualContentStyles.scss'
import 'styles/pages/manualContent.scss'

type ManualContentUIProps = {
  pageData: PageContentResponseType
}

type LayoutConfigType = {
  layout: string
  values: Array<object>
}

const ManualContentUI = ({ pageData }: ManualContentUIProps) => {
  const { pageId, content: data } = pageData

  const headerHeight = useHeaderHeight()
  const translations = useSelector(selectTranslations)
  const manualContentRef = useRef<HTMLDivElement>(null)
  const structure = useSelector(selectStructure)

  /** Load data marking */
  useEffect(() => {
    initMarking()
  }, [])

  const onScrollToTop = () => {
    const element = document.getElementById(data[0].id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const renderRibbon = () => {
    const ribbonData = data?.find((item) => {
      const layoutConfig: LayoutConfigType = item.config ? JSON.parse(item.config) : {}
      return layoutConfig.layout === LAYOUTS.CONTENT_RIBBON
    })

    if (!ribbonData) return null

    const layoutConfig: LayoutConfigType = JSON.parse(ribbonData.config)
    const layoutData = layoutConfig.values?.[0] as ContentRibbonType

    const onExport = (onDone: () => void) => {
      createManualContentPDF(
        {
          manualContents: data,
          ribbonData: layoutData,
          pageId: pageId,
          structure: structure,
        },
        onDone,
      )
    }

    return <ContentRibbon pageData={pageData} layoutData={layoutData} onExport={onExport} />
  }

  const renderAnchor = () => {
    const anchorData = data.find((item) => {
      const layoutConfig: LayoutConfigType = item.config ? JSON.parse(item.config) : {}
      return layoutConfig.layout === LAYOUTS.CONTENT_ANCHOR
    })

    if (!anchorData) return null

    const layoutConfig: LayoutConfigType = JSON.parse(anchorData.config)

    const layoutData = layoutConfig.values as ContentAnchorItemType[]
    return <ContentAnchor anchorsData={layoutData} anchorRef={manualContentRef} />
  }

  const renderItem = (item: ContentType) => {
    const layoutConfig: LayoutConfigType = item.config ? JSON.parse(item.config) : {}
    let layoutData
    let layoutComponent

    switch (layoutConfig.layout) {
      case LAYOUTS.CONTENT_IMAGE:
        layoutData = layoutConfig.values?.[0] as ContentImageType
        layoutComponent = <ContentImage layoutData={layoutData} />
        break

      case LAYOUTS.CONTENT_DOWNLOAD:
        layoutData = layoutConfig.values?.[0] as ContentDownloadType
        layoutComponent = <ContentDownload layoutData={layoutData} />
        break

      case LAYOUTS.CONTENT_HEADLINE_TEXT:
        layoutData = layoutConfig.values?.[0] as HeadlineTextType
        layoutComponent = <HeadLineText layoutData={layoutData} />
        break

      case LAYOUTS.IMAGE_HEADLINE_TEXT:
        layoutData = layoutConfig.values?.[0] as ImageHeadlineTextType
        layoutComponent = <ImageHeadlineText layoutData={layoutData} />
        break

      case LAYOUTS.IMAGE_HEADLINE_TEXT_FIFTY_FIFTY:
        layoutData = layoutConfig.values?.[0] as ImageHeadlineTextFiftyFiftyType
        layoutComponent = <ImageHeadlineTextFiftyFifty layoutData={layoutData} />
        break

      case LAYOUTS.IMAGE_HEADLINE_TEXT_TWO_COL:
        layoutData = layoutConfig.values?.[0] as ImageHeadlineTwoColumnsType
        layoutComponent = <ImageHeadlineTwoColumns layoutData={layoutData} />
        break

      case LAYOUTS.IMAGE_HEADLINE_TEXT_THREE_COL:
        layoutData = layoutConfig.values?.[0] as ImageHeadlineThreeColumnsType
        layoutComponent = <ImageHeadlineThreeColumns layoutData={layoutData} />
        break

      case LAYOUTS.IMAGE_HEADLINE_TEXT_FOUR_COL:
        layoutData = layoutConfig.values?.[0] as ImageHeadlineFourColumnsType
        layoutComponent = <ImageHeadlineFourColumns layoutData={layoutData} />
        break

      case LAYOUTS.CONTENT_TABLE:
        let Table: AkTableType = []
        if (layoutConfig.values.length > 0) {
          const firstValue = layoutConfig.values[0]
          if (Array.isArray(firstValue)) {
            Table = firstValue.map((row: any[]) => {
              return row.map((column: { value: string; style: string }) => ({
                value: column.value,
                style: column.style,
              }))
            })
          }
        }
        layoutComponent = <AkTable table={Table} />
        break

      case LAYOUTS.CONTENT_TEXT:
        layoutData = layoutConfig.values?.[0] as ContentTextType
        layoutComponent = <Text layoutData={layoutData} />
        break

      case LAYOUTS.HORIZONTAL_RULE:
        layoutComponent = <HorizontalRule />
        break

      default:
        break
    }

    return <div id={item.id}>{layoutComponent}</div>
  }

  const renderContent = () => {
    return (
      <div ref={manualContentRef} className='content-section-wrapper'>
        {data.map((item) => {
          return <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
        })}

        {renderFooter()}
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <>
        {PAGE_IDS.SALE_GUIDE.includes(pageId) && (
          <Container className='mt-4'>
            <p className='ak-bold'>
              {translations?.online_only ?? 'This content is only available in online mode'}
            </p>
          </Container>
        )}
        <div className=' d-flex justify-content-end p-5'>
          <FontAwesomeIcon icon={faArrowAltCircleUp} onClick={onScrollToTop} size='3x' />
        </div>
      </>
    )
  }

  return (
    <Container>
      <Row
        className='manualContent-header'
        style={{
          paddingTop: headerHeight,
        }}
      >
        <Col className='manualContent-header-col'>
          {renderRibbon()}
          {renderAnchor()}
        </Col>
      </Row>

      {renderContent()}
    </Container>
  )
}

export default ManualContentUI
