import { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ContentRibbonType, PageContentResponseType } from 'types'
import { getContentRibbon } from 'config/utils/CommonFunction'
import { selectTranslations } from 'redux/selector'
import { handleSelectedMark } from 'features/Marking/markingFunctions'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { usePDFLoading } from 'features/NewPDF/hooks/usePDFLoading'
import './contentRibbonStyles.scss'

type Props = {
  layoutData: ContentRibbonType
  onExport: (onDone: () => void) => void
  pageData?: PageContentResponseType
}

const ContentRibbon = ({ layoutData, onExport, pageData }: Props) => {
  const translations = useSelector(selectTranslations)
  const exportText = translations.export ?? 'EXPORT'
  const { showModal, hideModal } = useGlobalModalContext()

  const { headline, text } = layoutData
  const { pageId } = pageData || {}
  const { subHeadline, description } = getContentRibbon(text ?? '')
  const { pdfLoading, handleExportPDF } = usePDFLoading(onExport)

  /** Init Marking listener */
  useEffect(() => {
    document.addEventListener('mouseup', handleSelectedMark)
    return () => {
      document.removeEventListener('mouseup', handleSelectedMark)
    }
  }, [])

  const onShowModalFavorite = () => {
    showModal('FAVORITES_MODAL', {
      showModal: true,
      itemName: headline,
      pageContent: pageData,
      handleClose: hideModal,
    })
  }

  return (
    <Container className='content-ribbon-wrapper' fluid>
      <Container>
        <Row>
          <Col md='9'>
            {headline && (
              <h1
                id={`content-ribbon-headline-${pageId}`}
                className='heading'
                dangerouslySetInnerHTML={{
                  __html: headline,
                }}
              />
            )}
            {subHeadline && (
              <h6
                id={`content-ribbon-subHeadline-${pageId}`}
                className='ak-bold'
                dangerouslySetInnerHTML={{
                  __html: subHeadline,
                }}
              />
            )}
            {description && (
              <p
                id={`content-ribbon-description-${pageId}`}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
          </Col>
          <Col md='3'>
            <Row className='d-flex justify-content-end'>
              <Col md='1'>
                <i
                  onClick={onShowModalFavorite}
                  role='button'
                  tabIndex={0}
                  className='karcher-icon fav-icon icon-a_068_feedback_line'
                >
                  &#xe843;
                </i>
              </Col>
              <Col md='1'>
                {pdfLoading ? (
                  <Spinner style={{ width: 12, height: 12 }} />
                ) : (
                  <i
                    onClick={handleExportPDF}
                    role='button'
                    tabIndex={0}
                    title={exportText}
                    className='karcher-icon export-icon icon-a_068_feedback_line ml-4'
                  >
                    &#xe850;
                  </i>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default ContentRibbon
