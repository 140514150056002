import { getProductDetailDbById } from 'database/queries/product'
import { getProductDetailQuery } from 'services'
import { ProductDetailItemType, ProductDetailResponseType } from 'types'
import { LAYOUTS } from 'config/constants'
import { productDetailStyle, toProductDetailHTML } from '../ProductDetailPDF/ProductDetailPDF'
import { getBreadcrumbData } from '../pdfHelper'
import { logoHTML, toBreadcrumbHTML, toRibbonHTML } from '../pdfLayouts'
import { Config } from './type'
import { asyncFetchData, asyncGetPageContent } from './utils'

export const getProductDetailHTML = async (pageId: number, config: Config) => {
  const { isAppOnline, translations } = config
  const pageData = await asyncGetPageContent(pageId, isAppOnline)
  if (!pageData || !('content' in pageData)) return ''

  const productContent = pageData?.content?.find((item) => item.type === LAYOUTS.PRODUCT_DETAILS)
  if (!productContent?.config) return ''

  const productConfig = JSON.parse(productContent.config)

  const productDetailResponse = await asyncFetchData<number, ProductDetailResponseType>(
    isAppOnline,
    getProductDetailQuery,
    getProductDetailDbById,
    productConfig.productId,
    productConfig.productId,
  )

  if (!productDetailResponse) return ''

  const { data } = productDetailResponse
  const productDetailData: ProductDetailItemType = JSON.parse(data || '{}')

  const ribbonData = getRibbonData(productDetailData)
  const breadCrumbData = getBreadcrumbData(pageId, config.structure)

  const breadCrumbHTML = toBreadcrumbHTML(breadCrumbData)
  const ribbonHTML = toRibbonHTML(ribbonData)
  const mainHTML = toProductDetailHTML(productDetailData, translations)

  return `
    <style>
    ${productDetailStyle}
    </style>
    ${logoHTML}
    ${breadCrumbHTML}
    ${ribbonHTML}
    ${mainHTML}
`
}

const getRibbonData = (productDetail: ProductDetailItemType) => {
  const { name } = productDetail
  const description = productDetail?.texts.find((textData) => textData.type === 'short')

  return {
    headline: name,
    description: description?.value ?? '',
  }
}
