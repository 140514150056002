import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'

const Imprint = () => {
  const translations = useSelector(selectTranslations)
  const imprintText = translations?.imprint ?? 'Imprint'
  const imprintPolicyText = translations?.imprint_policy_text ?? <span />
  return (
    <Container className='cookie-policy-screen px-0' fluid>
      <Header pageId={PAGE_IDS.SETTINGS} />
      <Breadcrumb pageId={PAGE_IDS.SETTINGS} staticTitle={imprintText} />
      <Container>
        <br />
        <h1>{imprintText.toUpperCase()}</h1>
        <br />
        <div dangerouslySetInnerHTML={{ __html: imprintPolicyText }}/>
      </Container>
    </Container>
  )
}
export default Imprint
