import React from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAppLang } from 'config/utils/CommonFunction'
import { selectCurrentCountry } from 'redux/selector'
import 'styles/components/akTable.scss'

type AkTableColumnType = { value: string; style?: string }

export type AkTableRowType = AkTableColumnType[]

export type AkTableType = AkTableRowType[]

type Props = {
  table: AkTableType
  tableType?: string
  fixedWidth?: boolean
  definedTable?: boolean
}

const HAS_EQUIPMENT = '1'

const AkTable: React.FC<Props> = (props: Props) => {
  const { table, definedTable = false, tableType } = props
  const currentCountry = useSelector(selectCurrentCountry)
  const languageCode = currentCountry?.languages[0].languageCode ?? ''
  const appLang = getAppLang(languageCode)

  const borderClass = tableType === 'borderless' ? 'table-borderless' : 'table-striped'

  const textStyles: React.CSSProperties = {
    fontSize: '10px',
    color: '',
    fontFamily: '',
    textAlign: 'left',
    fontWeight: '',
    fontStyle: '',
    backgroundColor: '',
  }

  const renderHasEquipment = () => (
    <div style={!definedTable ? textStyles : {}}>
      <FontAwesomeIcon size='2x' color='#3eaf56' icon={faCheckCircle} />
    </div>
  )

  const renderColumn = (column: AkTableColumnType, colIndex: number) => {
    const { value, style } = column
    let textStyles: React.CSSProperties = {
      fontSize: '10px',
      color: '',
      fontFamily: '',
      textAlign: 'left',
      fontWeight: '',
      fontStyle: '',
      backgroundColor: '',
    }
    if (style) {
      const styleArr = style.split(';')
      styleArr.forEach((styleItem) => {
        const activeStyleArr = styleItem.split(': ')

        if ((activeStyleArr[0] && activeStyleArr[0].trim()) === 'background-color') {
          textStyles = { ...textStyles, backgroundColor: activeStyleArr[1] }
        }
        if ((activeStyleArr[0] && activeStyleArr[0].trim()) === 'color') {
          textStyles = { ...textStyles, color: activeStyleArr[1] }
        }
        if ((activeStyleArr[0] && activeStyleArr[0].trim()) === 'font-weight') {
          if (activeStyleArr[1] === 'bold') {
            textStyles = {
              ...textStyles,
              fontFamily: appLang.lang ? 'Clan Pro Bold Ru' : 'Clan Pro Bold',
            }
          }
          textStyles = { ...textStyles, fontWeight: activeStyleArr[1] }
        }
        if ((activeStyleArr[0] && activeStyleArr[0].trim()) === 'font-style') {
          textStyles = { ...textStyles, fontStyle: activeStyleArr[1] }
        }
        if ((activeStyleArr[0] && activeStyleArr[0].trim()) === 'text-align') {
          textStyles = { ...textStyles, textAlign: 'center' }
        }
      })
    }
    const tdContent =
      value === HAS_EQUIPMENT ? (
        renderHasEquipment()
      ) : (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      )
    return (
      <td
        className={colIndex === 0 ? 'pl-3' : ''}
        width='20%'
        key={`${Math.random().toString(36)}td`}
        style={!definedTable ? textStyles : {}}
      >
        {tdContent}
      </td>
    )
  }

  const renderRow = (row: AkTableRowType) => {
    return (
      <tr key={`${Math.random().toString(36)}tr`}>
        {row.map((column, colIndex) => renderColumn(column, colIndex))}
      </tr>
    )
  }

  return (
    <Table
      className={`ak-table ${!definedTable} ? 'pt-4 container' : ${borderClass} pt-4 container`}
      key={`${Math.random().toString(36)}table`}
    >
      <tbody {...appLang}>{table.map((row) => renderRow(row))}</tbody>
    </Table>
  )
}

export default AkTable
