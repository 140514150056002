import images from 'assets/images/images'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { useState } from 'react'
import { Button, Card, Col, FormCheck, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { TRANSLATIONS } from 'config/constants'
import { splitArrayIntoChunks } from 'config/utils/CommonFunction'
import { useDownload } from 'redux/download/download.hook'
import { DOCUMENT_KEYS, DocumentType, DownloadInfo } from 'redux/download/download.reducer'
import { selectDownloadData, selectLatestDownloadTime } from 'redux/download/download.selector'
import { selectAppOnline, selectTranslations } from 'redux/selector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import DCAImage from 'components/DCAImage'

type CheckBoxesType = Partial<{
  [key in DocumentType]: boolean
}>

const DownloadBlock = () => {
  const isDesktop = window.electron?.isDesktop()
  const { download } = useDownload()
  const { showModal, hideModal } = useGlobalModalContext()
  const translations = useSelector(selectTranslations)
  const isAppOnline = useSelector(selectAppOnline)
  const CHECK_BOXES_DATA = [
    {
      key: DocumentType.AK_DCA_HOME_AND_GARDEN,
      value: translations.home_and_garden_database || 'Home & Garden Content',
    },
    {
      key: DocumentType.AK_DCA_PROFESSIONAL,
      value: translations.professional_database || 'Professional Content',
    },
    {
      key: DocumentType.HOME_AND_GARDEN_ASSET,
      value: translations.home_and_garden_images || 'Home & Garden Images',
    },
    {
      key: DocumentType.PRO_ASSET,
      value: translations.professional_images || 'Professional Images',
    },
  ]

  const lastDownloadTime = selectLatestDownloadTime()

  const {
    download: downloadTitle = TRANSLATIONS.download,
    last_download: lastDownloadKey = TRANSLATIONS.lastDownload,
    settings_download_caption: downloadCaption = TRANSLATIONS.onboarding_download_description,
    download_status: downloadStatusTxt = TRANSLATIONS.downloadStatus,
    web_offline_message_prompt: offlineMsg = TRANSLATIONS.youAreOffline,
  } = translations || {}

  const [checkboxData, setCheckboxData] = useState<CheckBoxesType>({})

  const downloadData = selectDownloadData()
  const runningDownloadCount = downloadData.filter((i) => i.state === 'DOWNLOADING').length
  const pauseDownloadCount = downloadData.filter((i) => i.state === 'PAUSED').length
  // array to object
  const downloadDataObj: Partial<Record<DocumentType, DownloadInfo>> = downloadData.reduce(
    (obj, item) => ({ ...obj, [item.id]: item }),
    {},
  )

  const [loading, setLoading] = useState(false)

  const onCheckboxPress = (key: DocumentType) => {
    const cloneCheckboxState = { ...checkboxData }
    if (cloneCheckboxState[key]) {
      delete cloneCheckboxState[key]
      setCheckboxData({ ...cloneCheckboxState })
    } else {
      setCheckboxData((prev) => ({
        ...prev,
        [key]: true,
      }))
    }
  }

  const onPressDownload = async () => {
    if (loading) {
      return
    }

    if (!isAppOnline) {
      showModal('CONFIRM_MODAL', {
        showModal: true,
        message: offlineMsg,
        handleClose: hideModal,
      })
      return
    }

    try {
      setLoading(true)
      const shouldDownloadCommonDocument =
        checkboxData[DocumentType.AK_DCA_HOME_AND_GARDEN] ||
        checkboxData[DocumentType.AK_DCA_PROFESSIONAL]
      const shouldDownloadCommonAsset =
        checkboxData[DocumentType.HOME_AND_GARDEN_ASSET] || checkboxData[DocumentType.PRO_ASSET]
      const canDownload = await download({
        resource: {
          ...checkboxData,
          [DocumentType.AK_DCA_COMMON]: shouldDownloadCommonDocument,
          [DocumentType.COMMON_ASSET]: shouldDownloadCommonAsset,
        },
      })

      if (canDownload) {
        setTimeout(() => {
          showDownloadModal()
          setCheckboxData({})
        }, 200)
      }
    } catch (error) {
      console.error('error', error)
    } finally {
      setLoading(false)
    }
  }

  const showDownloadModal = () => {
    showModal('DOWNLOAD_MODAL', {
      showModal: true,
      handleHide: hideModal,
    })
  }

  const chunkCheckboxes = splitArrayIntoChunks(CHECK_BOXES_DATA, 2)

  const downloadDetailDisabled = downloadData.length === 0

  const downloadBtnDisabled =
    !isAppOnline ||
    runningDownloadCount > 0 ||
    pauseDownloadCount > 0 ||
    // check if there is a selected checkbox
    Object.keys(checkboxData).length === 0

  const isFileAndCommonDownloading = (key: DocumentType) => {
    const keyState = downloadDataObj[key]?.state
    const downloadingStates = ['DOWNLOADING', 'PAUSED', 'DONE']

    const commonState = DOCUMENT_KEYS.includes(key)
      ? downloadDataObj[DocumentType.AK_DCA_COMMON]?.state
      : downloadDataObj[DocumentType.COMMON_ASSET]?.state

    return (
      downloadingStates.includes(keyState || '') && downloadingStates.includes(commonState || '')
    )
  }

  const isFileChecked = (key: DocumentType) => {
    // user select or is downloading/completed
    return checkboxData[key] || isFileAndCommonDownloading(key)
  }

  const isCheckDisabled = (key: DocumentType) => {
    // if file is downloading or completed
    return isFileAndCommonDownloading(key)
  }

  return (
    <Card
      className='download-block setting-card'
      style={
        !isDesktop
          ? {
              pointerEvents: 'none',
              filter: 'grayscale(100%)',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }
          : {}
      }
    >
      <h6 className='settings-heading'>
        <i className='karcher-icon settings-icons'>&#xe851;</i>
        {downloadTitle}
        {isDesktop && (
          <>
            <i
              className='karcher-icon settings-icons'
              data-tooltip-id='my-tooltip'
              style={{ marginLeft: 5 }}
            >
              &#xe802;
            </i>
            <Tooltip id='my-tooltip' place='bottom' className='tooltip'>
              <div
                dangerouslySetInnerHTML={{ __html: translations.onboarding_download_description }}
              />
            </Tooltip>
          </>
        )}
      </h6>

      <p className='settings-caption' style={{ fontSize: 10 }}>
        {downloadCaption}
      </p>
      {chunkCheckboxes.map((chunk, index) => (
        <Row className='mt-2' key={index}>
          {chunk.map(({ key, value }) => (
            <Col key={key}>
              <FormCheck
                label={value}
                checked={isFileChecked(key)}
                onChange={() => onCheckboxPress(key)}
                disabled={isCheckDisabled(key)}
              />
            </Col>
          ))}
        </Row>
      ))}

      {isDesktop && !!lastDownloadTime && (
        <Row>
          <p className='last-download'>
            {lastDownloadKey}: {moment(lastDownloadTime).format('D/MM/YYYY')}
          </p>
        </Row>
      )}

      <Row className='mt-2'>
        <Col>
          <Button
            className={`app-button ${downloadDetailDisabled && 'disable-button'}`}
            onClick={showDownloadModal}
            disabled={downloadDetailDisabled}
          >
            {downloadStatusTxt.toUpperCase()}
          </Button>
        </Col>
        <Col>
          <Button
            id='onboarding-btn-download'
            className={`app-button ${downloadBtnDisabled ? 'disable-button' : ''}`}
            onClick={onPressDownload}
            disabled={downloadBtnDisabled}
          >
            {loading ? (
              <DCAImage className='download-loader' src={images.loading} />
            ) : (
              downloadTitle.toUpperCase()
            )}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default DownloadBlock
