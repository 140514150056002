import { getPageContentDb } from 'database/queries/pageContent'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  getPageContentSuccess,
  getPageContentError,
  getPageContent,
  getPageContentWithCallback,
} from 'redux/reducers/pageContentReducer'
import { selectAppOnline } from 'redux/selector'
import getPageContentQuery from 'services/pageContent'
import { PageContentResponseType } from 'types/pageContentType'

function* fetchPageContentOnline(pageId: number) {
  try {
    const response: PageContentResponseType = yield call(getPageContentQuery, pageId)
    return response
  } catch (error) {
    throw new Error('Failed to fetch page content from API')
  }
}

function* fetchPageContentOffline(pageId: number) {
  try {
    const response: PageContentResponseType = yield getPageContentDb(pageId)
    return response
  } catch (error) {
    throw new Error('Failed to fetch page content from local database')
  }
}

function* fetchPageContent(action: ReturnType<typeof getPageContent>) {
  const { payload } = action
  try {
    const isOnline: boolean = yield select(selectAppOnline)
    const response: PageContentResponseType = isOnline
      ? yield fetchPageContentOnline(payload)
      : yield fetchPageContentOffline(payload)
    if (response) {
      yield put(getPageContentSuccess({ pageId: payload, obj: response }))
    } else {
      yield put(getPageContentError(payload))
    }
  } catch (error) {
    yield put(getPageContentError(payload))
  }
}

function* fetchPageContentWithCallback(action: ReturnType<typeof getPageContentWithCallback>) {
  const { pageId, onSuccess , onFailure} = action.payload
  try {
    const isOnline: boolean = yield select(selectAppOnline)
    const response: PageContentResponseType = isOnline
      ? yield fetchPageContentOnline(pageId)
      : yield fetchPageContentOffline(pageId)
    if (response) {
      yield put(getPageContentSuccess({ pageId: pageId, obj: response }))
      if(onSuccess){
        onSuccess(response)
      }
    } else {
      yield put(getPageContentError(pageId))
      if(onFailure) {
        onFailure()
      }

    }
  } catch (error) {
    yield put(getPageContentError(pageId))
    if(onFailure) {
      onFailure()
    }
  }
}


export default function* pageContentSaga() {
  yield takeEvery(getPageContent.type, fetchPageContent),
  yield takeLatest(getPageContentWithCallback.type, fetchPageContentWithCallback)
}
