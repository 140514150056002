import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import { StyleSheet } from '@react-pdf/renderer'
import DCAImage from 'components/DCAImage'
import images from 'assets/images/images'
import { FontSizeX2, Fonts } from '../theme'

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 'auto',
  },
  date: {
    fontFamily: Fonts.narrBold,
    fontSize: FontSizeX2.p5,
    textAlign: 'right',
  },
})

const HeaderPDF = () => {
  return (
    <Row className='my-4'>
      <Col>
        <DCAImage style={styles.logo} src={images.logo} />
      </Col>
      <Col>
        <div style={styles.date}>{moment().format('DD.MM.YYYY')}</div>
      </Col>
    </Row>
  )
}

export default HeaderPDF
