import { tr } from '../pdfHelper'

const technicalDataRow = (techLabels: string[][]) => {
  let str = ''

  const firstCol = (isFirstCol: boolean) => (isFirstCol ? 'padding-left: 5px;' : '')

  const emptyField = (value: string) => (value === '-' ? 'align-self: center;' : '')

  techLabels.forEach((labels) => {
    str += '<div class="compare-row">'
    labels.forEach((label, index) => {
      str += `
     <div class="compare-col" style="display:flex;">
        <label style="${firstCol(index === 0)} ${emptyField(label)}">${label}</label>
      </div>`
    })

    str += '</div>'
  })

  return str
}

export const getTechnicalData = (techLabels: string[][], translations: Record<string, string>) => {
  const str = technicalDataRow(techLabels)

  return `<div class="section">
    <h4>${tr(translations, 'technical_data', 'TECHNICAL DATA').toLocaleUpperCase()}</h4>
    <div class="compare-wrap">
      ${str}
    </div>
  </div>
  `
}
