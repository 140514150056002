import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import HorizontalRule from 'components/HorizontalRule'

type DetergentWarningsProps = {
  detergentWarnings: string[]
}

const DetergentWarnings = ({ detergentWarnings }: DetergentWarningsProps) => {
  const translations = useSelector(selectTranslations)
  const headline = translations?.detergent_warning ?? 'WARNINGS'

  const detergentWarningsTable: AkTableType = detergentWarnings.map((warning) => {
    return [
      {
        value: `&#9632; ${warning}`,
      },
    ]
  })

  const detergentWarningsTableEven = detergentWarningsTable.filter((_, index) => index % 2 === 0)
  const detergentWarningsTableOdd = detergentWarningsTable.filter((_, index) => index % 2 !== 0)

  return (
    <Container
      className='detergent-warning-wrapper pt-4'
      id={PRODUCT_DETAILS_SECTIONS.DETERGENT_WARNINGS}
    >
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>

      <HorizontalRule />

      <Row>
        <Col xs={12} md={6}>
          <AkTable table={detergentWarningsTableEven} />
        </Col>
        <Col xs={12} md={6}>
          <AkTable table={detergentWarningsTableOdd} />
        </Col>
      </Row>
    </Container>
  )
}

export default DetergentWarnings
