import { gql } from 'apollo-boost'

export const IS_PRODUCT_FINDER_AVAILABLE = (languageCode: string, countryCode: string) => gql`
    {
      isProductFinderAvailable(input: {
        countryCode: "${countryCode}",
        languageCode: "${languageCode}"
      })
      }
    `
export const GET_IS_PRODUCT_FINDER_AVAILABLE_FOR_PRODUCT_GROUP = (
  productGroupId: number,
  languageCode: string,
  countryCode: string,
) => gql`
      {
        isProductGroupHasProductFinder(input: {
          countryCode: "${countryCode}",
          languageCode: "${languageCode}"
          productGroupId: ${productGroupId},
        })
        }
      `
