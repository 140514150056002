import { getProductDetailDbById } from 'database/queries/product'
import { getProductDetailQuery } from 'services'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProductDetailItemType, ProductDetailResponseType } from 'types'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import useAnalytics from 'hooks/useAnalytics'
import useDataFetcher from 'hooks/useDataFetcher'
import LoadingPage from 'features/Common/screens/LoadingPage'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import ComparisonUI from './Comparison.ui'
import 'styles/pages/comparison.scss'

const Comparison = () => {
  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)
  const { setPageInfoAnalytics } = useAnalytics()

  const { fetchData } = useDataFetcher()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [productCompareDetails, setProductCompareDetails] = useState<ProductDetailItemType[]>([])

  const productComparison = translations.product_comparison ?? TRANSLATIONS.productComparison

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.COMPARISON,
      pageType: PAGE_TYPES.COMPARISON,
    })
  }, [])

  useEffect(() => {
    const getProductCompareDetails = async () => {
      try {
        const productDetailResponses = await Promise.all(
          productCompare.map(async (product) => {
            const productDetailResponse = await fetchData<number, ProductDetailResponseType>(
              getProductDetailQuery,
              getProductDetailDbById,
              Number(product.id),
              Number(product.id),
            )
            return productDetailResponse
          }),
        )

        const productDetails = productDetailResponses.map((response) => {
          if (response?.data) {
            return JSON.parse(response.data)
          } else {
            throw new Error('Error fetching product details')
          }
        })

        setProductCompareDetails(productDetails.filter(Boolean))
      } catch (error) {
        console.error('getProductCompareDetails', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (!productCompare || productCompare.length === 0) {
      setIsLoading(false)
      setProductCompareDetails([])
      return
    }

    getProductCompareDetails()
  }, [productCompare])

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }
    return <ComparisonUI productCompareDetails={productCompareDetails} />
  }

  return (
    <Container className='comparison-wrapper px-0' fluid>
      <Header pageId={PAGE_IDS.COMPARISON_SCREEN} />

      <Breadcrumb pageId={PAGE_IDS.COMPARISON_SCREEN} staticTitle={productComparison} />

      {renderContent()}
    </Container>
  )
}

export default Comparison
