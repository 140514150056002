import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import HorizontalRule from 'components/HorizontalRule'

type ApplicationsProps = {
  applications: string[]
}

const Applications = ({ applications }: ApplicationsProps) => {
  const translations = useSelector(selectTranslations)
  const headline = translations?.applications ?? 'APPLICATIONS'

  const applicationsTable: AkTableType = applications.map((application) => {
    return [
      {
        value: `&#9632; ${application}`,
      },
    ]
  })

  const applicationsTableEven = applicationsTable.filter((_, index) => index % 2 === 0)
  const applicationsTableOdd = applicationsTable.filter((_, index) => index % 2 !== 0)

  return (
    <Container className='applications-wrapper pt-4' id={PRODUCT_DETAILS_SECTIONS.APPLICATIONS}>
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>

      <HorizontalRule />

      <Row className='d-flex mt-3'>
        <Col xs={12} md={6}>
          <AkTable table={applicationsTableEven} />
        </Col>
        <Col xs={12} md={6}>
          <AkTable table={applicationsTableOdd} />
        </Col>
      </Row>
    </Container>
  )
}

export default Applications
