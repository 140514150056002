import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FavoriteFolderType } from 'types'
import { ALL_FOLDER, MY_FAVORITES_FOLDER } from 'config/constants'
import { setSelectedFolderId } from 'redux/reducers/favoritesReducer'
import { selectFavoritesFolder } from 'redux/selector/favoritesSelector'
import FavoriteFolder from '../components/FavoriteFolder'
import { useFavorites } from '../hooks/useFavorites'
import 'styles/components/favFolderTree.scss'

export type FolderTreeRef = {
  onCreateNewFolder: () => void
  onCanCreateNewFolder: () => void
}

type FavFolderTreeProps = {
  onActiveFolderData: (folder: FavoriteFolderType) => void
  isModal?: boolean
  onExportFolder: (folderId: number) => void
  onExportFolderShort: (fodlerId: number) => void
}

const FavFolderTree = forwardRef<FolderTreeRef, FavFolderTreeProps>((props, ref) => {
  const { isModal, onActiveFolderData, onExportFolder, onExportFolderShort } = props

  const dispatch = useDispatch()

  const { addFolderFavorites } = useFavorites()

  const folders = useSelector(selectFavoritesFolder)

  const [expandedFolders, setExpandedFolders] = useState<number[]>([])
  const [activeFolderId, setActiveFolderId] = useState<number>()
  const [isCanCreateFolder, setIsCanCreateFolder] = useState<boolean>(false)
  const [newFolderName, setNewFolderName] = useState<string>('')

  useEffect(() => {
    if (activeFolderId) {
      const activeFolderData = folders?.find((f) => f.id === activeFolderId)
      if (activeFolderData) {
        onActiveFolderData(activeFolderData)
      }
    }
  }, [activeFolderId, folders])

  useImperativeHandle(ref, () => ({
    /** Active create folder input */
    onCanCreateNewFolder: () => {
      setIsCanCreateFolder(!isCanCreateFolder)
    },

    /** Add folder to local DB and redux */
    onCreateNewFolder: () => {
      const newFolder: FavoriteFolderType = {
        id: (folders?.length ?? MY_FAVORITES_FOLDER.id) + 1,
        folderName: newFolderName,
        parentID: activeFolderId ?? MY_FAVORITES_FOLDER.id,
      }
      addFolderFavorites(newFolder)
      setIsCanCreateFolder(false)
    },
  }))

  const setActiveAndSelectedFolder = (folderId: number) => {
    setActiveFolderId(folderId)
    dispatch(setSelectedFolderId(folderId))
  }

  const handleExpand = (folderId: number) => {
    if (!folders || folderId === 0) return

    if (expandedFolders.includes(folderId)) {
      const folder = folders.find((f) => f.id === folderId)

      setExpandedFolders((prev) => prev.filter((id) => id !== folderId))
      if (folderId === activeFolderId && folder?.parentID) {
        setActiveAndSelectedFolder(folder.parentID)
      } else {
        setActiveAndSelectedFolder(folderId)
      }
    } else {
      setExpandedFolders((prev) => [...prev, folderId])
      setActiveAndSelectedFolder(folderId)
    }
  }

  useEffect(() => {
    handleExpand(MY_FAVORITES_FOLDER.id)
  }, [])

  if (!folders || folders.length === 0) return null

  const handleFolderNameChange = (folderName: string) => setNewFolderName(folderName)

  const rootFolders = folders.filter((f) => f.parentID === ALL_FOLDER.id)
  const allFolder = folders.find((f) => f.id === ALL_FOLDER.id)

  return (
    <div className='fav-folder-tree'>
      {rootFolders.map((rootFolder) => (
        <FavoriteFolder
          key={rootFolder.id}
          isModal={isModal}
          folder={rootFolder}
          folders={folders}
          expandedFolders={expandedFolders}
          activeFolderId={activeFolderId}
          isCanCreateFolder={isCanCreateFolder}
          onExportFolder={onExportFolder}
          onExportFolderShort={onExportFolderShort}
          onExpand={handleExpand}
          onFolderNameChange={handleFolderNameChange}
        />
      ))}
      {allFolder && !isModal && (
        <FavoriteFolder
          key={allFolder.id}
          isModal={isModal}
          folder={allFolder}
          folders={folders}
          expandedFolders={[]}
          activeFolderId={activeFolderId}
          isCanCreateFolder={isCanCreateFolder}
          onExpand={handleExpand}
          onExportFolder={onExportFolder}
          onExportFolderShort={onExportFolderShort}
          onFolderNameChange={handleFolderNameChange}
        />
      )}
    </div>
  )
})

FavFolderTree.displayName = 'FavFolderTree'

export default FavFolderTree
