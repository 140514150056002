import { gql } from 'apollo-boost'

export const GET_ONBOARDING = (languageCode: string, countryCode: string, year: number) => gql`
    {
      getOnboarding(input: {
          year: ${year},
          languageCode: "${languageCode}",
          countryCode: "${countryCode}"
      }) {
          productGroupId,
          productId
      }
    }
  `
