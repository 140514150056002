import { useEffect } from 'react'

export const APP_HEADER_ID = 'app-static-header'
export const APP_CONTENT_ID = 'app-content'
export const setStyleForContent = () => {
  const header = document.getElementById(APP_HEADER_ID)
  const content = document.getElementById(APP_CONTENT_ID)
  if (header && content) {
    content.style.paddingTop = `${header.offsetHeight}px`
  }
}

export const useStyleContent = () => {
  useEffect(() => {
    setStyleForContent()
    // Add resize event listener to handle window resizing
    window.addEventListener('resize', setStyleForContent)

    return () => {
      // Cleanup resize event listener
      window.removeEventListener('resize', setStyleForContent)
    }
  }, [])
}
