import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getDetergentWarnings = (detergentWarnings: string[], translations: TranslationsPDFType) => {
  if (!detergentWarnings || !detergentWarnings.length) return ''

  let leftStr = ''
  let rightStr = ''
  detergentWarnings.forEach((item, index) => {
    if (index % 2 === 0) {
      leftStr += `<tr><td>■ ${item}</td></tr>`
    } else {
      rightStr += `<tr><td>■ ${item}</td></tr>`
    }
  })

  return `<div class="detergent-warnings-sec">
      <h4>${tr(
        translations,
        'settings',
        '"DETERGENT WARNINGS & SAFETY RECOMMENDATIONS"',
      ).toLocaleUpperCase()}</h4>
      <div class="line"></div>
      <div class="row small" style="justify-content: space-around;">
        <table class="table" style="width: 49%; font-size: 10px;">
          <tbody>
            ${leftStr}
          </tbody>
        </table>
        <table class="table" style="width: 49%; font-size: 10px;">
          <tbody>
            ${rightStr}
          </tbody>
        </table>
      </div>
    </div>`
}

export default getDetergentWarnings
