import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import { TRANSLATIONS } from 'config/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'

type TechDataProps = {
  techDataTable: AkTableType
}

const TechDataCompare = ({ techDataTable }: TechDataProps) => {
  const translations = useSelector(selectTranslations)

  const [isShow, setIsShow] = useState<boolean>(true)

  const techDataTxt = translations.technical_data ?? TRANSLATIONS.technicalData

  const onShowFull = () => setIsShow(!isShow)

  if (!techDataTable || techDataTable.length === 0) return null

  return (
    <div className='comparison-tech-data'>
      <h6 className='mb-4'>
        {techDataTxt.toUpperCase()}

        {isShow ? (
          <span className='p-2' onClick={onShowFull}>
            <FontAwesomeIcon size='xs' icon={faAngleUp} />
          </span>
        ) : (
          <span className='p-2' onClick={onShowFull}>
            <FontAwesomeIcon size='xs' icon={faAngleDown} />
          </span>
        )}
      </h6>

      {isShow && <AkTable table={techDataTable} />}
    </div>
  )
}

export default TechDataCompare
