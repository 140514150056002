import { Font } from '@react-pdf/renderer'
import NarrBlack from '../../assets/fonts/ClanPro/ClanPro-NarrBlack.otf'
import ClanProNews from '../../assets/fonts/ClanPro/ClanPro-News.otf'
import ClanProBold from '../../assets/fonts/ClanPro/ClanPro-Bold.otf'
import ClanProBook from '../../assets/fonts/ClanPro/ClanPro-Book.otf'
import ClanProNarrBold from '../../assets/fonts/ClanPro/ClanPro-NarrBold.otf'

// Register font
Font.register({
  family: 'NarrBlack',
  src: NarrBlack,
})

Font.register({
  family: 'ClanProNews',
  src: ClanProNews,
})

Font.register({
  family: 'ClanProBold',
  src: ClanProBold,
})

Font.register({
  family: 'ClanProBook',
  src: ClanProBook,
})

Font.register({
  family: 'ClanProNarrBold',
  src: ClanProNarrBold,
})

export enum Colors {
  white = '#fff',
  black = '#000',
  red = '#ff0000',
  gray = '#6e6e6e',
  yellow = '#ffed00',
  background = '#e3e3e3',
}

export enum Fonts {
  narrBlack = 'NarrBlack',
  clanProNews = 'ClanProNews',
  narrBold = 'ClanProBold',
  clanProBook = 'ClanProBook',
  clanProNarrBold = 'ClanProNarrBold',
}

export enum FontSizeX2 {
  p1 = 2,
  p2 = 4,
  p3 = 6,
  p4 = 8,
  p5 = 10,
  p6 = 12,
  p7 = 14,
  p8 = 16,
  p9 = 18,
  p10 = 20,
}

export enum SpacingX4 {
  p1 = 4,
  p2 = 8,
  p3 = 12,
  p4 = 16,
  p5 = 20,
  p6 = 24,
  p7 = 28,
  p8 = 32,
  p9 = 36,
  p10 = 40,
  p12 = 48,
  p16 = 64,
  p20 = 80,
  p24 = 96,
  p32 = 128,
  p40 = 160,
  p48 = 192,
}
