import { ImageHeadlineTextType } from 'types'
import { formatAnchorTag } from '../pdfHelper'

export const getImageHeadlineText = (headlines: ImageHeadlineTextType[]) => {
  const images = headlines.map((headline) => headline.image)

  let str = '<div class="image-headline-text-wrap">'

  headlines.forEach((item, index) => {
    const headlineStr = item.headline ? `<h3>${item.headline}</h3>` : ''
    const descStr = item.text ? `<p>${formatAnchorTag(item.text)}</p>` : ''

    str += `
      <div class="card-wrap">
        <div class="card">
          <img src="${images[index]}" alt="" srcset=""/>
          ${headlineStr}
          ${descStr}
        </div>
      </div>
    `
  })

  str += '</div>'
  return str
}
