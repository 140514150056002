import { useSelector } from 'react-redux'
import { ContentAnchorItemType, ProductDetailItemType } from 'types'
import { COMMON, PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { selectAppOnline, selectTranslations } from 'redux/selector'

const ANCHOR_EXCLUDE_PAST_PRODUCT = [
  PRODUCT_DETAILS_SECTIONS.EQUIPMENT,
  PRODUCT_DETAILS_SECTIONS.ICONS,
  PRODUCT_DETAILS_SECTIONS.FEATURE_BENEFITS,
  PRODUCT_DETAILS_SECTIONS.DETERGENT_WARNINGS,
  PRODUCT_DETAILS_SECTIONS.APPLICATIONS,
  PRODUCT_DETAILS_SECTIONS.COMPATIBLE_MACHINES,
  PRODUCT_DETAILS_SECTIONS.CONFIGURABLE_COMPONENT,
]

const useAnchorLinks = () => {
  const translations = useSelector(selectTranslations)
  const isOnline = useSelector(selectAppOnline)

  const ANCHORS_DATA: ContentAnchorItemType[] = [
    {
      targetId: PRODUCT_DETAILS_SECTIONS.DESCRIPTION,
      title: translations.description || 'DESCRIPTION',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.EQUIPMENT,
      title: translations.equipment || 'EQUIPMENT',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.FEATURE_BENEFITS,
      title: translations.feature_and_benefits || 'FEATURES AND BENEFITS',
    },
    { targetId: PRODUCT_DETAILS_SECTIONS.DOCUMENTS, title: translations.downloads || 'DOWNLOADS' },
    { targetId: PRODUCT_DETAILS_SECTIONS.VIDEOS, title: translations.videos || 'VIDEOS' },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.ACCESSORIES,
      title: translations.accessories || 'ACCESSORIES',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.CLEANING_AGENTS,
      title: translations.cleaning_agents || 'CLEANING AGENTS',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.APPLICATIONS,
      title: translations.applications || 'APPLICATIONS',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.DETERGENT_WARNINGS,
      title: translations.detergent_warning || 'WARNINGS',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.COMPATIBLE_MACHINES,
      title: translations.compatible_machines || 'COMPATIBLE MACHINES',
    },
    {
      targetId: PRODUCT_DETAILS_SECTIONS.CONFIGURABLE_COMPONENT,
      title: translations.configurable_components || 'CONFIGURABLE COMPONENTS',
    },
  ]

  const filterFn = (anchor: ContentAnchorItemType, productData: ProductDetailItemType): boolean => {
    const isPastProduct = productData.productType === COMMON.PAST_PRODUCT
    const { targetId } = anchor
    targetId as keyof ProductDetailItemType

    if (isPastProduct && targetId !== PRODUCT_DETAILS_SECTIONS.VIDEOS) {
      return !ANCHOR_EXCLUDE_PAST_PRODUCT.includes(targetId)
    }

    if (targetId === PRODUCT_DETAILS_SECTIONS.VIDEOS) {
      return (productData.videos?.length > 0 && isOnline) ?? false
    }

    if (targetId === PRODUCT_DETAILS_SECTIONS.COMPATIBLE_MACHINES) {
      return (
        productData.compatibleProducts?.current?.length > 0 ||
        productData.compatibleProducts?.past?.length > 0
      )
    }

    return productData[targetId]?.length ?? false
  }

  const getAnchorDataFilter = (productData: ProductDetailItemType): ContentAnchorItemType[] => {
    const anchorDataFilter = ANCHORS_DATA.filter((anchor) => filterFn(anchor, productData))

    if (productData.equipment?.length === 0 && productData.icons?.length > 0) {
      const equipmentAnchor = {
        targetId: PRODUCT_DETAILS_SECTIONS.EQUIPMENT,
        title: translations.equipment || 'EQUIPMENT',
      }

      if (
        !anchorDataFilter.some(({ targetId }) => targetId === PRODUCT_DETAILS_SECTIONS.EQUIPMENT)
      ) {
        anchorDataFilter.unshift(equipmentAnchor)
      }
    }

    if (
      !anchorDataFilter.some(({ targetId }) => targetId === PRODUCT_DETAILS_SECTIONS.DESCRIPTION)
    ) {
      const descriptionAnchor = {
        targetId: PRODUCT_DETAILS_SECTIONS.DESCRIPTION,
        title: translations.description || 'DESCRIPTION',
      }
      anchorDataFilter.unshift(descriptionAnchor)
    }

    return anchorDataFilter
  }

  return { ANCHORS_DATA, getAnchorDataFilter }
}

export default useAnchorLinks
