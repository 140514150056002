import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import { Range } from 'react-input-range'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import RangeInput from './RangeInput'

type Props = {
  filterData: FilterItem[]
  onFilter: (selectedCheckbox: SelectedCheckbox, selectedFilters: SelectedFilter) => void
}

export type FilterItem = {
  label: string
  type: 'slider' | 'checkbox' | 'checkboxsingle' | 'group'
  key?: string
  filter?: FilterItem[]
  unit?: string
  min?: number
  max?: number
}

export type SelectedCheckbox = Array<string>

export type SelectedFilter = {
  [key: string]: {
    min: number
    max: number
    unit: string
  }
}

const ProductFilter = (props: Props): React.ReactElement => {
  const { filterData, onFilter } = props

  const translations = useSelector(selectTranslations)

  const filterTxt = translations ? translations.filter : 'Filter'
  const resetFilter = translations ? translations.reset_filter : 'Reset Filter'
  const rangeFilters: FilterItem[] = filterData?.filter((item) => item.type === 'slider')
  const checkboxGroups: FilterItem[] = filterData?.filter((item) => item.type === 'group')

  const [isShowFull, setIsShowFull] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter>({})
  const [selectedCheckbox, setSelectedCheckbox] = useState<SelectedCheckbox>([])

  useEffect(() => {
    onFilter(selectedCheckbox, selectedFilters)
  }, [selectedFilters, selectedCheckbox])

  const toggleShow = () => {
    setIsShowFull((prev) => !prev)
  }

  const onChangeRange = (filterKey: string, unit: string, value: Range) => {
    const rangeData = { min: value.min, max: value.max, unit }
    setSelectedFilters((prev) => ({ ...prev, [filterKey]: rangeData }))
  }

  const onSelectCheckbox = (data: FilterItem) => {
    const { key } = data
    const filterKey = key?.replace('filterValues.', '') ?? ''

    if (!selectedCheckbox.includes(filterKey)) {
      setSelectedCheckbox((prev) => [...prev, filterKey])
    } else {
      setSelectedCheckbox((prev) => prev.filter((item) => item !== filterKey))
    }
  }

  const onResetFilter = (filterKey?: string) => {
    if (filterKey && selectedFilters[filterKey]) {
      const newSelectedFilters = { ...selectedFilters }
      delete newSelectedFilters[filterKey]
      setSelectedFilters(newSelectedFilters)
    } else {
      setSelectedCheckbox([])
      setSelectedFilters({})
    }
  }

  const renderHeader = () => {
    return (
      <Row className='m-0'>
        <Col md='11' className='filter-heading'>
          {filterTxt}
        </Col>
        <Col md='1' className='d-flex justify-content-end pt-2'>
          {!isShowFull ? (
            <span className='cursor-pointer' role='button' onClick={toggleShow}>
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          ) : (
            <span className='cursor-pointer' role='button' onClick={toggleShow}>
              <FontAwesomeIcon icon={faAngleUp} />
            </span>
          )}
        </Col>
      </Row>
    )
  }

  const renderRangeFilters = () => {
    if (!rangeFilters || rangeFilters.length === 0) return null

    return rangeFilters.map((range) => {
      const { min = 0, max = 0, label, unit, key } = range

      return (
        <Col xs='3' md='3' className='mb-2' key={key}>
          <div className='filter-loop-inner p-4 pb-5'>
            <p>{` ${label} (${unit}) `}</p>
            <RangeInput
              rangeData={{ min, max }}
              rangeSelected={selectedFilters[key || '']}
              filterKey={key || ''}
              unit={unit || ''}
              onChangeRange={onChangeRange}
            />
          </div>
        </Col>
      )
    })
  }

  const renderCheckboxGroups = useCallback(() => {
    if (!checkboxGroups || checkboxGroups.length === 0) return null
    return checkboxGroups.map((group, groupIndex) => {
      const { label, filter } = group

      return (
        <Col xs='3' md='3' key={`group${groupIndex}`} className='mb-2'>
          <Dropdown>
            <Dropdown.Toggle variant='success' id='dropdown-basic' className='dropdown-button'>
              {label}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filter?.map((checkbox) => {
                const { label, key } = checkbox
                const filterKey = key?.replace('filterValues.', '') || ''
                const isChecked = selectedCheckbox.includes(filterKey)
                return (
                  <Dropdown.Item
                    key={key}
                    onClick={(e) => {
                      e.stopPropagation()
                      onSelectCheckbox(checkbox)
                    }}
                  >
                    <Row>
                      <Col md={1}>
                        <input
                          type='checkbox'
                          className={isChecked ? 'checked' : ''}
                          checked={isChecked}
                          readOnly
                        />
                      </Col>
                      <Col md={10}>
                        <div className='checkbox-label'>{label}</div>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      )
    })
  }, [selectedCheckbox])

  const renderSelectedFilter = useCallback(() => {
    return (
      <Row className='filter-list-section'>
        <Col className='p-0'>
          {(Object.keys(selectedFilters).length > 0 || selectedCheckbox.length > 0) && (
            <Button onClick={() => onResetFilter()}>{resetFilter}</Button>
          )}

          {Object.keys(selectedFilters).map((filterKey, index) => {
            const dataFilter = selectedFilters[filterKey]
            const { min, max, unit } = dataFilter
            return (
              <span key={index} className='dca-bg pl-5 mr-2 list-element my-1'>
                {`${min} - ${max} ${unit}`}{' '}
                <span
                  role='button'
                  tabIndex={0}
                  onClick={() => onResetFilter(filterKey)}
                  className=' ml-3 mr-3 cross-icon'
                >
                  <i className='karcher-icon'>&#xe815;</i>
                </span>
              </span>
            )
          })}
        </Col>
      </Row>
    )
  }, [selectedFilters, selectedCheckbox])

  if (!filterData || filterData.length === 0) return <></>

  return (
    <Container className='filter-wrapper'>
      <Row className='filter-content'>
        {renderHeader()}

        {isShowFull && (
          <Row>
            {renderRangeFilters()}
            {renderCheckboxGroups()}
          </Row>
        )}
      </Row>

      {renderSelectedFilter()}
    </Container>
  )
}

export default ProductFilter
