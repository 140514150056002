import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import { TRANSLATIONS } from 'config/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

type EquipmentsProps = {
  equipmentsTable: AkTableType
}

const EquipmentCompare = ({ equipmentsTable }: EquipmentsProps) => {
  const translations = useSelector(selectTranslations)

  const [isShow, setIsShow] = useState<boolean>(true)

  const equipmentsTxt = translations.equipment ?? TRANSLATIONS.technicalData

  const onShowFull = () => setIsShow(!isShow)

  if (!equipmentsTable || equipmentsTable.length === 0) return null

  return (
    <div className='comparison-equipments'>
      <h6 className='mb-4'>
        {equipmentsTxt.toUpperCase()}

        {isShow ? (
          <span className='p-2' onClick={onShowFull}>
            <FontAwesomeIcon size='xs' icon={faAngleUp} />
          </span>
        ) : (
          <span className='p-2' onClick={onShowFull}>
            <FontAwesomeIcon size='xs' icon={faAngleDown} />
          </span>
        )}
      </h6>

      {isShow && <AkTable table={equipmentsTable} />}
    </div>
  )
}

export default EquipmentCompare
