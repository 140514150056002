import { ProductDetailItemType } from 'types'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const descriptionDataRow = (products: ProductDetailItemType[]) => {
  let str = ''

  products.forEach((item) => {
    if (!item) {
      str += `
        <div class="compare-col">
        </div>
      `
    } else {
      let desc = ''
      item.texts.forEach((item1) => {
        if (item1.type === 'long') desc = item1.value
      })

      str += `
          <div class="compare-col">
            <label>${desc}</label>
          </div>
      `
    }
  })
  return `
    <div class="compare-row" style="background-color: #fff;">
      ${str}
    </div>
  `
}

export const getDescription = (
  products: ProductDetailItemType[] = [],
  translations: TranslationsPDFType,
) => {
  let str = ''

  str += descriptionDataRow(products)

  return `<div class="desc-wrap">
      <h4>${tr(translations, 'description', 'DESCRIPTION').toLocaleUpperCase()}</h4>
      <div class="compare-wrap">
        ${str}
      </div>
    </div>
  `
}
