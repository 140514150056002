import { gql } from 'apollo-boost'

export const GET_PAGE_CONTENT = (
  pageId: string | number,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
      {
        getPageContent (input: {
          pageId: ${pageId},
          languageCode: "${languageCode}",
          countryCode: "${countryCode}",
          year: ${year}
        }
        ) {
          pageId
          style
          export
          year
          country
          language
          createdAt
          content {
            id
            type
            config
          }
        }
      }
    `
