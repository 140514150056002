import { gql } from 'apollo-boost'
import { API_YEAR } from 'config/constants'
import { downloadType } from 'types/export.Type'

export const GET_EXPORT_URL = (
  productId: string | number,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
      {
        getExportUrl(input: {
          productId: ${productId},
          languageCode: "${languageCode}",
          countryCode: "${countryCode}",
          year:${year},
        }){
          url
        }
    }
  `
export const GET_ASSET_EXPORT_URL = (
  type: downloadType,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
      query{
          getAssetExport(input: {
            languageCode: "${languageCode}",
            countryCode: "${countryCode}",
            year: ${year}
            type: "${type}"
          }){
            URL
          }
      }
    `


export const GET_IMAGE_CHANGE_LOG_PRESIGNED_URL = (
  type: string,
  languageCode: string,
  countryCode: string,
  year: number
) => gql`
  {
    getImageChangeLogPresignedUrl(input: {
      countryCode: "${countryCode}",
      languageCode: "${languageCode}",
      type: ${type},
      year: ${year}
    })
  }
  `;

export const GET_DOWNLOAD_EXPORT = (
  languageCode: string,
  countryCode: string,
  year: number,
  categories: string[],
) =>
  gql`
    {
    getDownloadExport(input: {
    isMobile: false,
    languageCode: "${languageCode}",
    countryCode: "${countryCode}",
    year: ${year}
    }){
        ${categories.join(',')}
    }
    }
    `



export const GET_IMAGE_ASSET_PRESIGNED_URLS = (
  type: 'COMMON' | 'HOME_AND_GARDEN' | 'PROFESSIONAL',
  languageCode: string,
  countryCode: string
) => gql`
  query {
    getImageAssetPresignedUrls(
      input: {
        countryCode: "${countryCode}"
        languageCode: "${languageCode}"
        year: ${API_YEAR}
        types: [${type}]
      }
    )
  }
`;