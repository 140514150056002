import { ProductDetailItemType } from 'types'
import HeaderPDF from '../components/HeaderPDF'
import DividerPDF from '../components/DividerPDF'
import Description from 'features/Product/components/Description/Description'
import React from 'react'
import useAnchorLinks from 'features/Product/hooks/useAnchorLinks'
import { COMMON, PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import EquipmentAndIcons from 'features/Product/components/EquipmentAndIcons'
import FeaturesAndBenefit from 'features/Product/components/FeaturesAndBenefit'
import Documents from 'features/Product/components/Documents'
import Accessories from 'features/Product/components/Accessories/Accessories'
import Videos from 'features/Product/components/Videos'
import DetergentWarnings from 'features/Product/components/DetergentWarning'
import Applications from 'features/Product/components/Applications'
import CompatibleMachines from 'features/Product/components/CompatibleMachines'
import ConfigurableComponents from 'features/Product/components/ConfigurableComponents'
import BreadCrumbPDF from '../components/BreadCrumbPDF'
import useProductDetail from 'redux/hooks/productDetail'

type ProductDetailPDFWrapperProps = {
  pageId: number
  onDownloadStatus: (status: boolean) => void
}

const ProductDetailPDFWrapper = ({ pageId, onDownloadStatus }: ProductDetailPDFWrapperProps) => {
  const { productDetail, loading, error } = useProductDetail(pageId)

  const hasDataLoaded = !!productDetail && !loading && !error

  if (hasDataLoaded) {
    onDownloadStatus(true)
  }

  if (error) {
    onDownloadStatus(false)
  }

  if (hasDataLoaded) {
    return (
      <div className='product-detail-container'>
        <ProductDetailPDF productDetails={productDetail} pageId={pageId} />
      </div>
    )
  }
  return null;
}



type ProductDetailPDF = {
  pageId?: number
  productDetails: ProductDetailItemType
}

const ProductDetailPDF = ({ pageId, productDetails }: ProductDetailPDF) => {
  const { getAnchorDataFilter } = useAnchorLinks()
  const anchorsData = getAnchorDataFilter(productDetails)
  const isPastProduct = productDetails?.productType === COMMON.PAST_PRODUCT

  const renderProductItem = (targetSection: string) => {
    switch (targetSection) {
      case PRODUCT_DETAILS_SECTIONS.DESCRIPTION:
        if (isPastProduct) return null
        return <Description productDetails={productDetails} />

      case PRODUCT_DETAILS_SECTIONS.EQUIPMENT:
        if (isPastProduct) return null
        return (
          <EquipmentAndIcons equipment={productDetails.equipment} icons={productDetails.icons} />
        )

      case PRODUCT_DETAILS_SECTIONS.FEATURE_BENEFITS:
        if (isPastProduct) return null

        return <FeaturesAndBenefit featureBenefits={productDetails.featureBenefits} />

      case PRODUCT_DETAILS_SECTIONS.DOCUMENTS:
        return <Documents documents={productDetails.documents} />

      case PRODUCT_DETAILS_SECTIONS.ACCESSORIES:
        return (
          <Accessories
            accessories={productDetails.accessories}
            accessoryCategories={productDetails.acc_categories}
          />
        )

      case PRODUCT_DETAILS_SECTIONS.DETERGENTS:
        return (
          <Accessories
            accessories={productDetails.detergents}
            detergentCategories={productDetails.det_categories}
          />
        )

      case PRODUCT_DETAILS_SECTIONS.VIDEOS:
        return <Videos videos={productDetails.videos} />

      case PRODUCT_DETAILS_SECTIONS.DETERGENT_WARNINGS:
        if (isPastProduct) return null
        return <DetergentWarnings detergentWarnings={productDetails.detergentWarnings} />

      case PRODUCT_DETAILS_SECTIONS.APPLICATIONS:
        if (isPastProduct) return null
        return <Applications applications={productDetails.applications} />

      case PRODUCT_DETAILS_SECTIONS.COMPATIBLE_MACHINES:
        if (isPastProduct) return null
        return <CompatibleMachines compatibleProducts={productDetails.compatibleProducts} />

      case PRODUCT_DETAILS_SECTIONS.CONFIGURABLE_COMPONENT:
        if (isPastProduct) return null
        return (
          <ConfigurableComponents configurableComponents={productDetails.configurableComponents} />
        )

      default:
        break
    }
  }

  const renderProductSection = () => {
    return (
      <div>
        {anchorsData.map((item) => (
          <React.Fragment key={item.targetId}>{renderProductItem(item.targetId)}</React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <>
      <HeaderPDF />

      <DividerPDF />

      <BreadCrumbPDF pageId={pageId} />

      {renderProductSection()}
    </>
  )
}

export { ProductDetailPDFWrapper, ProductDetailPDF }
