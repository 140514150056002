import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  getProductFinderAvailability,
  getProductFinderAvailabilityFailure,
  getProductFinderAvailabilitySuccess,
  getProductGroupProductFinder,
  getProductGroupProductFinderFailure,
  getProductGroupProductFinderSuccess,
} from 'redux/reducers/productFinderReducer'
import { selectAppOnline } from 'redux/selector'
import { isProductFinderAvailableQuery, isProductGroupHasProductFinderQuery } from 'services'
import {
  IsProductFinderAvailableResponseType,
  IsProductGroupHasProductFinderResponseType,
} from 'types'

function* fetchProductFinderAvailability() {
  try {
    const data: IsProductFinderAvailableResponseType = yield call(isProductFinderAvailableQuery)
    if (data) {
      yield put(getProductFinderAvailabilitySuccess(data.isProductFinderAvailable))
    } else {
      yield put(getProductFinderAvailabilityFailure())
    }
  } catch (error) {
    console.error('Error fetching product finder availability:', error)
    yield put(getProductFinderAvailabilityFailure())
  }
}

function* fetchProductGroupProductFinder(action: PayloadAction<number>) {
  try {
    const isOnline: boolean = yield select(selectAppOnline)
    const productGroupId = action.payload

    let isAvailable = false

    if (isOnline) {
      const data: IsProductGroupHasProductFinderResponseType = yield call(
        isProductGroupHasProductFinderQuery,
        productGroupId,
      )
      isAvailable = data.isProductGroupHasProductFinder
    }
    yield put(getProductGroupProductFinderSuccess({ productGroupId, isAvailable }))
  } catch (error) {
    console.error('Error fetching product finder for product group:', error)
    yield put(getProductGroupProductFinderFailure())
  }
}

export default function* productFinderSaga() {
  yield takeLatest(getProductFinderAvailability.type, fetchProductFinderAvailability)
  yield takeLatest(getProductGroupProductFinder.type, fetchProductGroupProductFinder)
}
