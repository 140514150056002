import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers'
import { ASSET_KEYS, DOCUMENT_KEYS, DocumentType, DownloadInfo } from './download.reducer'

export const selectLatestDownloadTime = () => {
  const download = useSelector((state: RootState) => state.download)
  return Math.max(0, ...DOCUMENT_KEYS.map((key) => download[key]?.downloadFinishedAt || 0))
}

export const selectDownloadData = () => {
  const download = useSelector((state: RootState) => state.download)
  return [
    ...DOCUMENT_KEYS.map((key) => parseDownloadPercent(download[key])),
    ...ASSET_KEYS.map((key) => parseToAssetData(download, key)),
  ].filter(Boolean) as DownloadInfo[]
}

const parseDownloadPercent = (download: DownloadInfo | null) => {
  if (!download) return null
  if (download.state !== 'DONE') {
    return { ...download, percent: Math.min(download.percent, 0.99) }
  }
  return download
}
export const parseToAssetData = (downloadData: any, assetType: DocumentType) => {
  const keys = Object.keys(downloadData)
  const assetKeys = keys.filter((i) => i.includes(assetType) && downloadData[i])

  const hasAssetInfo = assetKeys.length > 0
  if (!hasAssetInfo) return null

  const combineTask: DownloadInfo = {
    id: assetType,
    state: 'DOWNLOADING',
    percent: 0,
    bytesWritten: 0,
    totalBytes: 0,
  }

  const allTaskAreValid = assetKeys.every((key) => downloadData[key]?.totalBytes)

  if (allTaskAreValid) {
    assetKeys.forEach((key) => {
      const task = downloadData[key]
      combineTask.bytesWritten += task.bytesWritten
      combineTask.totalBytes += task.totalBytes
      combineTask.percent += task.percent
    })

    const isAllTaskDone = assetKeys.every((key) => downloadData[key].state === 'DONE')
    const isSomeTaskPaused = assetKeys.some((key) => downloadData[key].state === 'PAUSED')
    if (isAllTaskDone) {
      combineTask.state = 'DONE'
    }
    if (isSomeTaskPaused) {
      combineTask.state = 'PAUSED'
    }

    combineTask.percent /= assetKeys.length
    combineTask.createdAt = downloadData[assetKeys[0]].createdAt
  }
  return parseDownloadPercent(combineTask)
}
