import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PageContentResponseType, ProductGroupDataType, ProductListItemType } from 'types'
import { LAYOUTS } from 'config/constants'
import { isObjectEmpty } from 'config/utils/CommonFunction'
import { selectStructure, selectTranslations } from 'redux/selector'
import { useAppContext } from 'context/AppContext'
import { initMarking } from 'features/Marking/markingFunctions'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { createProductListPDF } from 'features/NewPDF/ProductListPDF/ProductListPDF'
import Ribbon from 'features/Product/components/Ribbon'
import ProductFilter, {
  FilterItem,
  SelectedCheckbox,
  SelectedFilter,
} from 'features/Product/screens/ProductList/ProductFilter'
import ButtonScrollToTop from 'components/ButtonScrollToTop'
import ProductItem from './ProductItem'
import 'styles/pages/productList.scss'

type UIProps = {
  data: PageContentResponseType | object
  productList: ProductListItemType[]
  productGroup: ProductGroupDataType | undefined
  onGoNextPage: (pageId: number) => void
  onAddProductToComparison: (
    event: React.MouseEvent<HTMLDivElement>,
    product: ProductListItemType,
  ) => void
}

type LayoutConfigType = {
  filter: FilterItem[]
  productgroupId: number
  ribbon: boolean
  ribbonButtonTarget?: number
}

const ProductListUI = (props: UIProps): React.ReactElement => {
  const { data, productList, productGroup, onGoNextPage, onAddProductToComparison } = props

  const { showModal, hideModal } = useGlobalModalContext()
  const structure = useSelector(selectStructure)
  const translations = useSelector(selectTranslations)

  const [listProductLocal, setListProductLocal] = useState<ProductListItemType[]>(productList)

  const {
    setState,
    state: { tourActive },
  } = useAppContext()

  useEffect(() => {
    initMarking()
    /** Wait until all the DOM render finish */
    setTimeout(() => {
      if (tourActive) {
        setState({ run: true, stepIndex: 5 })
      }
    }, 300)
  }, [])

  if (!('content' in data)) return <></>

  const layoutData = data.content
  const productListObj = layoutData.find((item) => item.type === LAYOUTS.PRODUCT_LIST)
  const layoutConfig: LayoutConfigType = JSON.parse(productListObj?.config ?? '{}')
  const priceRemarkTitle =
    translations.price_remark ?? '*recommended retail price. Prices by the dealer may vary.'

  const onFilter = (selectedCheckbox: SelectedCheckbox, selectedFilters: SelectedFilter) => {
    let productsFilter = productList
    if (!isObjectEmpty(selectedFilters)) {
      Object.keys(selectedFilters).forEach((key) => {
        if (key === 'price') {
          productsFilter = productsFilter.filter((product) => {
            const price = product.priceGross
            if (!price) return false

            return price >= selectedFilters.price.min && price <= selectedFilters.price.max
          })
        } else {
          productsFilter = productsFilter.filter((product) => {
            const shortKey = key.replace('filterValues.', '')
            const filterValues = JSON.parse(product.filterValues ?? '{}')
            const filterValue = filterValues[shortKey]
            if (!filterValue) return false

            return (
              filterValue >= selectedFilters[key].min && filterValue <= selectedFilters[key].max
            )
          })
        }
      })
    }

    if (selectedCheckbox.length > 0) {
      const objectCheckbox = new Set(selectedCheckbox)
      productsFilter = productsFilter.filter((product) => {
        const filterValues = JSON.parse(product.filterValues ?? '{}')
        return Object.keys(filterValues).some((key) => objectCheckbox.has(key))
      })
    }
    setListProductLocal(productsFilter)
  }

  const renderRibbon = () => {
    if (!layoutConfig.ribbon || !productGroup) {
      return <div />
    }

    const { name, texts } = productGroup
    const groupHeaderText = texts.find((text) => text.type === 'header')
    const subHeadline = groupHeaderText?.headline ?? ''
    const description = groupHeaderText?.text ?? ''
    const targetId = layoutConfig?.ribbonButtonTarget

    const ribbonData = { headline: name, subHeadline, description }

    const onAddFavorite = () => {
      showModal('FAVORITES_MODAL', {
        showModal: true,
        itemName: name,
        pageContent: data,
        handleClose: hideModal,
      })
    }

    const exportPDF = (onDone: () => void) => {
      createProductListPDF({
        pageContent: data,
        structure,
        products: productList,
        translations,
        ribbonData,
      }, onDone)
    }

    return (
      <Ribbon
        pageId={data.pageId}
        targetId={targetId}
        {...ribbonData}
        onAddFavorite={onAddFavorite}
        onExportPDF={exportPDF}
      />
    )
  }

  const renderFilter = () => {
    return (
      <div className='pt-4'>
        <ProductFilter filterData={layoutConfig.filter} onFilter={onFilter} />
      </div>
    )
  }

  const renderProductList = () => {
    if (!listProductLocal) return null

    return (
      <Container className='py-4'>
        <Row xs={1} sm={2} md={4} className='g-4'>
          {listProductLocal.map((data) => (
            <ProductItem
              product={data}
              key={data.id}
              onGoNextPage={onGoNextPage}
              onAddProductToComparison={onAddProductToComparison}
            />
          ))}
        </Row>
      </Container>
    )
  }

  const renderFooter = () => {
    const priceExist = listProductLocal.find((item) => item.priceNet)
    return (
      <>
        <div className='d-flex justify-content-between p-5 align-items-center'>
          {priceExist && (
            <p
              style={{ fontFamily: 'Clan Pro News', textAlign: 'left', margin: 0, marginLeft: 65 }}
            >
              {priceRemarkTitle}
            </p>
          )}

          <ButtonScrollToTop />
        </div>
      </>
    )
  }

  return (
    <>
      {renderRibbon()}

      {renderFilter()}

      {renderProductList()}

      {renderFooter()}
    </>
  )
}

export default ProductListUI
