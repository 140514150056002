import { ConfigurableItemType } from 'types'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getConfigurableComponents = (
  configurableComponent: ConfigurableItemType[],
  translations: TranslationsPDFType,
) => {
  if (!configurableComponent || !configurableComponent.length) return ''

  let str = ''
  configurableComponent.forEach((item) => {
    str += `<tr>
        <td width="70px" class="bold" style="padding-right: 0px;">${item.name}:</td>
        <td style="padding-left: 0px;">${item.description}</td>
      </tr>`
  })

  return `<div class="configurable-component-sec">
      <h4>${tr(
        translations,
        'configurable_component',
        'CONFIGURABLE COMPONENTS',
      ).toLocaleUpperCase()}</h4>
      <div class="line"></div>
      <table class="table" style="width: 100%; font-size: 10px;">
        <tbody>
          ${str}
        </tbody>
      </table>
    </div>
  `
}

export default getConfigurableComponents
