import { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { RootState } from 'redux/reducers'
import { getPageContent } from 'redux/reducers/pageContentReducer'
import { selectPageContent } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import useLinkNavigation from 'hooks/useLinkNavigation'
import usePreviousValue from 'hooks/usePreviousValue'
import ErrorPage from 'features/Common/screens/ErrorPage'
import LoadingPage from 'features/Common/screens/LoadingPage'
import Page from 'components/Page/Page'
import ManualContentUI from './ManualContent.ui'

const ManualContent = (): React.ReactElement => {
  const dispatch = useDispatch()
  /**
   * This hook is used to navigate to the link in the html content
   */
  useLinkNavigation()

  const params = useParams<{ pageId: string }>()
  const { pageId } = params
  const pageIdNumber = Number(pageId)

  const previousPageId = usePreviousValue(pageIdNumber)
  const { setPageInfoAnalytics } = useAnalytics()

  const pageData = useSelector((state: RootState) => selectPageContent(state, pageIdNumber))
  const { data, error, loading } = pageData ?? {}
  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.MANUAL_CONTENT,
      pageType: PAGE_TYPES.MANUAL_CONTENT,
    })
  }, [])

  useEffect(() => {
    if (!previousPageId || previousPageId !== pageIdNumber) {
      dispatch(getPageContent(pageIdNumber))
    }
  }, [pageIdNumber, dispatch, previousPageId])

  const renderContent = () => {
    if (loading) {
      return <LoadingPage />
    }

    if (error || !data?.content) {
      return (
        <Container style={{ paddingTop: 200 }}>
          <ErrorPage />
        </Container>
      )
    }

    if (data) {
      return <ManualContentUI pageData={data} />
    }

    return null
  }

  return (
    <Page pageId={pageIdNumber} shouldPaddingTop={false}>
      {renderContent()}
    </Page>
  )
}

export default ManualContent
