const images = {
  loading: require('assets/images/loading.gif'),
  logo: require('assets/images/karcher_logo.png'),
  loaderDots: require('assets/images/loader_dots.gif'),
  loaderIcon: require('assets/images/Download_Animation.gif'),
  square: require('assets/images/square.png'),
  infoBlack: require('assets/images/Info_black.png'),
  infoYellow: require('assets/images/info_yellow.png'),
  productFinder: require('assets/images/product_finder_logo.jpg'),
  imageNotFound: require('assets/images/image_not_found.png'),
  tick: require('assets/images/tick.png')
}

export default images
