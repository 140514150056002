import React, { useEffect } from 'react'
import { AppDataKeyEnum, GET_APP_DATA } from 'config/libs/globals'
import { getBusinessHeadlineDb } from 'database/queries/country'
import { PAGE_IDS } from 'config/constants'
import HeaderUI from './Header.ui'
import useNavigation from 'hooks/useNavigation'

export type HeadlineDataType = {
  pageId: number
  headline: string
}

type Props = {
  pageId: number
  id?: string
}

const Header = (props: Props) => {
  const { pageId } = props
  const { onDynamicNavigate } = useNavigation()

  const [headlineData, setHeadlineData] = React.useState<HeadlineDataType | undefined>()

  useEffect(() => {
    const init = async () => {
      let headlineData = GET_APP_DATA(AppDataKeyEnum.businessHeadline) as HeadlineDataType
      if (!headlineData) {
        headlineData = (await getBusinessHeadlineDb()) as HeadlineDataType
      }
      if (headlineData?.headline) {
        setHeadlineData(headlineData)
      }
    }
    init()
  }, [])

  const onGoRootPage = () => {
    if (headlineData?.pageId) {
      onDynamicNavigate(headlineData?.pageId)
    }
  }

  const onGoProductFinder = () => {
    onDynamicNavigate(PAGE_IDS.PRODUCT_FINDER)
  }

  return (
    <HeaderUI
      headlineData={headlineData}
      pageId={pageId}
      onGoProductFinder={onGoProductFinder}
      onGoRootPage={onGoRootPage}
    />
  )
}

export default Header
