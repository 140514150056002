import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'

const PrivacyPolicy = () => {
  const translations = useSelector(selectTranslations)
  const privacyStatement = translations?.privacy_statement ?? 'Privacy Statement'
  const privacyPolicyText = translations?.privacy_policy_text ?? <span />

  return (
    <Container className='cookie-policy-screen px-0' fluid>
      <Header pageId={PAGE_IDS.SETTINGS} />
      <Breadcrumb pageId={PAGE_IDS.SETTINGS} staticTitle={privacyStatement} />
      <Container>
        <br />
        <h1>{privacyStatement.toUpperCase()}</h1>
        <br />
        <div dangerouslySetInnerHTML={{ __html: privacyPolicyText }} />
      </Container>
    </Container>
  )
}

export default PrivacyPolicy
