import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'

enum AppDataKeyEnum {
  userToken = 'userToken',
  currentLanguage = 'currentLanguage',
  currentCountry = 'currentCountry',
  appLang = 'appLang',
  businessHeadline = 'businessHeadline',
}

type AppDataTypes = {
  [key: string]: string | object
}

//* Application global variables */
const LIVE = true
const SERVER_URL = process.env.REACT_APP_GRAPHQL_API_URL || ''
const IMG_BASE_URL = process.env.REACT_APP_MEDIA_URL
const USER_PREFERENCE_WIFI_ONLY = 'downloadUsingWifiOnly';

const appData: AppDataTypes = {
  businessHeadline: '',
  userToken: '',
  currentLanguage: '',
  currentCountry: '',
  appLang: {},
}

export type BackendConfigType = {
  serverUrl: string
  authorizerKey: string
}

export const NEW_BACKEND_CONFIG: BackendConfigType = {
  serverUrl: process.env.REACT_APP_NEW_GRAPHQL_API_URL ?? '',
  authorizerKey: process.env.REACT_APP_NEW_GRAPHQL_API_KEY ?? '',
}

const SET_APP_DATA = (key: string, value: string | object) => {
  appData[key] = value
}

const GET_APP_DATA = (key: string) => appData[key]

// New graphql client
const newCache = new InMemoryCache({
  dataIdFromObject: () => Math.random().toString(),
  addTypename: false,
})

let newGraphQLClient: ApolloClient<unknown>

const getGraphQLClient = () => {
  if (!NEW_BACKEND_CONFIG.authorizerKey) {
    throw new Error('X-Api-Key is not defined')
  }
  if (newGraphQLClient) {
    return newGraphQLClient
  }

  newGraphQLClient = new ApolloClient({
    uri: NEW_BACKEND_CONFIG.serverUrl,
    cache: newCache,
    headers: {
      'X-Api-Key': NEW_BACKEND_CONFIG.authorizerKey,
    },
  })
  return newGraphQLClient
}

export {
  AppDataKeyEnum,
  getGraphQLClient,
  LIVE,
  SERVER_URL,
  IMG_BASE_URL,
  GET_APP_DATA,
  SET_APP_DATA,
  USER_PREFERENCE_WIFI_ONLY
}
