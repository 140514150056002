import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAppOnline } from 'redux/selector'
import { checkInternetConnection } from 'components/ConnectionHandler'

interface Props {
  translations?: { not_available_online: string }[]
}

const ErrorPage: React.FC<Props> = ({ translations }) => {
  const isOnlineFlag = useSelector(selectAppOnline)

  useEffect(() => {
    const recheckInternetConnection = async () => {
      const isConnected = await checkInternetConnection()
      if (isConnected !== isOnlineFlag) {
        window.location.reload() // reload the app to let the app knows the internet is lost
      }
    }
    // for windows devices, sometimes it doesn't know the internet is lost
    // so we need to recheck the internet connection
    if (window.electron?.isDesktop()) {
      recheckInternetConnection()
    }
  }, [isOnlineFlag])

  const onlineMessage =
    'Sorry, this content at the moment not available because you are offline. Please try again later.'
  let notAvailable =
    (translations && translations[0]?.not_available_online) ||
    'Sorry, this content at the moment not available. Please try again later.'
  notAvailable = !isOnlineFlag ? onlineMessage : notAvailable
  const reloadTxt = 'Reload Page'

  const onReload = () => {
    window.location.reload()
  }

  return (
    <div className='content-error text-center p-5'>
      <h6 style={{ fontWeight: 'bolder' }}>{notAvailable}</h6>

      <button className='app-button mt-3' onClick={onReload}>
        {reloadTxt.toUpperCase()}
      </button>
    </div>
  )
}

export default ErrorPage
