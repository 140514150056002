import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import { CountryItemType, CurrentCountryType, CurrentLanguageType } from 'types'
import 'styles/pages/languageSelection.scss'

export type CountryStateType = {
  currentCountryObj: CurrentCountryType
  currentLanguage: CurrentLanguageType
}

type Props = {
  listCountries: CountryItemType[]
  onGoBusinessUnitEntry: (currentCountry: CountryStateType) => void
}

const LanguageSelectionUI = (props: Props): React.ReactElement => {
  const { listCountries, onGoBusinessUnitEntry } = props

  const [country, setCountry] = useState<CountryStateType>({
    currentCountryObj: {
      name: '',
      code: '',
      languages: [],
    },
    currentLanguage: { languageName: '', languageCode: '' },
  })

  useEffect(() => {
    if (listCountries?.length > 0) {
      const defaultCountry = listCountries.find((item) => item.name === 'International')
      setCountry({
        currentCountryObj: defaultCountry!,
        currentLanguage: defaultCountry!.languages[0],
      })
    }
  }, [listCountries])

  function onCountryChange(selectedCountry: CountryItemType) {
    const currentCountryObj = {
      name: selectedCountry.name,
      code: selectedCountry.code,
      languages: selectedCountry.languages,
    }
    const currentLanguage = selectedCountry.languages[0]
    setCountry({ currentCountryObj, currentLanguage })
  }

  function onLanguageChange(selectedLanguage: CurrentLanguageType) {
    const currentLanguage = selectedLanguage
    setCountry((prevCountry) => ({ ...prevCountry, currentLanguage }))
  }

  if (listCountries?.length === 0) return <></>

  return (
    <div className='main-container welcome-container'>
      <Container className='welcome-desc align-items-center'>
        <Row className='h-100 justify-content-md-center'>
          <Col xs lg='3' md='2' />
          <Col className='align-items-center m-auto'>
            <h1 className='welcome'>Welcome</h1>
            <div className='please-choose-your-c'>Please choose your country and language</div>
            <Row className='pb-4 pt-2'>
              <Col>
                <Dropdown className='dca-dropdown rectangle-copy-3'>
                  <Dropdown.Toggle className='w-100'>
                    {country.currentCountryObj.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {listCountries.map((country) => (
                      <Dropdown.Item
                        as='button'
                        onClick={() => onCountryChange(country)}
                        key={country.name}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col>
                <Dropdown className='dca-dropdown rectangle-copy-3'>
                  <Dropdown.Toggle className='w-100'>
                    {country.currentLanguage.languageName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {country.currentCountryObj.languages.map((language) => (
                      <Dropdown.Item
                        as='button'
                        onClick={() => onLanguageChange(language)}
                        key={language.languageCode}
                      >
                        {language.languageName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <p>
                <b>Please Note: </b>The use of this application is restricted to Kärcher partners,
                sales representatives and dealers. End customers are kindly asked to use the
                official website{' '}
                <a target='_blank' rel='noreferrer' href='https://www.karcher.com'>
                  www.karcher.com
                </a>
              </p>
            </Row>
            <Button
              className='start theme-btn btn-dark px-5 my-3'
              id='button'
              onClick={() => onGoBusinessUnitEntry(country)}
            >
              START
            </Button>
          </Col>
          <Col xs lg='3' md='2' />
        </Row>
      </Container>
    </div>
  )
}

export default LanguageSelectionUI
