import { saveCurrentCountryLangDb } from 'database/queries/country'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CountryItemType } from 'types'
import { PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { AppDataKeyEnum, SET_APP_DATA } from 'config/libs/globals'
import { setCurrentCountry, setCurrentLanguage } from 'redux/reducers/countriesReducer'
import { selectCountries, selectCountriesError, selectCountriesLoading } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import LanguageSelectionUI, { CountryStateType } from './LanguageSelection.ui'

type ListCountryType = CountryItemType[]

function handleSortListCountries(listCountry: ListCountryType): ListCountryType {
  if (!listCountry || listCountry.length === 0) {
    return []
  }
  return listCountry
    .map((country) => ({
      ...country,
      languages: country.languages
        ?.slice()
        .sort((a, b) => a.languageName.localeCompare(b.languageName)),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

const LanguageSelection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setPageInfoAnalytics } = useAnalytics()

  const countries = useSelector(selectCountries)
  const loading = useSelector(selectCountriesLoading)
  const error = useSelector(selectCountriesError)

  const [listCountrySort, setListCountrySort] = React.useState<ListCountryType>([])

  useEffect(() => {
    if (countries) {
      const countriesArr = handleSortListCountries(countries)
      setListCountrySort(countriesArr)
    }
  }, [countries])

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.LANGUAGE_SELECTION,
      pageType: PAGE_TYPES.LANGUAGE_SELECTION,
    })
  }, [])

  const onGoBusinessUnitEntry = (country: CountryStateType) => {
    const { currentCountryObj, currentLanguage } = country
    if (currentCountryObj && currentLanguage) {
      SET_APP_DATA(AppDataKeyEnum.currentCountry, currentCountryObj)
      SET_APP_DATA(AppDataKeyEnum.currentLanguage, currentLanguage)
      dispatch(setCurrentCountry(currentCountryObj))
      dispatch(setCurrentLanguage(currentLanguage))
      saveCurrentCountryLangDb(currentCountryObj, currentLanguage).then(() => {
        navigate('/business-entry')
      })
    }
  }

  if (loading) return <LoadingPage />

  if (error) return <ErrorPage />

  return (
    <LanguageSelectionUI
      listCountries={listCountrySort}
      onGoBusinessUnitEntry={onGoBusinessUnitEntry}
    />
  )
}

export default LanguageSelection
