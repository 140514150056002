import { sortByStringPropertyAscending } from 'config/libs/helpers'
import { universal } from 'database/db'
import { IDcaFavContent } from 'database/dbTypes'
import { FavoriteContentType, FavoriteFolderType } from 'types'

export const getFavoritesDb = async (): Promise<{
  favoriteContent: FavoriteContentType[]
  favoriteFolder: FavoriteFolderType[]
}> => {
  try {
    let favoriteContent = await universal.table('dcaFavContent').toArray()
    const favoriteFolder = await universal.table('dcaFavFolder').toArray()
    if (favoriteContent)
      favoriteContent = sortByStringPropertyAscending(favoriteContent, 'position')
    return { favoriteFolder, favoriteContent }
  } catch (error) {
    console.error('Error getting favorite content from database:', error)
    throw error
  }
}

/** Update all the data in the favorites DB */
export const updatedFavoritesDb = (favoritesContent: FavoriteContentType[]) => {
  try {
    return universal.dcaFavContent.bulkPut(favoritesContent)
  } catch (error) {
    console.error('updatedFavoritesDb fail', error)
  }
}

export const saveFavContentDb = async (favoriteData: IDcaFavContent) => {
  try {
    const id = await universal.dcaFavContent.put(favoriteData)
    return { success: true, id }
  } catch (error) {
    console.error('saveFavContentDb Fail', error)
  }
}

export const editFavContentDb = async (id: number, favoriteData: FavoriteContentType) => {
  try {
    await universal.dcaFavContent
      .where('id')
      .equals(id)
      .modify({ ...favoriteData })
    return { success: true }
  } catch (error) {
    console.error('editFavContentDB Fail', error)
  }
}

export const deleteFavContentDb = async (id: number) => {
  try {
    await universal.dcaFavContent.delete(id)
    const favoriteContent = await universal.table('dcaFavContent').toArray()
    return { success: true, data: favoriteContent }
  } catch (error) {
    console.error('deleteFavContentDb fail', error)
  }
}

export const saveFavFolderDb = async (newFolder: FavoriteFolderType) => {
  try {
    await universal.dcaFavFolder.put(newFolder)
    return { success: true }
  } catch (error) {
    console.error('saveFavFolderDb fail', error)
    return { success: false }
  }
}
