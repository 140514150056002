export const LAYOUTS = {
  BUSINESS_ENTRY: 'manualcontent_businessunitentry',
  PRODUCT_GROUPS_OVERVIEW: 'productgroupoverview',
  PRODUCT_GROUPS_LIST: 'productgrouplist',
  PRODUCT_LIST: 'productlist',
  PRODUCT_DETAILS: 'productdetails',
  PRODUCT_DETAILS_SHORT: 'productdetailsshort',
  MANUAL_CONTENT: 'content',
  CONTENT_ANCHOR: 'manualcontent_anchor',
  CONTENT_DOWNLOAD: 'manualcontent_download',
  CONTENT_RIBBON: 'manualcontent_ribbon',
  CONTENT_IMAGE: 'manualcontent_image',
  CONTENT_TABLE: 'manualcontent_table',
  CONTENT_TEXT: 'manualcontent_text',
  CONTENT_HEADLINE_TEXT: 'manualcontent_headlinetext',
  IMAGE_GRID: 'manualcontent_imagegrid',
  IMAGE_HEADLINE_TEXT_FIFTY_FIFTY: 'manualcontent_imageheadlinetextfiftyfifty',
  IMAGE_HEADLINE_TEXT: 'manualcontent_imageheadlinetext',
  IMAGE_HEADLINE_TEXT_TWO_COL: 'manualcontent_imageheadlinetexttwocol',
  IMAGE_HEADLINE_TEXT_THREE_COL: 'manualcontent_imageheadlinetextthreecol',
  IMAGE_HEADLINE_TEXT_FOUR_COL: 'manualcontent_imageheadlinetextfourcol',
  HORIZONTAL_RULE: 'manualcontent_horizontalrule',
}
