import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import AkTable, { AkTableType } from 'components/AkTable/AkTable'
import DCAImage from 'components/DCAImage'
import HorizontalRule from 'components/HorizontalRule'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { EquipmentItemType, IconItemType } from 'types'

type Props = {
  equipment: EquipmentItemType[]
  icons: IconItemType[]
}

function EquipmentAndIcons(props: Props): React.ReactElement {
  const { equipment, icons } = props
  const translations = useSelector(selectTranslations)

  const headline = translations.equipment ?? 'Equipment'

  const equipmentTable: AkTableType = []
  equipment.forEach((item) => {
    const valueText = item.value === '1' ? '' : `, ${item.value}`
    const valueLabel = `&#9632;  ${item.label}`
    const colData = {
      value: valueLabel + valueText,
    }
    const rowData = [colData]
    equipmentTable.push(rowData)
    return null
  })

  const renderEquipment = () => {
    return <AkTable table={equipmentTable} />
  }

  const renderIcons = () => {
    return (
      <Row>
        {icons.map((icon) => {
          return (
            <Col key={icon.attribute} xs={6} md={3} className='icon-tooltip'>
              <DCAImage src={icon.url} className='icon-image' />
              {icon.text ? <div className='icon-text'>{icon.text}</div> : ''}
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <Container className='equipment-icons-wrapper pt-4' id={PRODUCT_DETAILS_SECTIONS.EQUIPMENT}>
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>
      <HorizontalRule />

      <Row>
        <Col xs={12} md={6}>
          {renderEquipment()}
        </Col>
        <Col xs={12} md={6} className='icons-wrapper'>
          {renderIcons()}
        </Col>
      </Row>
    </Container>
  )
}

export default EquipmentAndIcons
