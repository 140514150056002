import { gql } from 'apollo-boost'

export const GET_PRODUCT_LIST_BY_ID = (
  productGroupId: string | number,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
    {
      getProductListById(input: {
        productGroupId: ${productGroupId},
        languageCode: "${languageCode}",
        countryCode: "${countryCode}",
        year: ${year}
      }, pagination: {
        limit: 1000
        }){
        export,
        year,
        country,
        language,
        createdAt,
        productGroupData,
        data{
          id,
          name,
          texts{
            value,
            type
          },
          images{
            title,
            type,
            urls{
              type,
              url
            }
          },
          partnumberFormatted,
          price,
          priceFormatted,
          priceNet,
          priceGross,
          priceNetFormatted,
          priceGrossFormatted,
          filterValues
        }
      }
    }
    `

export const GET_SUB_PRODUCT_GROUP_BY_ID = (
  productGroupIds: number[],
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
      {
          getSubProductGroupByIds(input: {
            productGroupIds: ${JSON.stringify(productGroupIds)}
            languageCode: "${languageCode}",
            countryCode: "${countryCode}",
            year: ${year}
          }) {
              export,
              year,
              country,
              language,
              createdAt,
              data {
                  id
                  title
                  parentId
                  type
              }
          }
      }
    `
export const GET_PRODUCT_LIST_BY_ROOTLINE = (
  productGroupIds: number[],
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
      {
        getProductListByRootline(input: {
          rootLineIds: [${productGroupIds.join(', ')}],
          languageCode: "${languageCode}",
          countryCode: "${countryCode}",
          year:${year}
      }, pagination: {
        limit: 1000
        }){
              export,
              year,
              country,
              language,
              createdAt,
              productGroupData,
              data {
                  id,
                  name,
                  productType,
                  businessUnit,
                  images {
                      title,
                      type,
                      urls{
                          type,
                          url
                      }
                  },
                  partnumberFormatted,
                  price,
                  priceFormatted,
                  priceNet,
                  priceGross,
                  priceNetFormatted,
                  priceGrossFormatted,
                  texts{
                    value,
                    type
                  }
              }
          }
      }
    `

export const GET_PRODUCT_BY_ID = (
  productId: string | number,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
    {
      getProductById(input: {
        productId: ${productId},
        languageCode: "${languageCode}",
        countryCode: "${countryCode}",
        year: ${year}
      }){
          export,
          year,
          country,
          language,
          createdAt,
          data,
          recommendedProducts
      }
    }
    `
export const GET_PRODUCT_BY_GROUP_ID = (
  productGroupId: string | number,
  languageCode: string,
  countryCode: string,
  year: number,
) => gql`
      {
        getProductGroupById(input: {
          productGroupId: ${productGroupId},
          languageCode: "${languageCode}",
          countryCode: "${countryCode}",
          year:${year}
        }
        ){
          export,
          year,
          country,
          language,
          createdAt,
          isDropdownAvailable,
          dropdown,
          data{
            id,
            parentId,
            name,
            texts{
              headline,
              type,
              text,
              images{
                type,
                url
              }
            }
          }
        }
      }
    `
