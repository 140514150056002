import images from 'assets/images/images'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ImageHeadlineThreeColumnsType } from 'types'
import { PAGE_IDS } from 'config/constants'
import { extractAnchorsFromText } from 'config/libs/helpers'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import DCAImage from 'components/DCAImage'

type ImageHeadlineThreeColumnProps = {
  layoutData: ImageHeadlineThreeColumnsType
}

type RenderItemProps = {
  image?: string
  headline?: string
  text?: string
  link?: number
}

const ImageHeadlineThreeColumns = ({ layoutData }: ImageHeadlineThreeColumnProps) => {
  const translations = useSelector(selectTranslations)
  const { onDynamicNavigate } = useNavigation()

  const getUrl = window.location.href
  const businessUnitProf = getUrl.includes(PAGE_IDS.PROFESSIONAL_START_PAGE.toString())
  const PRODUCT_FINDER_DATA = {
    headline: translations.product_finder_header || 'Looking for the right product?',
    text:
      translations.product_finder_description ||
      'Use the Kärcher Product Finder and have the appropriate product displayed based on your selected criteria.',
    image: images.productFinder as string,
    link: PAGE_IDS.PRODUCT_FINDER,
  }

  const onGoNexPage = (pageId?: number) => {
    if (pageId) {
      onDynamicNavigate(pageId)
    }
  }

  const renderItem = ({ image, headline, text, link }: RenderItemProps) => {
    if (!image && !headline && !text && !link) {
      return null
    }

    const textWithAnchors = extractAnchorsFromText(text ?? '')

    return (
      <Card
        className={
          link !== undefined ? 'card-wrapper-no-border cursor-pointer' : 'card-wrapper-no-border'
        }
        onClick={() => onGoNexPage(link)}
      >
        {image && <DCAImage src={image} type='cardImage' />}
        {headline && <h5 className='ak-bold mt-3'>{headline}</h5>}
        {textWithAnchors && <div dangerouslySetInnerHTML={{ __html: textWithAnchors }} />}
      </Card>
    )
  }
  /**
   * Product Finder only show in the right side and when the left and middle columns are filled
   */
  const renderRightItem = () => {
    let rightElementEmpty = false
    if (
      !layoutData.image_left ||
      !layoutData.headline_left ||
      !layoutData.image_middle ||
      !layoutData.headline_middle
    ) {
      return null
    }

    if (
      !layoutData.image_right ||
      !layoutData.headline_right ||
      !layoutData.image_right ||
      !layoutData.headline_right
    ) {
      rightElementEmpty = true
    }

    if (businessUnitProf && rightElementEmpty) {
      return renderItem({
        image: PRODUCT_FINDER_DATA.image,
        headline: PRODUCT_FINDER_DATA.headline,
        text: PRODUCT_FINDER_DATA.text,
        link: PRODUCT_FINDER_DATA.link,
      })
    } else {
      return renderItem({
        image: layoutData.image_right,
        headline: layoutData.headline_right,
        text: layoutData.text_right,
        link: layoutData.link_right,
      })
    }
  }

  if (!layoutData.image_left && !layoutData.image_middle && !layoutData.image_right) return null

  return (
    <Container className='image-headline-three-columns pt-4'>
      <Row>
        <Col md={4} lg={4}>
          {renderItem({
            image: layoutData.image_left,
            headline: layoutData.headline_left,
            text: layoutData.text_left,
            link: layoutData.link_left,
          })}
        </Col>

        <Col md={4} lg={4}>
          {renderItem({
            image: layoutData.image_middle,
            headline: layoutData.headline_middle,
            text: layoutData.text_middle,
            link: layoutData.link_middle,
          })}
        </Col>

        <Col md={4} lg={4}>
          {renderRightItem()}
        </Col>
      </Row>
    </Container>
  )
}

export default ImageHeadlineThreeColumns
