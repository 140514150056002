import { formatSearchTerm } from 'helper/common'
import { getSearchSuggestionsQuery } from 'services'
import { SearchItem, SearchSuggestionResponseType } from 'types/searchType'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PAGE_IDS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import useNavigation from 'hooks/useNavigation'
import { showAlert } from 'config/utils/CommonFunction'

const SuggestionItem = ({
  suggestion,
  onClick,
}: {
  suggestion: SearchItem
  onClick: () => void
}) => {
  return (
    <div key={suggestion.id} className='suggestion-item' onClick={onClick}>
      {suggestion.name}
    </div>
  )
}

const SearchBar = () => {
  const { onDynamicNavigate } = useNavigation()
  const { setSearchAnalytics } = useAnalytics()

  const translations = useSelector(selectTranslations)

  const [searchKey, setSearchKey] = useState<string>('')
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [suggestions, setSuggestions] = useState<SearchSuggestionResponseType>([])

  const DEFAULT_SEARCH_KEYWORD_MSG = 'Search for product or keyword'
  let searchKeyword =
    (translations && translations.search_for_product_or_keyword) || DEFAULT_SEARCH_KEYWORD_MSG
  searchKeyword = searchKeyword || ''

  const onGoSearchPage = () => {
    if (searchKey.length > 0) {
      onDynamicNavigate(PAGE_IDS.SEARCH_SCREEN, { searchKey })
    } else {
      showAlert('Please enter search key')
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onGoSearchPage()
      setSearchKey('')
      setShowSuggestions(false)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const textSearch = event.target.value
    setSearchAnalytics({ searchTerm: textSearch })
    setSearchKey(textSearch)
    getSearchSuggestionsQuery(formatSearchTerm(textSearch)).then((res) => {
      setShowSuggestions(res.length > 0)
      setSuggestions(res)
    }).catch((e) => {
      console.error(e)
    })
  }

  const handleSuggestionClick = (suggestion: SearchItem) => {
    setShowSuggestions(false)
    setSearchKey(suggestion.name)
    onDynamicNavigate(suggestion.id)
  }

  return (
    <div className='search-bar'>
      <InputGroup>
        <FormControl
          className='dca-form-control'
          placeholder={searchKeyword}
          aria-label={searchKeyword}
          onKeyDown={handleKeyDown}
          value={searchKey}
          onChange={handleChange}
        />
        <InputGroup.Text className='cursor-pointer search-icon' onClick={onGoSearchPage}>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup>

      {showSuggestions && suggestions.length > 0 && (
        <div className='suggestion-list'>
          {suggestions.map((suggestion: SearchItem) => (
            <SuggestionItem
              key={suggestion.id}
              suggestion={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchBar
