import { ProductDetailItemType } from 'types'
import { TRANSLATIONS } from 'config/constants'
import { printDocument, tr } from '../pdfHelper'
import { getCustomHTML } from '../pdfLayouts'
import { pageBreak } from '../styles'
import { TranslationsPDFType } from '../types'
import { getDescription } from './getDescription'
import { getEquipment } from './getEquipment'
import { getImageSection } from './getImageSection'
import { getTechnicalData } from './getTechnicalData'

type ComparisonData = {
  products: ProductDetailItemType[]
  techLabels: string[][]
  equipmentLabels: string[][]
}

const toProductComparisonHTML = (
  comparisonData: ComparisonData,
  translations: TranslationsPDFType,
) => {
  const { products, techLabels, equipmentLabels } = comparisonData
  const imageSectionHTML = getImageSection(products, translations)
  const techDataHTML = getTechnicalData(techLabels, translations)
  const equipmentHTML = getEquipment(equipmentLabels, translations)
  const descriptionHTML = getDescription(products, translations)

  const html = `
    <h1>${tr(translations, 'product_comparison', 'PRODUCT COMPARISON').toLocaleUpperCase()}</h1>
    ${imageSectionHTML}
    <br />
    ${techDataHTML}
    <br />
    ${equipmentHTML}
    <br />
    ${descriptionHTML}
  `
  return html
}

export const createProductComparisonPDF = (
  comparisonData: ComparisonData,
  translations: TranslationsPDFType,
  onDone: () => void
) => {
  const htmlDOM = getCustomHTML({
    breadCrumbData: [],
    pageStyles: productCompareStyle,
    title: tr(translations, 'product_comparison', TRANSLATIONS.productComparison),
  })

  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += toProductComparisonHTML(comparisonData, translations)
  printDocument(htmlDOM, onDone)
}

const productCompareStyle = `
.compare-wrap {
}
.compare-row {
  display: flex;
  flex-direction: row;
}
.compare-col {
  flex: 1;
  margin-right: 5px
}
.compare-wrap .compare-row {
  padding: 10px 0;
  ${pageBreak}
}
.compare-wrap .compare-row label{
  font-size: 10px;
}
.compare-wrap .compare-row:nth-child(odd) {
  background: #F8F8F8;
}
.compare-wrap .compare-col {
  padding: 0px
}
.compare-col img {
  height: 130px;
  width: 100%;
  object-fit: contain;
  margin-top: 10px;
}
.compare-wrap .compare-col img {
  height: 12px;
  width: 12px;
}
.compare-col span {
  margin-top: 10px;
  float: left;
}
h4 {
  margin-top: 10px;
  margin-bottom: 5px;
}
.desc-wrap {
  padding-top: 30px;
  ${pageBreak}
}
.section {
  ${pageBreak}
  padding-top: 20px;
}
`
