import { Button, Col, Container, Row } from 'react-bootstrap'
import { ContentDownloadType } from 'types'
import { capitalizeFirstLetterOfEachWord } from 'config/libs/helpers'
import DCAImage from 'components/DCAImage'

type Props = {
  layoutData: ContentDownloadType
}

type SaleGuideType = {
  headline?: string
  headlineType?: string
  buttonText?: string
  imageUrl?: string
  fileUrl?: string
}

const ContentDownload = ({ layoutData }: Props) => {
  const { headline } = layoutData

  const renderSaleGuide = ({
    headline,
    headlineType,
    buttonText,
    imageUrl,
    fileUrl,
  }: SaleGuideType) => {
    if (!headline || !fileUrl || !headlineType || !buttonText || !imageUrl) return null
    return (
      <Row>
        <Col xs={9} md={5} lg={4}>
          <DCAImage type='cardImage' src={imageUrl} />
        </Col>
        <Col className='d-flex flex-column justify-content-between' xs={9} md={5} lg={4}>
          <h6 className='ak-bold'>{headline}</h6>

          <Button href={fileUrl} target='_blank' className='app-button'>
            {capitalizeFirstLetterOfEachWord(buttonText)}
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <Container className='content-download-wrapper py-3 mt-4'>
      <h2 className='headline'>{headline}</h2>
      <Row className='mt-4'>
        <Col xs={9} md={5} lg={4} style={{ paddingBottom: 10 }}>
          {renderSaleGuide({
            headline: layoutData.headline_left,
            headlineType: layoutData.headlinetype_left,
            buttonText: layoutData.button_text_left,
            imageUrl: layoutData.preview_left,
            fileUrl: layoutData.file_left,
          })}
        </Col>

        <Col xs={9} md={5} lg={4}>
          {renderSaleGuide({
            headline: layoutData.headline_middle,
            headlineType: layoutData.headlinetype_middle,
            buttonText: layoutData.button_text_middle,
            imageUrl: layoutData.preview_middle,
            fileUrl: layoutData.file_middle,
          })}
        </Col>

        <Col xs={9} md={5} lg={4}>
          {renderSaleGuide({
            headline: layoutData.headline_right,
            headlineType: layoutData.headlinetype_right,
            buttonText: layoutData.button_text_right,
            imageUrl: layoutData.preview_right,
            fileUrl: layoutData.file_right,
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default ContentDownload
