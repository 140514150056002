import { dbMigration } from 'database/dbFunctions'
import React from 'react'
import { Container } from 'react-bootstrap'
import 'styles/pages/migration.scss'

const Migration = (): React.ReactElement => {
  const onMigration = async () => {
    await dbMigration()
    localStorage.setItem('dbMigration', 'true')
    window.location.reload()
  }

  return (
    <Container>
      <div className='migration-wrapper'>
        <div className='migration-content'>
          <h6 className='ak-bold text-center'>
            Due to a software update, it is mandatory to clear your local cache related to Digital
            Catalogue / Kärcher Programme. Without clearing the cache, you will not be able to use
            Digital Catalogue / Kärcher Programme in the future. Please note that clearing your
            cache will result in the loss of all settings and configurations (e.g. saved favorites
            and active product comparisons) within Digital Catalogue / Kärcher Programme.
          </h6>

          <h6 className='ak-bold text-center mt-3'>
            Only Digital Catalogue / Kärcher Programme cache will be deleted, all other data will be
            kept. To clear your cache, click here:
          </h6>
          <button className='app-button mt-3' onClick={onMigration}>
            CLEAR CACHE
          </button>
        </div>
      </div>
    </Container>
  )
}

export default Migration
