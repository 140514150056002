import React, { createContext, useContext, useState } from 'react'
import ComparisonModal, { ComparisonModalProps } from './ComparisonModal'
import ConfirmModal, { ConfirmModalProps } from './ConfirmModal'
import DownloadModal, { DownloadModalProps } from './DownloadModal/DownloadModal'
import FavoritesModal, { FavoritesModalProps } from './FavoritesModal'
import ImageViewerModal, { ImageViewerProps } from './ImageViewerModal'
import LoadingModal from './LoadingModal'
import MagnifyModal, { MagnifyModalProps } from './MagnifyModal'
import PopupModal, { PopUpModalProps } from './PopupModal'

type ModalType =
  | 'CONFIRM_MODAL'
  | 'MAGNIFY_MODAL'
  | 'DOWNLOAD_MODAL'
  | 'COMPARE_MODAL'
  | 'FAVORITES_MODAL'
  | 'LOADING_MODAL'
  | 'IMAGE_VIEWER_MODAL'
  | 'POPUP_MODAL'

type ModalPropsMap = {
  [key in ModalType]:
    | ConfirmModalProps
    | MagnifyModalProps
    | DownloadModalProps
    | ComparisonModalProps
    | FavoritesModalProps
    | ImageViewerProps
    | PopUpModalProps
    | undefined
}

type GlobalModalContextState = {
  showModal: (modalType: ModalType, modalProps: ModalPropsMap[ModalType]) => void
  hideModal: () => void
}

const initialState: GlobalModalContextState = {
  showModal: () => {
    // initialize with empty function to avoid errors
  },
  hideModal: () => {
    // initialize with empty function to avoid errors
  },
}

const GlobalModalContext = createContext(initialState)

export const useGlobalModalContext = () => useContext(GlobalModalContext)

type GlobalModalProviderProps = {
  children: React.ReactNode
}

export const GlobalModalProvider: React.FC<GlobalModalProviderProps> = ({ children }) => {
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [modalProps, setModalProps] = useState<ModalPropsMap[ModalType] | null>(null)

  const showModal = (type: ModalType, props: ModalPropsMap[ModalType]) => {
    setModalType(type)
    setModalProps(props)
  }

  const hideModal = () => {
    setModalType(null)
    setModalProps(null)
  }

  const renderModalComponent = (type: ModalType | null) => {
    if (type !== 'LOADING_MODAL' && !modalProps) return null

    switch (type) {
      case 'CONFIRM_MODAL':
        return <ConfirmModal {...(modalProps as ConfirmModalProps)} />

      case 'MAGNIFY_MODAL':
        return <MagnifyModal {...(modalProps as MagnifyModalProps)} />

      case 'DOWNLOAD_MODAL':
        return <DownloadModal {...(modalProps as DownloadModalProps)} />

      case 'COMPARE_MODAL':
        return <ComparisonModal {...(modalProps as ComparisonModalProps)} />

      case 'FAVORITES_MODAL':
        return <FavoritesModal {...(modalProps as FavoritesModalProps)} />

      case 'IMAGE_VIEWER_MODAL':
        return <ImageViewerModal {...(modalProps as ImageViewerProps)} />

      case 'POPUP_MODAL':
        return <PopupModal {...(modalProps as PopUpModalProps)} />

      case 'LOADING_MODAL':
        return <LoadingModal />

      default:
        return null
    }
  }

  return (
    <GlobalModalContext.Provider value={{ showModal, hideModal }}>
      {renderModalComponent(modalType)}
      {children}
    </GlobalModalContext.Provider>
  )
}
