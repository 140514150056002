import { getProductListDbById } from 'database/queries/product'
import { getProductListQuery } from 'services'
import { ProductGroupDataType, ProductListResponseType } from 'types'
import { productListStyle, toProductListHTML } from '../ProductListPDF/ProductListPDF'
import { getBreadcrumbData } from '../pdfHelper'
import { horizontalRule, logoHTML, toBreadcrumbHTML, toRibbonHTML } from '../pdfLayouts'
import { Config } from './type'
import { asyncFetchData } from './utils'

export const getProductListHTML = async (pageId: number, config: Config) => {
  const { structure, isAppOnline, translations } = config

  const productResponse = await asyncFetchData<number, ProductListResponseType>(
    isAppOnline,
    getProductListQuery,
    getProductListDbById,
    pageId,
    pageId,
  )

  if (!productResponse) return ''

  const productGroupData: ProductGroupDataType = JSON.parse(
    productResponse.productGroupData ?? '{}',
  )

  const breadCrumbData = getBreadcrumbData(pageId, structure)
  const ribbonData = getRibbonData(productGroupData)

  const productHTML = toProductListHTML(productResponse.data, translations)
  const breadCrumbHTML = toBreadcrumbHTML(breadCrumbData)
  const ribbonHTML = toRibbonHTML(ribbonData)
  
  return `
      <style>
      ${productListStyle}
      </style>
      ${logoHTML}
      ${breadCrumbHTML}
      ${ribbonHTML}
      ${productHTML}
  `
}

const getRibbonData = (productGroup: ProductGroupDataType) => {
  if (!productGroup) return undefined
  const { name, texts } = productGroup
  const groupHeaderText = texts.find((text) => text.type === 'header')
  const subHeadline = groupHeaderText?.headline ?? ''
  const description = groupHeaderText?.text ?? ''

  return { headline: name, subHeadline, description }
}
