import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { DocumentsItemType } from 'types'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import DCAImage from 'components/DCAImage'
import HorizontalRule from 'components/HorizontalRule'

type DocumentsPropsType = {
  documents: DocumentsItemType[]
}

function Documents({ documents }: DocumentsPropsType) {
  const translations = useSelector(selectTranslations)
  const headline = translations.download ?? 'DOWNLOADS'

  const onOpenPdf = (url: string) => {
    window.open(url, 'product')
  }

  const renderDocuments = () => {
    if (!documents || documents.length === 0) return null

    return (
      <Row>
        {documents.map((document, index) => {
          const title = translations[document.type] ? translations[document.type] : ''
          const previewData = document.urls.find((url) => url.type === 'preview')
          const fileData = document.urls.find((url) => url.type === 'file')

          if (!previewData || !fileData) return null

          return (
            <Col key={index} md='2'>
              <Card className='document-image-wrapper' onClick={() => onOpenPdf(fileData.url)}>
                <DCAImage src={previewData.url} type='cardImage' />
              </Card>

              <Button
                variant='primary'
                className='button-download'
                onClick={() => onOpenPdf(fileData.url)}
              >
                {headline.toUpperCase()}
              </Button>
              <p className='download-title'>{title}</p>
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <Container className='documents-wrapper pt-4' id={PRODUCT_DETAILS_SECTIONS.DOCUMENTS}>
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>

      <HorizontalRule />

      {renderDocuments()}
    </Container>
  )
}

export default Documents
